<template>
  <div>
    <ui-error
      v-if="state.matches('updatingShipping.error') || state.matches('bookingShipping.error')"
      class="shipping-incomplete__error"
      :subtitle="errorMsg"
      @try-again="onTryAgain"
    />
    <shipping-form
      :state="state"
      :state-machine="stateMachine"
      @save="onShippingFormSave"
    />
    <ui-message v-if="isShippingInProgress">Shipping creation is in progress</ui-message>
    <ui-message v-if="isShippmentWasNotCreated" type="error">Shipping creating faild</ui-message>
  </div>
</template>

<script>
import {
  UiError,
  UiMessage,
} from '@ui';

import { mapState, mapGetters } from 'vuex';
import { interpret } from 'xstate';

import ShippingForm from '../../ShippingDetails/ShippingForm.vue';

import OrderIncompleteMachine from './OrderIncompleteMachine';

export default {
  components: {
    UiError,
    UiMessage,
    ShippingForm,
  },

  props: {
    shopId: {
      type: String,
      default: null,
    },

    orderId: {
      type: String,
      default: null,
    },
    positionIds: {
      type: Array,
      default: null,
    },
    hasMsa: {
      type: Boolean,
      default: null,
    },
    isShippmentWasNotCreated: {
      type: Boolean,
    },
  },

  data() {
    return {
      state: null,
      stateMachine: null,
    };
  },

  computed: {
    ...mapState('orders/order', {
      errorMsg: 'errorMsg',
    }),

    ...mapGetters('orders/order/withMSA', {
      noSelectedPickupPoint: 'noSelectedPickupPoint',
    }),

    ...mapGetters('orders/order', {
      isShippingInProgress: 'isShippingInProgress',
    }),
  },

  created() {
    this.stateMachine = interpret(OrderIncompleteMachine.withConfig({
      actions: {
        SELECT_PROVIDER: (context, { providerId }) => {
          this.$store.dispatch('orders/order/providers/selectProvider', providerId);
        },

        SELECT_PICKUP_ADDRESS: (context, { address }) => {
          this.$store.dispatch('orders/order/withMSA/selectAddress', address);
        },

        SELECT_PACKAGE: (context, { packageType }) => {
          this.$store.dispatch('orders/order/withMSA/selectPackage', packageType);
        },

        SET_TRACKING_NUMBER: (context, { trackingNumber }) => {
          this.$store.dispatch('orders/order/withoutMSA/setTrackingNumber', trackingNumber);
        },

        FETCH_ORDER: () => {
          this.$emit('onBookFinished');
        },
      },

      guards: {
        hasMSA: () => this.hasMsa,
        noSelectedPickupPoint: () => this.noSelectedPickupPoint,
      },

      services: {
        FETCH_PROVIDERS: () => this.$store.dispatch('orders/order/providers/fetchProviders', {
          positionIds: this.positionIds,
          shopId: this.shopId,
        }),

        UPDATE_SHIPPING: () => this.$store.dispatch('orders/order/updateShipping', {
          orderId: this.orderId,
          shopId: this.shopId,
          positionIds: this.positionIds,

        }).then(() => {
          this.$store.dispatch('statistics/decrementIncompleteCounter', this.shopId);
          return Promise.resolve();
        }),

        BOOK_SHIPPING: () => this.$store.dispatch('orders/order/bookShipping', {
          orderId: this.orderId,
          shopId: this.shopId,
          positionIds: this.positionIds,

        }).then(() => {
          this.$store.dispatch('statistics/decrementIncompleteCounter', this.shopId);
          return Promise.resolve();
        }),
      },
    }))
      .onTransition((state) => {
        this.state = state;
      })
      .start();
  },

  methods: {
    onTryAgain() {
      this.stateMachine.send('BACK');
    },

    onShippingFormSave() {
      this.stateMachine.send('CONTINUE');
    },
  },
};
</script>

<style lang="scss">
.shipping-incomplete {
  &__content {
    text-align: center;
    margin: 50px auto;
    font-size: 17px;
  }

  &__spinner {
    margin: 20px auto;
  }

  &__error {
    padding: 30px 0;
  }
}
</style>
