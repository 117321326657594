<template>
  <div class="ui-layout">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiLayout',
};
</script>

<style lang="scss">
.ui-layout {
  background: #F2F3F5;
}
</style>
