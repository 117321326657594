<template>
  <span class="ui-badge">
    <slot :value="value" :text="text">
      <span>{{ text }}</span>
    </slot>
  </span>
</template>

<script>
export default {
  name: 'UiBagde',
  props: {
    value: {
      type: [Number, String],
      default: null,
    },

    max: {
      type: Number,
      default: 99,
    },
  },

  computed: {
    text() {
      if (typeof this.value === 'number') {
        return (this.value > this.max ? `${this.max}+` : this.value);
      }
      return this.value;
    },
  },
};
</script>

<style lang="scss">
.ui-badge {
  display: inline-block;
  border-radius: 9px;
  min-width: 25px;
  padding: 1px 5px 0;
  background-color: #B3008F;
  font-size: 14px;
  line-height: 14px;
  color: #fff;
  text-align: center;
  margin-left: 5px;
}
</style>
