var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"order"},[_c('ui-spinner-container',{staticClass:"order__loader",attrs:{"is-loading":_vm.isLoading}},[(_vm.details)?_c('order-details',{key:_vm.details.id,attrs:{"order":_vm.details,"is-shippment-was-not-created":_vm.isShippmentWasNotCreated},on:{"onBookShipmentFinished":function($event){return _vm.fetchOrder(true)}}}):_vm._e(),(!_vm.isLoading && !_vm.details)?[_c('message-template',{attrs:{"icon":"/illustration-no-results.svg","text":"Order does not exist"}})]:_vm._e(),_c('router-link',{staticClass:"order__close-link",attrs:{"to":{
        name: 'orders',
        params: {
          shopId: _vm.$route.params.shopId,
        },
        query: _vm.$route.query,
      }}},[(!_vm.isLoading)?_c('ui-icon',{staticClass:"order__close-icon",attrs:{"type":"x"}}):_vm._e()],1)],2)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }