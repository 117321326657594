import Vue from 'vue';
import 'iview/dist/styles/iview.css';
import FlagIcon from 'vue-flag-icon';
import PortalVue from 'portal-vue';
import App from './App.vue';
import router from './router';
import store from './store';
import auth from './auth';

Vue.use(FlagIcon);
Vue.use(PortalVue);

Vue.config.productionTip = false;

auth.load();

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
