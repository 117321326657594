import axios from 'axios';

export default class ApiClass {
  constructor(options, interceptors) {
    this.http = axios.create(options);

    if (interceptors && interceptors.request && interceptors.request.length) {
      interceptors.request.map((requestInterceptor) => this.http.interceptors.request.use(
        requestInterceptor.onRequest,
        requestInterceptor.onError,
      ));
    }

    if (interceptors && interceptors.response && interceptors.response.length) {
      interceptors.response.map((requestInterceptor) => this.http.interceptors.response.use(
        requestInterceptor.onResponse,
        requestInterceptor.onError,
      ));
    }
  }
}
