<template>
  <div class="ui-message" :class="classes">
    <icon class="ui-message__icon" type="info" />
    <slot />
  </div>
</template>

<script>
import Icon from '../Icon/Icon.vue';

export default {
  name: 'UiMessage',
  components: {
    Icon,
  },

  props: {
    type: {
      type: String,
      default: 'info',
      validator: (val) => ['', 'info', 'success', 'error'].indexOf(val),
    },
  },

  computed: {
    classes() {
      return [`ui-message--type-${this.type}`];
    },

    icon() {
      const icons = {
        info: 'info',
      };

      return icons[this.type];
    },
  },
};
</script>

<style lang="scss">
.ui-message {
  position: relative;
  font-size: 14px;
  line-height: 1.55em;
  border-radius: 8px;
  padding: 16px 16px 16px 42px;

  &__icon {
    position :absolute;
    left: 16px;
    top: 14px;
    font-size: 20px;
  }

  &--type-info {
    color: #2D67AE;
    background-color: #F3F9FE;
  }

  &--type-success {
    color: #316A39;
    background-color: #ABE0B9;
  }

  &--type-error {
    color: #000000;
    background-color: #e0abc8;
  }
}
</style>
