<template>
  <RadioGroup
    :value="selectedId"
    vertical
    @on-change="onChange"
  >
    <Radio
      v-for="type in types"
      :key="type.id"
      :label="type.id"
    >
      {{ type.name }} ({{ type.price | price(type.currency) }})
    </Radio>
  </RadioGroup>
</template>

<script>
import { RadioGroup, Radio } from 'iview';
import priceFilter from '@/logic/filters/price';

export default {
  filters: {
    price: priceFilter,
  },

  components: {
    RadioGroup,
    Radio,
  },

  props: {
    types: {
      type: Array,
      required: true,
    },

    selectedId: {
      type: String,
      required: false,
      default: null,
    },
  },

  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>
