import auth from '@/auth';
import router from '@/router';
import axios from 'axios';

// eslint-disable-next-line arrow-parens
export default (storeContext) => ({
  onResponse(response) {
    return response;
  },
  onError(error) {
    if (error.response && error.response.status === 401 && auth.channel) {
      const sessionLifeTime = window.localStorage.getItem('sessionAuth');

      const log = {
        accessorId: auth.channel.data.accessor.id?.toString(),
        authId: error.response.config.headers['Miinto-Api-Auth-ID'],
        authSeed: error.response.config.headers['Miinto-Api-Auth-Seed'],
        authTimestamp: error.response.config.headers['Miinto-Api-Auth-Timestamp'],
        sessionTimestamp: sessionLifeTime ? (Date.parse(sessionLifeTime) / 1000).toString() : '',
        device: navigator.userAgent,
      };

      if (window.APP_CONFIG.api.auth) {
        axios.post(`${window.APP_CONFIG.apiV2}/debug-logs`, log);
      }
    }

    if (
      error.response && (error.response.status === 403 || error.response.status === 401) && !auth.channel
    ) {
      // TODO: Test and tweak when API ready - 23.05.24 IT DOENST WORK - TO FIX
      storeContext.dispatch('auth/logout').then(() => {
        router.push({ name: 'login' }).catch(() => {});
      });
    }

    throw error;
  },
});
