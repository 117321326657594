<template>
  <ui-nav class="shops-list">
    <div v-if="!hasDropDownList">
      <list-item
        v-for="shop in shops"
        :key="shop.id"
        :shop-id="shop.id"
        :country="shop.countryCode"
        class="shops-list__item"
      >
        {{ shop.name }}
      </list-item>
    </div>
    <div v-else>
      <dropdown
        body-class="shops-list__dropdown-body"
        nowrap
        horizontal-align="left"
        vertical-align="under"
        :width="300"
        scroll-context="layout-content"
      >
        <template #anchor="{ toggle, isOpened }">
          <list-item
            v-if="currentShop"
            :key="currentShop.id"
            :shop-id="currentShop.id"
            :country="currentShop.countryCode"
            disable-link
            class="shops-list__current-item"
            @click="onCurrentItemClick(toggle, isOpened, $event)"
          >
            {{ currentShop.name }}
            <template #action>
              <ui-icon type="arrow-left" size="12" class="shops-list__current-item-icon" />
            </template>
          </list-item>
        </template>
        <template #default="{ toggle }">
          <div
            v-click-outside="onClickOutsideList(toggle)"
          >
            <list-item
              v-for="shop in shops"
              :key="shop.id"
              :shop-id="shop.id"
              :country="shop.countryCode"
              class="shops-list__dropdown-item"
              @click="onListItemClick(toggle)"
            >
              {{ shop.name }}
            </list-item>
          </div>
        </template>
      </dropdown>
    </div>
  </ui-nav>
</template>

<script>
import {
  UiNav,
  UiIcon,
} from '@ui';

import Dropdown from '@/ui/Dropdown/Dropdown.vue';
import vClickOutside from 'v-click-outside';
import ListItem from './ShopsListItem.vue';

export default {
  name: 'ShopsShopsListView',
  components: {
    UiNav,
    UiIcon,
    ListItem,
    Dropdown,
  },

  directives: {
    clickOutside: vClickOutside.directive,
  },

  props: {
    shops: {
      type: Array,
      default: () => [],
    },
    hasDropDownList: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    currentShop() {
      return this.$route.params.shopId
        ? this.shops.find((shop) => shop.id === this.$route.params.shopId) : null;
    },
  },

  methods: {
    onCurrentItemClick(dropdownToggle, dropdownOpened, event) {
      event.stopPropagation();
      dropdownToggle(!dropdownOpened);
    },

    onClickOutsideList(toggle) {
      return () => toggle(false);
    },

    onListItemClick(toggle) {
      toggle(false);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.shops-list {
  line-height: 40px;

  @include for-tablet-portrait-down {
    white-space: nowrap;
    overflow-x: auto;
  }

  &__current-item {
    margin-left: 0;
  }

  &__current-item-icon {
    margin-left: 10px;
    transform: rotate(-90deg);
  }

  &__item {
    margin: 5px;

    &:first-of-type {
      margin-left: 0;
    }
  }

  &__dropdown-item {
    display: block;
    margin: 0;
    padding: 10px;

    &:hover {
      background: #f0faff;
    }
  }

  &__dropdown-body {
    padding: 0;
    height: 300px;
    overflow: auto;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
      width: 7px;
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 4px;
      background-color: rgba(0,0,0,.5);
      -webkit-box-shadow: 0 0 1px rgba(255,255,255,.5);
    }
  }
}
</style>
