<template>
  <Form
    ref="form"
    class="rma-return-reason-form"
    :model="form"
    :rules="validationRules"
  >
    <FormItem prop="reason">
      <Input
        v-model="form.reason"
        :placeholder="placeholder"
      />
    </FormItem>
    <div class="rma-return-reason-form__actions">
      <ui-button type="link-blue">Cancel</ui-button>
      <ui-button
        type="danger"
        :active="true"
        @click.native.prevent="submit"
      >
        Decline
      </ui-button>
    </div>
  </Form>
</template>

<script>
import { Form, FormItem, Input } from 'iview';
import { UiButton } from '@ui';

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    FormItem,
    // eslint-disable-next-line vue/no-reserved-component-names
    Input,
    UiButton,
  },

  props: {
    placeholder: {
      type: String,
      default: 'Please describe why the return could not be approved.',
    },
  },

  data() {
    return {
      form: {
        reason: '',
      },
      validationRules: {
        reason: [
          { required: true, message: 'Reason cannot be empty', trigger: 'blur' },
        ],
      },
    };
  },

  methods: {
    submit() {
      this.$refs.form.validate((isValid) => {
        if (isValid) {
          this.$emit('submit', this.form);
        }
      });
    },
  },
};
</script>

<style lang="scss">
.rma-return-reason-form {
  width: 100%;

  &__reason-field {
    margin-bottom: 10px;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
  }

  .ivu-form-item {
    margin-bottom: 10px;
  }
}
</style>
