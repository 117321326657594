<template>
  <span
    class="return-status-badge "
    :class="[`return-status-badge--status-${status}`]"
  >{{ text }}</span>
</template>

<script>
export default {
  props: {
    status: {
      type: String,
      required: true,
      validator: (val) => ['pending', 'approved', 'rejected', 'confirmed'].indexOf(val) !== -1,
    },
  },

  data: () => ({
    texts: {
      pending: 'Pending',
      approved: 'Approved',
      rejected: 'Rejected',
      'confirmed': 'Approved manually',
    },
  }),

  computed: {
    text() {
      return this.texts[this.status] || this.status;
    },
  },
};
</script>

<style lang="scss">
.return-status-badge {
  line-height: 22px;
  border-radius: 4px;
  font-size: 12px;
  line-height: 12px;
  padding: 5px 8px;
  white-space: nowrap;

  &--status-pending {
    background-color: #F9E4B8;
    color: #94670B;
  }

  &--status-declined {
    background-color: #F2BBB9;
    color: #83302C;
  }

  &--status-accepted,
  &--status-accepted-manually {
    background-color: #ABE0B9;
    color: #316A39;
  }
}
</style>
