import Vue from 'vue';
import Vuex from 'vuex';

import auth, { types as authTypes } from '@/store/auth';
import shops, { types as shopsTypes } from '@/store/shops';
import orders, { types as ordersTypes } from '@/store/orders';
import statistics from '@/store/statistics';
import rmas from '@/store/rmas';
import rmaRequests from '@/store/rma-requests';
import envConfig from '@/store/envConfig';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    auth,
    shops,
    orders,
    statistics,
    rmas,
    rmaRequests,
    envConfig,
  },

  actions: {
    reset({ commit }) {
      commit(`auth/${authTypes.RESET}`);
      commit(`orders/${ordersTypes.RESET}`);
      commit(`shops/${shopsTypes.RESET}`);
      commit('statistics/reset');
      commit('rmas/RESET');
      commit('rmaRequests/RESET');
    },
  },
});
