const scrollAttributeName = 'data-scroll';

export const fix = () => {
  const { scrollY } = window;

  document.body.setAttribute(scrollAttributeName, scrollY);

  document.body.style.position = 'fixed';
  document.body.style.top = `-${scrollY}px`;
  document.body.style.left = 0;
  document.body.style.right = 0;
};

export const unfix = () => {
  document.body.style.position = '';
  document.body.style.width = '';
  document.body.style.height = '';
  document.body.style.top = '';
  document.body.style.left = '';
  document.body.style.right = '';

  const dataScroll = document.body.getAttribute(scrollAttributeName);

  if (dataScroll) {
    window.scroll(0, dataScroll);
    document.body.removeAttribute(scrollAttributeName);
  }
};

export default {
  fix,
  unfix,
};
