<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div class="rma-details-items-item" :class="classes">
    <div v-if="$slots.select" class="rma-details-items-item__select">
      <slot name="select" />
    </div>
    <img v-if="item.img" class="rma-details-items-item__image" :src="item.img">
    <div class="rma-details-items-item__right">
      <div class="rma-details-items-item__price">{{ item.amount | price(item.currency) }}</div>
      <slot name="action" />
    </div>
    <h4 class="rma-details-items-item__name">{{ item.name }}</h4>
    <dl class="rma-details-items-item__props">
      <dt class="rma-details-items-item__prop-name">Quantity</dt>
      <dd class="rma-details-items-item__prop-val"><slot name="quantity">{{ item.quantity }}</slot></dd>

      <dt class="rma-details-items-item__prop-name">Color</dt>
      <dd class="rma-details-items-item__prop-val">{{ item.color }}</dd>

      <dt class="rma-details-items-item__prop-name">Size</dt>
      <dd class="rma-details-items-item__prop-val">{{ item.size }}</dd>

      <slot name="reason">
        <dt class="rma-details-items-item__prop-name">Return reason</dt>
        <dd class="rma-details-items-item__prop-val">{{ item.reasonForReturning }}</dd>
      </slot>
    </dl>
  </div>
</template>

<script>
import PriceFilter from '@/logic/filters/price';

export default {
  name: 'ReturnsRmaDetailsItemsItem',
  filters: {
    price: PriceFilter,
  },

  props: {
    item: {
      type: Object,
      default: () => ({
        amount: null,
        color: null,
        currency: null,
        name: null,
        positionId: null,
        quantity: null,
        size: null,
        totalAmount: 0,
        reasonForReturning: null,
      }),
    },
  },

  computed: {
    classes() {
      return {
        'rma-details-items-item--with-select': this.withSelect,
      };
    },

    withSelect() {
      return this.$slots.select;
    },
  },
};
</script>

<style lang="scss">
.rma-details-items-item {
  &--with-select {
    padding-left: 40px;
  }

  &__image {
    max-width: 100px;
    max-height: 100px;
    border: 1px solid #EAEAEA;
    border-radius: 4px;
    float: left;
    margin-right: 16px;
  }

  &__select {
    margin-left: -40px;
    float: left;
  }

  &__name {
    font-size: 20px;
    font-weight: normal;
    line-height: 1em;
    margin-bottom: 12px;
  }

  &__right {
    float: right;
  }

  &__price {
    font-size: 20px;
    line-height: 1em;
    margin-bottom: 12px;
  }

  &__props {
    padding-left: 120px;
    font-size: 14px;
    overflow: hidden;
  }

  &__prop-name {
    width: 108px;
    margin-left: -120px;
    margin-bottom: 4px;
    clear: left;
    float: left;
    color: #8C8C8C;
  }

  &__prop-val {
    margin-bottom: 4px;
  }
}
</style>
