<!-- eslint-disable vuejs-accessibility/label-has-for -->
<template>
  <Form
    ref="form"
    class="login-form"
    :model="model"
    :rules="rules"
    label-position="top"
    @submit.native.prevent="handleSubmit"
  >
    <Alert v-if="globalError" type="error">Incorrect email or password</Alert>
    <FormItem prop="Email">
      <label class="login-form__label">Username</label>
      <ui-text-input v-model="model.Email" class="login-form__input" type="text" />
    </FormItem>
    <FormItem prop="Password">
      <label class="login-form__label">Password</label>
      <ui-text-input v-model="model.Password" class="login-form__input" type="password" />
    </FormItem>

    <ui-button type="submit" class="login-form__button" :loading="loading"> Login </ui-button>
  </Form>
</template>

<script>
import { Form, FormItem, Alert } from "iview";

import { UiTextInput, UiButton } from "@ui";

export default {
  components: {
    UiTextInput,
    UiButton,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    FormItem,
    Alert,
  },

  data() {
    return {
      loading: false,
      globalError: false,

      model: {
        Email: null,
        Password: null,
      },

      rules: {
        Email: [{ required: true }],
        Password: [{ required: true }],
      },
    };
  },

  methods: {
    handleSubmit() {
      this.globalError = false;
      this.loading = true;

      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$emit("submit", {
            email: this.model.Email,
            password: this.model.Password,
          });
        } else {
          this.loading = false;
        }
      });
    },

    setError() {
      this.globalError = true;
      this.loading = false;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/mixins/breakpoints.scss";

.login-form {
  &__label {
    font-weight: 500;
    font-size: 14px;
  }

  &__input .ui-text-input {
    width: 360px;
    height: 50px;

    @include for-phone-only {
      width: 100%;
    }
  }

  &__button {
    margin-top: 30px;
    padding: 15px 50px;
    height: 50px;

    @include for-tablet-portrait-down {
      width: 100%;
      margin-top: 0;
    }
  }
}
</style>
