<template>
  <div class="rma-details-items-list">
    <h3 class="rma-details-items-list__title">Returned items</h3>
    <p v-if="items.length === 0">{{ emptyMessage }}</p>
    <template v-else>
      <ul class="rma-details-items-list__items">
        <li v-for="(item, key) in items" :key="key" class="rma-details-items-list__item">
          <slot name="item" :item-key="key" :item="item">
            <item :item="item" />
          </slot>
        </li>
      </ul>
      <div class="rma-details-items-list__summary">
        <div class="rma-details-items-list__summary-line">
          <span class="rma-details-items-list__summary-title">{{ totalLabel }}</span>
          <span class="rma-details-items-list__summary-value">{{ total | price(currency) }}</span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import PriceFilter from '@/logic/filters/price';
import Item from './Item.vue';

export default {
  name: 'ReturnsRmaDetailsItemsList',
  filters: {
    price: PriceFilter,
  },

  components: {
    Item,
  },

  props: {
    total: {
      type: Number,
      default: null,
    },
    currency: {
      type: String,
      default: null,
    },
    items: {
      type: Array,
      default: () => [],
    },
    totalLabel: {
      type: String,
      default: 'Total',
    },
    emptyMessage: {
      type: String,
      default: 'No available products',
    },
  },
};
</script>

<style lang="scss">
.rma-details-items-list {
  margin-bottom: 22px;

  &__title {
    font-size: 18px;
    font-weight: 500;
    display: flex;
    align-items: center;
    margin-bottom: 6px;

    &:after {
      content: '';
      flex: 1;
      height: 1px;
      background: #EBEBEB;
      margin-left: 28px;
    }
  }

  &__items {
    list-style: none;
    margin: 0 0 0 0;
    padding: 0;
  }

  &__item {
    margin-bottom: 22px;
    padding: 18px 0;
    border-bottom: 1px solid #EBEBEB;
  }

  &__summary {
    display: flex;
    align-items: center;
    align-items: flex-end;
    flex-direction: column;
  }

  &__summary-line {
    display: flex;
    width: 50%;
    justify-content: space-between;
    border-bottom: 1px solid #EBEBEB;
    padding: 5px 0 12px 0;
    margin-bottom: 22px;
  }

  &__summary-title {
    font-size: 18px;
    line-height: 18px;
  }

  &__summary-value {
    font-size: 18px;
    line-height: 18px;
  }
}
</style>
