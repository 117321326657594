<template>
  <table class="shipping-information">
    <tr v-if="address.company">
      <td class="shipping-information__label">Company</td>
      <td class="shipping-information__value">{{ address.company }}</td>
    </tr>
    <tr>
      <td class="shipping-information__label">Name</td>
      <td class="shipping-information__value">{{ address.name }}</td>
    </tr>
    <tr>
      <td class="shipping-information__label">Address</td>
      <td class="shipping-information__value">{{ address.address.street }}, {{ address.address.postcode }}, {{ address.address.city }}, {{ address.address.country }}</td>
    </tr>
    <tr v-if="address.email">
      <td class="shipping-information__label">Email</td>
      <td class="shipping-information__value">{{ address.email }}</td>
    </tr>
    <tr v-if="address.phone">
      <td class="shipping-information__label">Phone</td>
      <td class="shipping-information__value">{{ address.phone }}</td>
    </tr>
    <tr v-if="providerName" class="shipping-information__carrier-info">
      <td class="shipping-information__label">Carrier</td>
      <td class="shipping-information__value">{{ providerName }}</td>
    </tr>
  </table>
</template>
<script>
import { shippingProvidersMap } from '@/const';

export default {
  props: {
    address: {
      type: Object,
      required: true,
    },

    delivery: {
      type: Object,
      default: null,
    },

    deliveryOptions: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      iconPath: null,
      providerName: null,
      providerId: null,
    };
  },

  created() {
    this.providerId = this.getProviderId();
    this.providerName = this.getProviderName(this.providerId);
    this.iconPath = this.getIconPath(this.providerId, this.providerName);
  },

  methods: {
    getTrackingNumber() {
      return this.delivery && this.delivery.trackingNumber ? this.delivery.trackingNumber : null;
    },

    getIconPath(providerId, providerName) {
      if (providerId) {
        return shippingProvidersMap[providerName] ? `/img/shipping-providers/${shippingProvidersMap[providerName]}.png` : '/img/shipping-providers/default_provider.png';
      }

      return null;
    },

    getProviderName(providerId) {
      return providerId ? providerId.split('_')[0] : null;
    },

    getProviderId() {
      return this.deliveryOptions && this.deliveryOptions.initial
        ? this.deliveryOptions.initial.providerId : null;
    },
  },
};
</script>

<style lang="scss">
  .shipping-information {
    width: 100%;

    &__label {
      width: 70px;
      padding-top: 5px;
      vertical-align: top;
      color: #545454;
    }

    &__value {
      padding-top: 5px;
      vertical-align: middle;
      color: #333333;
      word-break: break-all;
    }
  }
</style>
