import qs from 'qs';
import { parseSuccessResponse, parseErrorResponse } from '@/helpers';
import { CancelToken } from 'axios';
import ApiClass from '../Api/Api';

let ordersSource;

export default class ReturnsApi extends ApiClass {
  getRmas(contextId, params, type) {
    if (ordersSource) {
      ordersSource.cancel('Operation canceled by the user.');
    }

    ordersSource = CancelToken.source();
    const requestParams = {};
    let queryString = '';
    requestParams.legacyMode = true;

    if (typeof params.offset !== 'undefined') {
      requestParams.offset = params.offset;
    }

    if (typeof params.limit !== 'undefined') {
      requestParams.limit = params.limit;
    }

    if (typeof params.status !== 'undefined') {
      if (params.status === 'confirmed') requestParams['filters[status][eq]'] = 'confirmed';
      if (params.status === 'pending') requestParams['filters[status][eq]'] = 'pending';
      if (params.status === 'approved') requestParams['filters[status][eq]'] = 'accepted';
      if (params.status === 'rejected') requestParams['filters[status][eq]'] = 'declined';
    }

    if (typeof params.query !== 'undefined') {
      requestParams['filters[query][eq]'] = params.query;
    }

    requestParams['filters[source][eq]'] = type;

    if (Object.keys(requestParams).length > 0) {
      queryString = `?${qs.stringify(requestParams, { arrayFormat: 'brackets' })}`;
    }

    return this.http.get(`/context/${contextId}/returns${queryString}`, { cancelToken: ordersSource.token })
      .then(parseSuccessResponse)
      .then((response) => ({
        data: response?.data?.items.map(({
          createdAt: createdDatetime,
          ...rest
        }) => ({
          createdDatetime,
          ...rest,
        })) || [],
        meta: {
          ...response.meta,
          totalItemCount: response.meta.total,
        },
      }))
      .catch(parseErrorResponse);
  }

  getRma(contextId, returnId) {
    return this.http.get(`/context/${contextId}/returns/${returnId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  acceptRma(contextId, returnId) {
    return this.http
      .patch(`/context/${contextId}/returns`, {
        returns: [
          {
            id: returnId,
            status: 'accepted',
          },
        ],
      })
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  declineRma(contextId, returnId, reason) {
    return this.http
      .patch(`/context/${contextId}/returns`, {
        returns: [
          {
            id: returnId,
            status: 'declined',
            reason,
          },
        ],
      })
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getRmaRequests(contextId, params) {
    const requestParams = {
      ...params,
      source: 'partner',
    };

    if (typeof params?.status === 'undefined') {
      delete requestParams.status;
    }

    const queryString = qs.stringify(requestParams, { arrayFormat: 'brackets' });

    return this.http.get(`/context/${contextId}/returns/${queryString}`)
      .then(parseSuccessResponse)
      .then((response) => ({
        data: response.data.items.map((item) => ({
          ...item,
          // fixed incopatibility in api
          status: this.fixRmaRequestStatus(item.status),
        })),
        meta: {
          ...response.meta,
          // fixed incopatibility in api
          totalItemCount: response.meta.total,
        },
      }))
      .catch(parseErrorResponse);
  }

  getRmaRequest(contextId, returnId) {
    const requestParams = {
      source: 'partner',
    };
    const queryString = qs.stringify(requestParams, { arrayFormat: 'brackets' });

    return this.http.get(`/context/${contextId}/returns/${returnId}/${queryString}`)
      .then(parseSuccessResponse)
      .then((response) => ({
        data: {
          ...response.data,
        },
        meta: response.meta,
      }))
      .catch(parseErrorResponse);
  }

  createRmaRequest(contextId, positions) {
    return this.http
      .post(`/context/${contextId}/returns`, {
        positions,
      })
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }
}
