<template>
  <div class="layout__grow">
    <slot />
  </div>
</template>
<script>

import { refreshInterval } from '@/const';

export default {
  name: 'ShopsRefreshStats',
  data() {
    return {
      interval: null,
    };
  },

  computed: {
    isLoading() {
      return this.$store.state.shops.isLoading;
    },

    shops() {
      return this.$store.getters['shops/list'];
    },
  },

  watch: {
    isLoading(newValue) {
      if (!newValue) {
        this.start();
      }
    },
  },

  mounted() {
    this.fetchOrders();
  },

  beforeDestroy() {
    this.stop();
  },

  methods: {
    start() {
      if (!this.interval) {
        this.interval = setInterval(() => {
          this.fetchOrders();
        }, refreshInterval);
      }
    },

    stop() {
      if (this.interval) {
        clearInterval(this.interval);
      }
    },

    fetchOrders() {
      if (this.shops.length > 0) {
        this.$store.dispatch('statistics/fetchAll', this.shops.map((shop) => shop.id));
      }
    },
  },
};
</script>
