<template>
  <message-template
    icon="/illustration-no-results.svg"
    :text="message"
    additional-text=""
  />
</template>

<script>
import MessageTemplate from '@/modules/Orders/components/Messages/_MessageTemplate.vue';

export default {
  components: {
    MessageTemplate,
  },

  props: {
    message: {
      type: String,
      default: 'Sorry, no RMA requests found',
    },
  },
};
</script>
