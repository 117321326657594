import axios from 'axios';
import qs from 'qs';
import { parseSuccessResponse, parseErrorResponse } from '@/helpers';

const fixRmaRequestStatus = (status) => (status === 'confirmed' ? 'confirmed' : status);

export default class OrderApi {
  constructor(options, interceptors) {
    this.http = axios.create(options);

    if (interceptors && interceptors.request && interceptors.request.length) {
      interceptors.request.map((requestInterceptor) => this.http.interceptors.request.use(
        requestInterceptor.onRequest,
        requestInterceptor.onError,
      ));
    }

    if (interceptors && interceptors.response && interceptors.response.length) {
      interceptors.response.map((requestInterceptor) => this.http.interceptors.response.use(
        requestInterceptor.onResponse,
        requestInterceptor.onError,
      ));
    }
  }

  getTransfers(shopId, params) {
    const requestParams = {};
    let queryString = '';

    if (typeof params.sort !== 'undefined') {
      requestParams.sort = params.sort;
    }

    if (typeof params.offset !== 'undefined') {
      requestParams.offset = params.offset;
    }

    if (typeof params.limit !== 'undefined') {
      requestParams.limit = params.limit;
    }

    if (Object.keys(requestParams).length > 0) {
      queryString = `?${qs.stringify(requestParams, { arrayFormat: 'brackets' })}`;
    }

    return this.http.get(`/shops/${shopId}/transfers${queryString}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getNotifications(shopIds) {
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 30);
    const requestParams = {
      locations: shopIds,
      legacyMode: true,
      // eslint-disable-next-line radix
      date_from: Number.parseInt(dateFrom.getTime() / 1000), // This will give time is seconds
    };
    let queryString = '';

    if (Object.keys(requestParams).length > 0) {
      queryString = `?${qs.stringify(requestParams, { arrayFormat: 'brackets' })}`;
    }

    return this.http.get(`/shops/notifications${queryString}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getOrders(shopId, params) {
    const requestParams = {};
    let queryString = '';

    if (typeof params.sort !== 'undefined') {
      requestParams.sort = params.sort;
    }

    if (typeof params.status !== 'undefined') {
      requestParams.status = params.status;
    }

    if (typeof params.offset !== 'undefined') {
      requestParams.offset = params.offset;
    }

    if (typeof params.limit !== 'undefined') {
      requestParams.limit = params.limit;
    }

    if (typeof params.awaitingTrackingNumber !== 'undefined') {
      requestParams.awaitingTrackingNumber = params.awaitingTrackingNumber;
    }

    if (typeof params.query !== 'undefined') {
      requestParams.query = params.query;
    }

    if (Object.keys(requestParams).length > 0) {
      queryString = `?${qs.stringify(requestParams, { arrayFormat: 'brackets' })}`;
    }

    return this.http.get(`/shops/${shopId}/orders${queryString}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getTransfer(shopId, transferId) {
    return this.http.get(`/shops/${shopId}/transfers/${transferId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getOrder(shopId, orderId) {
    return this.http.get(`/shops/${shopId}/orders/${orderId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getShop(shopId) {
    if (!shopId) {
      return Promise.reject();
    }

    return this.http.get(`/shops/${shopId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  /**
   * Fetch reasons of rejections for orders and transfers positions
   */
  getDeclineReasons(config) {
    return this.http.get('/decline-reasons', config)
      .then((response) => response.data);
  }

  updateTransfer(shopId, transferId, payload) {
    return this.http
      .patch(`/shops/${shopId}/transfers/${transferId}`, payload)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  updateOrder(shopId, orderId, payload) {
    return this.http
      .patch(`/shops/${shopId}/orders/${orderId}`, payload)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getShippingProviders(shopId, orderId) {
    return this.http
      .get(`/shops/${shopId}/shipping-providers/orders/${orderId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  bookShipping(shopId, orderId, payload) {
    if (shopId && orderId && payload.variantId && payload.pickupPointId) {
      return this.http
        .post(`/shops/${shopId}/orders/${orderId}/shippings/book`, payload)
        .then(parseSuccessResponse)
        .catch(parseErrorResponse);
    }

    return Promise.reject();
  }

  generateLabel(shopId, orderId, payload) {
    return this.http.post(`/shops/${shopId}/orders/${orderId}/labels`, payload)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getRmas(shopId, payload) {
    const requestParams = {
      ...payload,
    };
    const queryString = qs.stringify(requestParams, { arrayFormat: 'brackets' });

    return this.http.get(`/shops/${shopId}/rmas?${queryString}`)
      .then(parseSuccessResponse)
      .then((response) => ({
        data: response.data,
        meta: {
          ...response.meta,
          // fixed incopatibility in api
          totalItemCount: response.meta.allResultCount,
        },
      }))
      .catch(parseErrorResponse);
  }

  getRma(shopId, returnId) {
    return this.http.get(`/shops/${shopId}/rmas/${returnId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  acceptRma(shopId, returnId) {
    return this.http.patch(`/shops/${shopId}/rmas/${returnId}`, {
      status: 'accepted',
    })
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  declineRma(shopId, returnId, reason) {
    return this.http.patch(`/shops/${shopId}/rmas/${returnId}`, {
      status: 'declined',
      reason,
    })
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getRmaRequests(shopId, payload) {
    const requestParams = {
      ...payload,
    };
    const queryString = qs.stringify(requestParams, { arrayFormat: 'brackets' });

    return this.http.get(`/shops/${shopId}/rma-requests?${queryString}`)
      .then(parseSuccessResponse)
      .then((response) => ({
        data: response.data.map((item) => ({
          ...item,
          // fixed incopatibility in api
          status: fixRmaRequestStatus(item.status),
        })),
        meta: {
          ...response.meta,
          // fixed incopatibility in api
          totalItemCount: response.meta.allResultCount,
        },
      }))
      .catch(parseErrorResponse);
  }

  getRmaRequest(shopId, rmaRequestId) {
    return this.http.get(`/shops/${shopId}/rma-requests/${rmaRequestId}`)
      .then(parseSuccessResponse)
      .then((response) => ({
        data: {
          ...response.data,
          // fixed incopatibility in api
          status: fixRmaRequestStatus(response.data.status),
        },
        meta: response.meta,
      }))
      .catch(parseErrorResponse);
  }

  createRmaRequest(shopId, orderId, returnedPositions) {
    return this.http.post(`/shops/${shopId}/rma-requests`, {
      orderId,
      returnedPositions,
    })
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }
}
