<template>
  <div class="order-rma-request-header">
    <h2 class="order-rma-request-header__id">Order #{{ id }}</h2>
    <span class="order-rma-request-header__date">Created at {{ createdDate | date() }}</span>
  </div>
</template>

<script>
import DateFilter from '@/logic/filters/date';

export default {
  name: 'OrderRmaRequestHeader',
  filters: {
    date: DateFilter,
  },

  props: {
    id: {
      type: String,
      required: true,
    },

    createdDate: {
      type: Date,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.order-rma-request-header {
  margin-bottom: 32px;

  &__id {
    display: inline-block;
    font-weight: normal;
    font-size: 30px;
    line-height: 1em;
    margin-bottom: 12px;
  }

  &__order,
  &__date {
    display: block;
    font-size: 12px;
    line-height: 1em;
    margin-bottom: 1em;
  }
}
</style>
