<template>
  <div id="app">
    <router-view />
    <portal-target name="outer-portal" />
  </div>
</template>

<script>
export default {
  beforeMount() {
    this.$store.dispatch("auth/init");

    if (this.$store.state.auth.isLoggedIn) {
      this.initShops();
    } else {
      this.$root.$once("auth/loggedIn", () => {
        this.initShops();
      });
    }

    if (this.$store.state.envConfig.scripts.google_analytics.id) {
      this.initGTM();
      this.initGTAG();
    }

    if (this.$store.state.envConfig.scripts.get_site_control.id) {
      this.initSiteControl();
    }

    if (this.$store.state.envConfig.scripts.elevio.id) {
      this.initElevio();
    }

    if (this.$store.state.envConfig.scripts.dixa.enabled
    && this.$store.state.envConfig.scripts.dixa.messangerToken
    && this.$store.state.envConfig.scripts.dixa.id) {
      this.initDixa();
    }

    if (this.$store.state.envConfig.scripts.beamer
    && this.$store.state.envConfig.scripts.beamer.id
    && this.$store.state.envConfig.scripts.beamer.enabled) {
      this.initBeamerConfig();
      this.initBeamer();
    }
  },

  methods: {
    /* eslint-disable */
    initShops() {
      if (
        this.$store.getters["auth/availableShops"] &&
        this.$store.getters["auth/availableShops"].length
      ) {
        this.$store.dispatch("shops/init", this.$store.getters["auth/availableShops"]); // TODO check
        this.$store.dispatch("shops/fetchAll").then(() => {
          this.$root.$emit("shops/initialized");
        });
      }
    },
    initBeamer() {
      const beamer = document.createElement("script");
      beamer.setAttribute("src", "https://app.getbeamer.com/js/beamer-embed.js");
      document.head.appendChild(beamer);
    },
    initGTM() {
      const script = document.createElement("script");
      script.src = `https://www.googletagmanager.com/gtag/js?id=${this.$store.state.envConfig.scripts.google_analytics.id}`;
      script.setAttribute("type", "text/javascript");
      script.async = true;
      document.body.appendChild(script);
    },
    initGTAG() {
      const script = document.createElement("script");
      const scriptBody = `
        window.dataLayer = window.dataLayer || [];
        function gtag(){dataLayer.push(arguments);}
        gtag('js', new Date());
        gtag('config', "` + this.$store.state.envConfig.scripts.google_analytics.id + `");
      `;
      const scriptBodyText = document.createTextNode(scriptBody);
      script.appendChild(scriptBodyText);
      document.body.appendChild(script);
    },
    initSiteControl() {
      const script = document.createElement("script");
      const scriptBody = `
        (function (w, i, d, g, e, t, s) {
          w[d] = w[d] || []; t = i.createElement(g);
          t.async = 1; t.src = e; s = i.getElementsByTagName(g)[0]; s.parentNode.insertBefore(t, s);
        })(window, document, '_gscq', 'script', '//widgets.getsitecontrol.com/` + this.$store.state.envConfig.scripts.get_site_control.id + `/script.js');
      `;
      const scriptBodyText = document.createTextNode(scriptBody);
      script.appendChild(scriptBodyText);
      document.body.appendChild(script);
    },
    initElevio() {
      const script = document.createElement("script");
      const scriptBody = `
        !function (e, l, v, i, o, n) { e[i] || (e[i] = {}), e[i].account_id = n; var g, h; g = l.createElement(v), g.type = "text/javascript", g.async = 1, g.src = o + n, h = l.getElementsByTagName(v)[0], h.parentNode.insertBefore(g, h); e[i].q = []; e[i].on = function (z, y) { e[i].q.push([z, y]) } }(window, document, "script", "_elev", "https://cdn.elev.io/sdk/bootloader/v4/elevio-bootloader.js?cid=", "` + this.$store.state.envConfig.scripts.elevio.id + `");
      `;
      const scriptBodyText = document.createTextNode(scriptBody);
      script.appendChild(scriptBodyText);
      document.body.appendChild(script);
    },
    initDixa() {
      const script = document.createElement("script");
      const scriptBody = `
        (function (w, d, s) {
          if (w._dixa_) return;
          w._dixa_ = {
            invoke: function (m, a) {
              w._dixa_.pendingCalls = w._dixa_.pendingCalls || [];
              w._dixa_.pendingCalls.push([m, a]);
            },
            addListener: function (event, listener) {
              w._dixa_.pendingAddListenerCalls = w._dixa_.pendingAddListenerCalls || [];
              w._dixa_.pendingAddListenerCalls.push([event, listener]);
            },
          };
          s = d.createElement('script');
          s.type = 'text/javascript';
          s.setAttribute('charset', 'utf-8');
          s.async = true;
          s.src = 'https://messenger.dixa.io/bootstrap.js';
          var before = d.getElementsByTagName('script')[0];
          before.parentNode.insertBefore(s, before);
        })(window, document);

        _dixa_.invoke("init", { messengerToken: "` + this.$store.state.envConfig.scripts.dixa.messangerToken + `", hideToggler: true });
      `;
      const scriptBodyText = document.createTextNode(scriptBody);
      script.appendChild(scriptBodyText);
      document.body.appendChild(script);
    },
    initBeamerConfig() {
      const script = document.createElement("script");
      const scriptBody = `
        var beamer_config = {
          product_id: '` + this.$store.state.envConfig.scripts.beamer.id + `',
          selector: '#beamerButton',
        };
      `;
      const scriptBodyText = document.createTextNode(scriptBody);
      script.appendChild(scriptBodyText);
      document.body.appendChild(script);
    }
  },
};
</script>

<style lang="scss">
@font-face {
  font-family: "Sofia";
  src: url("../public/fonts/SofiaProRegular.eot");
  src: url("../public/fonts/SofiaProRegular.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/SofiaProRegular.woff2") format("woff2"),
    url("../public/fonts/SofiaProRegular.woff") format("woff"),
    url("../public/fonts/SofiaProRegular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia";
  src: url("../public/fonts/SofiaProLight.eot");
  src: url("../public/fonts/SofiaProLight.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/SofiaProLight.woff2") format("woff2"),
    url("../public/fonts/SofiaProLight.woff") format("woff"),
    url("../public/fonts/SofiaProLight.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Sofia";
  src: url("../public/fonts/SofiaProBold.eot");
  src: url("../public/fonts/SofiaProBold.eot?#iefix") format("embedded-opentype"),
    url("../public/fonts/SofiaProBold.woff2") format("woff2"),
    url("../public/fonts/SofiaProBold.woff") format("woff"),
    url("../public/fonts/SofiaProBold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

body,
html {
  min-height: 100%;
  height: 100%;
}

#app {
  font-family: "Sofia", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
  height: 100%;
  font-size: 14px;
}
</style>
