<template>
  <div class="returns-list">
    <div class="returns-list__header">
      <div class="returns-list-col returns-list-col--return-id">Return ID</div>
      <div class="returns-list-col returns-list-col--order-id">Order ID</div>
      <div class="returns-list-col returns-list-col--date">Date</div>
      <div class="returns-list-col returns-list-col--quantity">Quantity</div>
      <div class="returns-list-col returns-list-col--total">Total</div>
      <div class="returns-list-col returns-list-col--status">Status</div>
      <div class="returns-list-col returns-list-col--actions">&nbsp;</div>
    </div>
    <div class="returns-list__body">
      <template v-for="(item, key) in items">
        <slot
          name="item"
          :item="item"
          :item-key="key"
          :class-names="['returns-list__item', { 'returns-list__item--even': !!(key % 2) }]"
        >
          <returns-list-item
            :key="key"
            :item="item"
            :class-names="['returns-list__item', { 'returns-list__item--even': !!(key % 2) }]"
          />
        </slot>
      </template>
    </div>
  </div>
</template>

<script>
import ReturnsListItem from './RetunsListItem.vue';

export default {
  components: {
    ReturnsListItem,
  },

  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
@import './table.scss';
@import './columns.scss';

.returns-list {
  &__header {
    @include row();
    border-bottom: 1px solid #E4E4F0;
  }

  &__item {
    &--even {
      background: #F9F9F9;
    }
  }
}
</style>
