<template>
  <ul class="orders-list">
    <li
      v-for="order in orders"
      :key="order.id"
      class="orders-list__item"
    >
      <order-item
        :order="order"
        :shop-id="shopId"
        :url-query="urlQuery"
        :checked="isChecked(order.id)"
        :context="context"
        @checked="handleChecked"
      />
    </li>
  </ul>
</template>

<script>
import OrderItem from '@/modules/Orders/components/OrdersList/OrdersListItem.vue';

export default {
  components: {
    OrderItem,
  },

  props: {
    orders: {
      type: Array,
      default: () => [],
    },
    shopId: {
      type: String,
      required: true,
    },

    urlQuery: {
      type: Object,
      default: () => {},
    },

    checkedOrders: {
      type: Array,
      default: () => [],
    },

    context: {
      type: String,
      default: null,
    },
  },

  watch: {
    checkedOrders(val) {
      // eslint-disable-next-line vue/no-mutating-props
      this.checkedOrders = val;
    },
  },

  methods: {
    isChecked(orderId) {
      return this.checkedOrders.indexOf(orderId) >= 0;
    },

    handleChecked(value) {
      this.$emit('checked', value);
    },
  },
};
</script>

<style lang="scss">
.orders-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    padding: 0;
    margin: 0;
    border-bottom: 1px solid #D8D8D8;

    &:last-child {
      border-bottom: none;
    }
  }
}
</style>
