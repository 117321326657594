import dayjs from 'dayjs';

export default function dateFilter(input) {
  const createdAt = dayjs(input).format('YYYY-MM-DD');
  const today = dayjs(new Date()).format('YYYY-MM-DD');
  const yesterday = dayjs(today).add(-1, 'day').format('YYYY-MM-DD');

  if (createdAt === today) return `Today, ${dayjs(input).format('HH:mm')}`;
  if (createdAt === yesterday) return `Yesterday, ${dayjs(input).format('HH:mm')}`;
  return dayjs(input).format('DD MMM YYYY, HH:mm');
}
