/* eslint-disable */

import { parseSuccessResponse, parseErrorResponse } from '@/helpers';
import ApiClass from '../Api/Api';

export default class StatisticsApi extends ApiClass {
  getStatistics(shopsIds) {
    const dateFrom = new Date();
    dateFrom.setDate(dateFrom.getDate() - 30);

    return this.http.get(`/statistics?legacyMode=true&filters[dateFrom][gt]=${Number.parseInt(dateFrom.getTime() / 1000)}&${shopsIds.map(id => `filters[contextId][in][]=${id}`).join('&')}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }
}
