import accounting from 'accounting';

export default function priceFilter(input, currency) {
  const accountingOptions = {
    symbol: currency,
    format: '%v %s',
    thousand: '.',
    decimal: ',',
    precision: input % 100 ? 2 : 0,
  };

  return accounting.formatMoney((input / 100), accountingOptions);
}
