<template>
  <component
    :is="component"
    :href="href"
    class="ui-tabs-tab"
    :class="classes"
    @click="handleClick"
  >
    <slot />
  </component>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    href: {
      type: String,
      default: null,
    },
  },

  computed: {
    classes() {
      return {
        'is-active': this.active,
      };
    },

    component() {
      return this.href ? 'a' : 'span';
    },
  },

  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss">
.ui-tabs-tab {
  $ui-tabs-tab-border-radius: 5px;

  display: inline-block;
  cursor: pointer;
  position: relative;
  display: inline-block;

  flex-basis: auto;
  flex-grow: 0;
  flex-shrink: 0;

  padding: 16px 20px;
  margin-right: 16px;

  line-height: 20px;
  font-size: 16px;
  font-weight: 500;
  color: inherit;

  border-radius: $ui-tabs-tab-border-radius $ui-tabs-tab-border-radius 0 0;

  &:hover {
    color: #006EF5;
  }

  &.is-active {
    background: #fff;
    color: #006EF5;

    &:after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: #1F64EE;
      height: 2px;
      border-radius: $ui-tabs-tab-border-radius $ui-tabs-tab-border-radius 0 0;
    }
  }
}
</style>
