import qs from 'qs';
import { CancelToken } from 'axios';
import { parseSuccessResponse, parseErrorResponse } from '@/helpers';
import ApiClass from '../Api/Api';
import { parseOrdersToLegacy } from './Orders.helpers';

let ordersSource;

export default class OrdersApi extends ApiClass {
  getOrders(contextId, params, legacy = true) {
    if (ordersSource) {
      ordersSource.cancel('Operation canceled by the user.');
    }

    ordersSource = CancelToken.source();

    const requestParams = {};
    let queryString = '';

    requestParams.legacyMode = legacy;

    if (params.sort === '-createdAt') {
      requestParams['sort[createdAt]'] = 'desc';
    }

    if (params.sort === '+createdAt') {
      requestParams['sort[createdAt]'] = 'asc';
    }

    if (typeof params.status !== 'undefined') {
      requestParams['filters[status][eq]'] = params.status?.[0];
    }

    if (typeof params.offset !== 'undefined') {
      requestParams.offset = params.offset;
    }

    if (typeof params.limit !== 'undefined') {
      requestParams.limit = params.limit;
    }

    if (typeof params.awaitingTrackingNumber !== 'undefined' && params.awaitingTrackingNumber) {
      requestParams['filters[hasTrackingNumber][eq]'] = !params.awaitingTrackingNumber;
    }

    if (typeof params.query !== 'undefined') {
      requestParams['filters[query][eq]'] = params.query;
    }

    if (Object.keys(requestParams).length > 0) {
      queryString = `?${qs.stringify(requestParams, { arrayFormat: 'brackets' })}`;
    }

    return this.http.get(`/context/${contextId}/order-positions/${queryString}`, { cancelToken: ordersSource.token })
      .then(parseSuccessResponse)
      .then((response) => ({
        meta: { ...response.meta, totalItemCount: response.meta.total },
        data: parseOrdersToLegacy(response.data.items),
      }))
      .catch(parseErrorResponse);
  }

  getOrder(contextId, orderId, status, availabilityOptions, legacy = true) {
    const requestParams = {};
    let queryString = '';
    requestParams.legacyMode = legacy;
    requestParams['filters[query][eq]'] = orderId;

    if (typeof status !== 'undefined') {
      requestParams['filters[status][eq]'] = status;
    }

    if (Object.keys(requestParams).length > 0) {
      queryString = `?${qs.stringify(requestParams, { arrayFormat: 'brackets' })}`;
    }

    return this.http.get(`/context/${contextId}/order-positions/${queryString}`)
      .then(parseSuccessResponse)
      .then((response) => ({
        meta: { ...response.meta, totalItemCount: response.meta.total },
        data: { ...parseOrdersToLegacy(response.data.items)[0], ...availabilityOptions },
      }))
      .catch(parseErrorResponse);
  }

  getOrderRMA(contextId, positionId, status, legacy = true) {
    const requestParams = {};
    let queryString = '';
    let positionIdsString = '';
    requestParams.legacyMode = legacy;

    if (Array.isArray(positionId)) {
      positionIdsString = '&';
      positionId.forEach((item) => { positionIdsString += `filters[positionId][in][]=${item}&`; });
      positionIdsString.slice();
    } else {
      requestParams['filters[positionId][in][]'] = positionId;
    }

    if (typeof status !== 'undefined') {
      requestParams['filters[status][eq]'] = status;
    }

    if (Object.keys(requestParams).length > 0) {
      queryString = `?${qs.stringify(requestParams, { arrayFormat: 'brackets' })}${positionIdsString}`;
    }

    return this.http.get(`/context/${contextId}/order-positions${queryString}`)
      .then(parseSuccessResponse)
      .then((response) => ({
        meta: { ...response.meta, totalItemCount: response.meta.total },
        data: { ...Array.isArray(positionId) ? response.data.items : response.data.items[0] },
      }))
      .catch(parseErrorResponse);
  }

  getDeclineReasons() {
    return this.http.get('/order-positions/decline-reasons')
      .then(parseSuccessResponse)
      .then((response) => ({
        ...response.meta,
        data: response.data.items.map((item) => ({ id: item.code, requiresTextAnswer: item.isAnswerRequired, defaultTranslation: item.reason })),
      }))
      .catch(parseErrorResponse);
  }

  updateOrderPositions(contextId, payload) {
    return this.http
      .patch(`/context/${contextId}/order-positions`, payload)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }
}
