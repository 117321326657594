/* eslint class-methods-use-this: ["error", {
  "exceptMethods": ["hashPayload", "hashResource", "getTimestamp", "generateSeed"]
}] */

import crypto from 'crypto-js';
import { hasAccessToAllStores } from '@/helpers';

class AuthChannel {
  constructor(id, token, data) {
    this.id = id;
    this.token = token;

    this.data = data;

    this.authType = 'MNT-HMAC-SHA256-1-0';
  }

  hashPayload(payload) {
    let payloadText = payload;

    if (payload === null || payload === undefined || (typeof payload === 'string' && payload.length === 0)) {
      payloadText = '';
    } else if (typeof payload === 'string') {
      payloadText = payload;
    } else {
      payloadText = JSON.stringify(payload);
    }

    return crypto.SHA256(payloadText).toString();
  }

  hashResource(method, domain, path, query) {
    const resourceText = `${method}\n${domain}\n${path}\n${query}`;

    if (['GET', 'POST', 'DELETE', 'PUT', 'PATCH'].indexOf(method) === -1) {
      throw new TypeError(`Method ${method} is not allowed`);
    }

    if (typeof domain !== 'string') {
      throw new TypeError('Domain is incorrect');
    }

    return crypto.SHA256(resourceText).toString();
  }

  generateHeaderHash(timestamp, seed) {
    const header = `${this.id}\n${timestamp}\n${seed}\n${this.authType}`;

    return crypto.SHA256(header).toString();
  }

  signRequest(method, domain, path, query, payload) {
    const timestamp = this.getTimestamp();
    const seed = this.generateSeed();
    const resourceHash = this.hashResource(method, domain, path, query);
    const headerHash = this.generateHeaderHash(timestamp, seed);
    const payloadHash = this.hashPayload(payload);
    const text = `${resourceHash}\n${headerHash}\n${payloadHash}`;

    return {
      signature: crypto.HmacSHA256(text, this.token).toString(),
      timestamp,
      seed,
    };
  }

  getTimestamp() {
    return Math.round(+new Date() / 1000);
  }

  generateSeed() {
    // Change algorithm (for example Crypto.getRandomValues())
    return Math.floor(Math.random() * 99);
  }

  setData(data) {
    this.data = data;
  }

  getData() {
    return this.data;
  }

  serialize() {
    return {
      id: this.id,
      token: this.token,
      data: this.data,
      hasAccessToAllStores: hasAccessToAllStores(this.data),
    };
  }
}

export default AuthChannel;
