import { addExtraOrderData } from '@/helpers';
import apiV2 from '@/apiV2/api';

import providers from '@/store/order/providers';
import positions from '@/store/order/positions';
import withMSA from '@/store/order/withMSA';
import withoutMSA from '@/store/order/withoutMSA';
import rmaRequest from '@/store/order/rmaRequest';

export const types = {
  RESET: 'RESET',
  FETCH_REQUEST: 'FETCH_REQUEST',
  BACKGROUND_UPDATE_REQUEST: 'BACKGROUND_UPDATE_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  BACKGROUND_UPDATE_SUCCESS: 'BACKGROUND_UPDATE_SUCCESS',
  FETCH_FAILED: 'FETCH_FAILED',
  SET_DATA: 'SET_DATA',
};

const getAsyncRequestResult = async (context, requestId) => {
  context.commit(types.FETCH_REQUEST);

  return new Promise((resolve, reject) => {
    let attempts = 1;
    // eslint-disable-next-line consistent-return
    const fetchRetry = (n) => apiV2(context).asyncOperations.getAsyncOperationResult(requestId).then((response) => {
      if (n === 1) {
        context.commit(types.FETCH_FAILED, 'Your request is queued for processing');
        throw new Error('Something went wrong');
      } else if (response.data.processStatus === 'failure') {
        context.commit(types.FETCH_FAILED, 'Please contact with our support');
        throw new Error('Something went wrong');
      } else if (response.data.processStatus !== 'success') {
        setTimeout(() => {
          attempts++;
          fetchRetry(n - 1);
        }, attempts * 1000);
      } else {
        context.commit(types.FETCH_SUCCESS);
        return resolve(response);
      }
    }).catch((error) => {
      if (n === 1 || error.message === 'Something went wrong') {
        reject(error);

        context.commit(types.FETCH_FAILED, error.message);
      } else {
        setTimeout(() => {
          attempts++;
          fetchRetry(n - 1);
        }, attempts * 1000);
      }
    });
    // eslint-disable-next-line no-promise-executor-return
    return fetchRetry(10);
  });
};

const initialState = () => ({
  isLoading: false,
  isFailed: false,
  data: null,
  errorMsg: null,
  isBackgroundUpdateRequest: false,
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  modules: {
    positions,
    providers,
    withMSA,
    withoutMSA,
    rmaRequest,
  },
  getters: {
    isShippingInProgress(state) {
      return state.data && state.data.shippingInformation.status === 'in_progress';
    },

    isPrintSelectedAvailable(state) {
      // check if there is one or two types of docs(labels, info)
      // if only one type of docs found printSelected is disabled
      return state.data && state.data.availabilityOptions
        ? (state.data.availabilityOptions.shipping.documentsTypes?.filter((doc) => doc.includes('info')).length > 0
          && state.data.availabilityOptions.shipping.documentsTypes?.filter((doc) => doc.includes('label')).length > 0)
        : false;
    },

    isAcceptedPositonsIds(state) {
      const allPos = [];
      state.data.acceptedPositions.forEach((item) => {
        item.ids.forEach((el) => {
          const tempObj = {
            positionId: el,
          };
          allPos.push(tempObj);
        });
      });
      return allPos;
    },

  },

  mutations: {
    [types.RESET](state) {
      Object.assign(state, initialState());
    },

    [types.FETCH_REQUEST](state) {
      state.isBackgroundUpdateRequest = false;
      state.isLoading = true;
      state.isFailed = false;
      state.errorMsg = null;
    },

    [types.BACKGROUND_UPDATE_REQUEST](state) {
      state.isFailed = false;
      state.errorMsg = null;
      state.isBackgroundUpdateRequest = true;
    },

    [types.FETCH_SUCCESS](state) {
      state.isLoading = false;
      state.isFailed = false;
    },
    [types.BACKGROUND_UPDATE_SUCCESS](state) {
      state.isFailed = false;
    },

    [types.FETCH_FAILED](state, msg) {
      state.isLoading = false;
      state.isFailed = true;
      state.errorMsg = msg;
    },

    [types.SET_DATA](state, data) {
      state.data = data;
    },
  },

  actions: {
    async updateOrder(context, data) {
      const payload = context.getters['positions/newPositionsPayload'];
      context.commit(types.FETCH_REQUEST);
      const positionsArr = [...payload.acceptedPositions.map((item) => ({ id: item.id, locationId: data.shopId, status: 'accepted' })), ...payload.declinedPositions.map((item) => ({
        id: item.id,
        locationId: data.shopId,
        status: 'declined',
        declineReason: {
          text: item.declineReasonText || item.declineReasonCode,
          code: item.declineReasonCode,
        },
      }))].reduce((acc, curr) => [...acc, ...curr.id.split('|').map((id) => ({ ...curr, id }))], []);
      const requestData = await apiV2(context).orders.updateOrderPositions(data.shopId, { positions: positionsArr });
      await getAsyncRequestResult(context, requestData?.data?.requestId);
      context.commit(types.FETCH_SUCCESS);
      const shopsIds = context.rootGetters['shops/list'];
      context.dispatch('statistics/fetchAll', shopsIds.map((shop) => shop.id), { root: true });
    },

    async bookShipping(context, data) {
      const payload = data.positionIds.map((id) => ({ positionId: id, providerVariantId: context.state.withMSA.packageTypeId, locationPickupPointId: context.state.withMSA.selectedPickupPoint }));

      context.commit(types.FETCH_REQUEST);

      const requestData = await apiV2(context).shipping.bookShipping(data.shopId, { positions: payload });
      await getAsyncRequestResult(context, requestData?.data?.requestId);
      context.dispatch('fetchOrder', { shopId: data.shopId, orderId: data.orderId });
    },

    async updateShipping(context, data) {
      const payload = data.positionIds.map((id) => ({ positionId: id, providerId: context.state.providers.selectedProvider, trackingNumber: context.state.withoutMSA.trackingNumber }));

      context.commit(types.FETCH_REQUEST);

      const requestData = await apiV2(context).shipping.updateShipping(data.shopId, { positions: payload });
      await getAsyncRequestResult(context, requestData?.data?.requestId);
      context.dispatch('fetchOrder', { shopId: data.shopId, orderId: data.orderId });
    },

    reset(context) {
      context.commit('positions/reset');
      context.commit('withMSA/reset');
      context.commit('withoutMSA/reset');
      context.commit('providers/RESET');
      context.commit('rmaRequest/RESET');
      context.commit(types.RESET);
    },

    initialFetchOrder(context, payload) {
      context.commit(types.SET_DATA, null);
      context.commit(types.FETCH_REQUEST);
      const isTransfer = payload.type === 'transfer';
      const availabilityOptions = context.rootGetters['shops/availabilityOptionsbyId'](payload.shopId);

      return new Promise((resolve, reject) => {
        const order = context.rootGetters['orders/ordersById'](payload.orderId);

        if (order) {
          resolve({ ...order, availabilityOptions });
        }
        reject(new Error('Order not exists'));
      }).then((result) => {
        const data = addExtraOrderData([result], {
          type: isTransfer ? 'transfer' : 'order',
        }).pop();

        context.commit(types.SET_DATA, data);
        context.commit(types.FETCH_SUCCESS);

        if (data.shippingInformation.deliveryInfo.trackingNumber && data.shippingInformation.deliveryInfo.trackingNumber !== '') {
          context.dispatch('orders/updateTrackAndTrace', {
            id: payload.orderId,
            trackingNumber: data.shippingInformation.deliveryInfo.trackingNumber,
          }, { root: true });
        }

        return {
          meta: {
            message: 'success',
            status: 'success',
          },
          data,
        };
      }).catch((error) => {
        context.commit(types.SET_DATA, null);
        context.commit(types.FETCH_FAILED, error.msg);
        throw error;
      });
    },

    fetchOrder(context, payload) {
      if (!payload.isBackgroundUpdate) {
        context.commit(types.SET_DATA, null);
      }
      context.commit(payload.isBackgroundUpdate
        ? types.BACKGROUND_UPDATE_REQUEST : types.FETCH_REQUEST);
      const isTransfer = payload.type === 'transfer';
      const availabilityOptions = context.rootGetters['shops/availabilityOptionsbyId'](payload.shopId);

      return apiV2(context).orders.getOrder(payload.shopId, payload.orderId, isTransfer ? 'pending' : undefined, { availabilityOptions })
        .then((response) => {
          const data = addExtraOrderData([response.data], {
            type: isTransfer ? 'transfer' : 'order',
          }).pop();

          context.commit(types.SET_DATA, data);
          context.commit(payload.isBackgroundUpdate
            ? types.BACKGROUND_UPDATE_SUCCESS : types.FETCH_SUCCESS);

          if (response.data.shippingInformation.deliveryInfo.trackingNumber) {
            context.dispatch('orders/updateTrackAndTrace', {
              id: payload.orderId,
              trackingNumber: response.data.shippingInformation.deliveryInfo.trackingNumber,
            }, { root: true });
          }

          return {
            ...response,
            data,
          };
        })
        .catch((error) => {
          if (context.state.isBackgroundUpdateRequest) return Promise.resolve();
          context.commit(types.SET_DATA, null);
          context.commit(types.FETCH_FAILED, error.msg);
          throw error;
        });
    },
    async generateLabel(context, payload) {
      context.commit(types.FETCH_REQUEST);

      const pos = context.getters.isAcceptedPositonsIds;

      const restPosIds = [];
      pos.forEach((el, index) => {
        if (el?.positionId?.includes('|')) {
          el.positionId.split('|').forEach((val) => {
            const tempObj = {
              positionId: val,
            };
            restPosIds.push(tempObj);
            pos.splice(index, 1);
          });
        }
      });

      const documentTypes = {
        labels: ['label_shipping', 'label_return'],
        info: ['order_info', 'return_info'],
        china: ['label_shipping', 'label_china_shipping'],
      };

      const documents = payload.documents.reduce((result, document) => [
        ...result, ...(documentTypes[document] || [document])], []);

      const requestData = await apiV2(context).shipping.getShippingDocuments(payload.shopId, {
        layout: payload.layout || 'default',
        documents,
        positions: [...pos, ...restPosIds],
      });

      const result = await getAsyncRequestResult(context, requestData?.data?.requestId);
      return result;
    },
  },
};
