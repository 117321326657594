<template>
  <div>
    <iv-modal
      v-model="isUpdating"
      footer-hide
      :closable="false"
      :mask-closable="false"
      class="shipping-awaiting"
    >
      <div class="shipping-awaiting__content">
        Saving changes...
        <ui-spinner class="shipping-awaiting__spinner" />
      </div>
    </iv-modal>

    <ui-error
      v-if="state.matches('updatingOrder.error')"
      class="shipping-awaiting__error"
      :subtitle="errorMsg"
      @try-again="onTryAgain"
    />

    <div v-if="state.matches('selectPositions')">
      <pending-positions-list
        v-if="positions.length > 0"
        :positions="positions"
        :currency="currency"
        :can-continue="allPositionsHaveStatus"
        @select="onPositionSelect"
        @set-decline-reason="onPositionReasonChange"
        @continue="handleOrderPositions"
      />
    </div>
  </div>
</template>

<script>
import {
  UiError,
  UiSpinner,
} from '@ui';
import { mapState, mapGetters } from 'vuex';
import { interpret } from 'xstate';
import {
  Modal as IvModal,
} from 'iview';

import PendingPositionsList from '../../Position/PendingPositionsList.vue';

import OrderAwaitingMachine from './OrderAwaitingMachine';

export default {
  components: {
    IvModal,
    UiError,
    UiSpinner,
    PendingPositionsList,
  },
  props: {
    shopId: {
      type: String,
      default: null,
    },

    orderId: {
      type: String,
      default: null,
    },

    hasMsa: {
      type: Boolean,
      default: null,
    },

    currency: {
      type: String,
      default: null,
    },

    orderType: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      stateMachine: null,
      state: null,
      processOrderId: this.orderId,
    };
  },

  computed: {
    ...mapState('orders/order', {
      errorMsg: 'errorMsg',
    }),
    ...mapState('orders/order/positions', {
      positions: 'positions',
    }),
    ...mapGetters('orders/order/positions', {
      allPositionsHaveStatus: 'allPositionsHaveStatus',
      allPositionsAreDeclined: 'allPositionsAreDeclined',
    }),

    isUpdating() {
      return this.state.matches('updatingOrder.updating');
    },
  },

  created() {
    this.stateMachine = interpret(OrderAwaitingMachine.withConfig({
      actions: {
        SELECT_POSITION: (context, { positionId, newStatus }) => {
          this.$store.dispatch('orders/order/positions/selectPosition', {
            positionId,
            newStatus,
          });
        },

        SET_REASON: (context, { positionId, reason }) => {
          this.$store.dispatch('orders/order/positions/setDeclineReason', {
            positionId,
            reason,
          });
        },

        REDIRECT_TO_ORDER: () => {
          this.$router.push({
            name: 'order',
            params: {
              shopId: this.shopId,
              orderId: this.processOrderId,
            },
            query: {
              context: 'accepted',
            },
          }).catch(() => {});
        },
      },

      guards: {
        noSelectedPositionsStatus: () => !this.allPositionsHaveStatus,
        hasMSA: () => this.hasMsa,
        noSelectedPickupPoint: () => this.noSelectedPickupPoint,
        allPositionsAreDeclined: () => this.allPositionsAreDeclined,
      },

      services: {
        UPDATE_ORDER: () => this.$store.dispatch('orders/order/updateOrder', {
          orderId: this.processOrderId,
          shopId: this.shopId,
          orderType: this.orderType,
        }).then(() => {
          this.$store.dispatch('orders/removeTransfer', this.processOrderId);
          this.$store.dispatch('statistics/decrementPendingCounter', this.shopId);
          this.$store.dispatch('orders/order/reset');
        }),
      },
    }))
      .onTransition((state) => {
        this.state = state;
      })
      .start();
  },

  methods: {
    onTryAgain() {
      this.stateMachine.send('BACK');
    },

    onPositionSelect({ positionId, newStatus }) {
      this.stateMachine.send('SELECT_POSITION', { positionId, newStatus });
    },

    onPositionReasonChange({ positionId, reason }) {
      this.stateMachine.send('SET_REASON', { positionId, reason });
    },

    handleOrderPositions() {
      this.stateMachine.send('CONTINUE');
    },
  },
};
</script>

<style lang="scss">
.shipping-awaiting {
  &__content {
    text-align: center;
    margin: 50px auto;
    font-size: 17px;
  }

  &__spinner {
    margin: 20px auto;
  }

  &__error {
    padding: 30px 0;
  }
}
</style>
