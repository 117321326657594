<template>
  <div class="order-base">
    <div v-if="messages.length > 0" class="order-base__messages">
      <ui-message v-if="messages.indexOf('rmaRequestCreated') !== -1" type="success">RMA Request has been created</ui-message>
    </div>
    <div class="order-base__header">
      <div class="order-base__header-status">
        <span class="order-base__price">
          {{ value | price(order.currency) }}
        </span>
        <order-tag class="order-base__tag">
          {{ getOrderStatus }}
        </order-tag>
        <order-tag
          v-if="isOrderWithPremiumShipping"
          class="order-base__tag"
          type="premium"
        >
          VIP
        </order-tag>
        <order-tag
          v-if="isAwaitingOrder"
          class="order-base__tag"
          type="pending"
        >
          New order
        </order-tag>
        <order-tag
          v-if="isOrderIncomplete"
          class="order-base__tag"
          type="incomplete"
        >
          Missing track & trace
        </order-tag>
      </div>
      <div class="order-base__header-basic">
        <div class="order-base__header-row">
          <span class="order-base__header-label">Created </span>
          <span class="order-base__header-value">{{ order.createdAt | date() }}</span>
        </div>
        <div class="order-base__header-row">
          <span class="order-base__header-label">Order ID </span>
          <span class="order-base__header-value">{{ order.parentOrder.id }} || {{ parsedOrderId }}</span>
        </div>
        <div v-if="extraInfo" class="order-base__extra-info">
          {{ extraInfo }}
        </div>
      </div>
    </div>
    <div class="order-base__content-wrapper">
      <div class="order-base__content">
        <div v-if="isAcceptedOrder" class="order-base__info-group">
          <div class="order-base__billing">
            <span class="order-base__section-label">BILLING</span>
            <billing-information :billing="order.billingInformation" />
          </div>

          <div class="order-base__shipping">
            <span class="order-base__section-label">SHIPPING</span>
            <shipping-information
              :address="order.shippingInformation.deliveryAddress"
              :delivery="order.shippingInformation.deliveryInfo"
              :delivery-options="order.shippingInformation.deliveryOptions"
            />

            <slot name="shippingFooter" />
          </div>
        </div>
        <div v-else class="order-base__info-rodo">
          Billing and shipping information is available when the order is accepted.
        </div>

        <slot name="middleSection" />
        <slot name="positions" />
        <div v-if="$scopedSlots.actions" class="order-base__actions">
          <slot name="actions" :classes="{ right: 'order-base__action--right', left: '' }" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DateFilter from '@/logic/filters/date';
import PriceFilter from '@/logic/filters/price';
import { UiMessage } from '@ui';
import OrderTag from '@/modules/Order/components/OrderTag.vue';
import { getOrderIdFromUUID } from '@/apiV2/api/Context/Context.helpers';
import BillingInformation from '../OrderDetailsBilling.vue';
import ShippingInformation from '../OrderDetailsShipping.vue';

export default {
  components: {
    UiMessage,
    BillingInformation,
    ShippingInformation,
    OrderTag,
  },

  filters: {
    date: DateFilter,
    price: PriceFilter,
  },

  props: {
    order: {
      type: Object,
      default: null,
    },
  },

  computed: {
    isOrderWithPremiumShipping() {
      return !!this.order.shippingInformation.deliveryInfo.isPremium;
    },

    isOrderIncomplete() {
      return this.order.status === 'accepted' && !this.order.shippingInformation.deliveryInfo.trackingNumber;
    },

    isAwaitingOrder() {
      return this.order.status === 'pending';
    },

    isAcceptedOrder() {
      return this.order.status === 'accepted';
    },

    getOrderStatus() {
      return this.order.status === 'accepted' ? 'Accepted' : 'Awaiting';
    },

    value() {
      let value = 0;
      if (this.order.status === 'pending') {
        this.order.pendingPositions.forEach((position) => {
          value += position.price * position.quantity;
        });
      } else if (this.order.status === 'accepted') {
        this.order.acceptedPositions.forEach((position) => {
          value += position.price * position.quantity;
        });
      }
      return value;
    },

    parsedOrderId() {
      return getOrderIdFromUUID(this.order.parentOrder.id);
    },

    extraInfo() {
      const info = [];
      if (this.order.shippingInformation.deliveryInfo.isInternational) {
        info.push('International');
      }

      return info.join(', ');
    },

    messages() {
      const { messages } = this.$router.currentRoute.query;

      if (messages) {
        return typeof messages === 'string' ? [messages] : messages;
      }

      return [];
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';
@import '@/styles/variables/colors.scss';

.order-base {
  $order-base-padding-desktop: 32px;
  $order-base-padding-mobile: 16px;

  &__header,
  &__content,
  &__messages {
    padding: $order-base-padding-desktop;
  }

  &__messages {
    padding-bottom: 0;
    padding-right: 100px;
  }

  &__header-status {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    flex-wrap: wrap;
    padding-right: 40px;
  }

  &__header-row {
    margin-bottom: 10px;
    width: 100%;
    display: grid;
    grid-template-columns: 100px auto;
    grid-column-gap: 20px;
  }

  &__tag {
    margin-left: 10px;
    margin-bottom: 5px;
  }

  &__price {
    font-size: 30px;
  }

  &__header-label {
    color: #4D4D4D;
  }

  &__info-group {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 20px;
    padding-bottom: 20px;
    border-bottom: 1px solid $color-border-1;
  }

  &__info-rodo {
    padding-bottom: 25px;
    border-bottom: 1px solid $color-border-1;
    color: #666;
    font-weight: 500;
  }

  &__section-label {
    color: #545454;
    font-weight: bold;
  }

  &__billing,
  &__shipping {
    margin-bottom: 20px;
  }

  &__actions {
    display: flex;
    justify-content: stretch;
  }

  &__action--right {
    margin-left: auto;
  }

  @include for-tablet-portrait-down {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1;
    display: flex;
    flex-direction: column;

    &__header,
    &__content,
    &__messages {
      padding: $order-base-padding-mobile;
    }

    &__price {
      padding: 0;
      // TODO: Refactor this mess
      min-height: 37px;
      line-height: 40px;
    }

    &__content-wrapper {
      flex-grow: 1;
      position: relative;
    }

    &__content {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      overflow: scroll;
    }
  }

  @include for-tablet-landscape-down {
    &__info-group {
      display: block;
    }
  }
}
</style>
