import api from '@/api';
import { addExtraOrderData } from '@/helpers';
import { isCancel } from 'axios';

import pagination from '@/store/pagination';
import order from '@/store/order';
import apiV2 from '@/apiV2/api';

export const types = {
  RESET: 'RESET',
  FETCH_REQUEST: 'LIST/FETCH_REQUEST',
  FETCH_SUCCESS: 'LIST/FETCH_SUCCESS',
  FETCH_FAILED: 'LIST/FETCH_FAILED',
  SET_DECLINE_REASONS: 'LIST/SET_DECLINE_REASONS',
  UPDATE_SEARCH_QUERY: 'SEARCH/UPDATE_QUERY',
  UPDATE_UUID_SEARCH: 'SEARCH/UPDATE_UUID',
  UPDATE_TRACK_AND_TRACE: 'LIST/UPDATE_TRACK_AND_TRACE',
  REMOVE_ITEM: 'LIST/REMOVE_ITEM',
  SET_PCS_DATA_MAP: 'PCS/SET_DATA_MAP',
};

const initialState = () => ({
  isLoading: false,
  isFailed: false,
  errorMsg: null,
  list: [],
  pcsDataMap: [],
  declineReasons: null,
  searchQuery: null,
  uuidSearch: null,
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  modules: {
    pagination,
    order,
  },
  getters: {
    ordersById(state) {
      return (orderId) => state?.list?.find((item) => item?.parentOrder?.id === orderId);
    },
  },

  mutations: {
    [types.RESET](state) {
      Object.assign(state, initialState());
    },

    [types.FETCH_REQUEST](state) {
      state.isLoading = true;
      state.errorMsg = null;
    },

    [types.FETCH_SUCCESS](state, data) {
      state.isLoading = false;
      state.isFailed = false;
      state.list = data;
    },

    [types.FETCH_FAILED](state, msg) {
      state.isLoading = false;
      state.isFailed = true;
      state.list = [];
      state.errorMsg = msg;
    },

    [types.UPDATE_SEARCH_QUERY](state, query) {
      state.searchQuery = query;
    },

    [types.UPDATE_UUID_SEARCH](state, query) {
      state.uuidSearch = query;
    },

    [types.UPDATE_TRACK_AND_TRACE](state, data) {
      state.list = state.list.map((item) => {
        if (item.id === data.id) {
          return {
            ...item,
            shippingInformation: {
              ...item.shippingInformation,
              deliveryInfo: {
                ...item.shippingInformation.deliveryInfo,
                trackingNumber: data.trackingNumber,
              },
            },
          };
        }

        return item;
      });
    },

    [types.REMOVE_ITEM](state, id) {
      state.list = state.list.filter((item) => item.id !== id);
    },

    [types.SET_DECLINE_REASONS](state, declineReasons) {
      state.declineReasons = declineReasons;
    },

    [types.SET_PCS_DATA_MAP](state, dataMap) {
      state.pcsDataMap = dataMap;
    },
  },
  actions: {
    search(context, { shopId, params }) {
      context.commit(types.FETCH_SUCCESS, []);
      context.commit(types.FETCH_REQUEST);
      context.commit(types.UPDATE_SEARCH_QUERY, params.query);

      if (shopId && params.query) {
        return apiV2(context).orders.getOrders(shopId, { ...params, status: ['accepted'] })
          .then((response) => {
            context.commit(types.FETCH_SUCCESS, response.data);
            context.dispatch('pagination/set', response.meta);

            return response;
          })
          .catch((err) => {
            if (!isCancel(err)) {
              context.commit(types.FETCH_FAILED);
              context.commit(types.FETCH_SUCCESS, []);
              context.dispatch('pagination/set', {
                offset: 0,
                totalItemCount: 0,
              });
            }
          });
      }

      return Promise.reject();
    },

    searchUUID(context, { shopId, params }) {
      context.commit(types.FETCH_SUCCESS, []);
      context.commit(types.FETCH_REQUEST);
      context.commit(types.UPDATE_UUID_SEARCH, params.query);

      if (shopId && params.query) {
        return apiV2(context).orders.getOrders(shopId, { ...params, status: params.status })
          .then((response) => {
            context.commit(types.FETCH_SUCCESS, response.data);
            context.dispatch('pagination/set', response.meta);

            return response;
          })
          .catch((err) => {
            if (!isCancel(err)) {
              context.commit(types.FETCH_FAILED);
              context.commit(types.FETCH_SUCCESS, []);
              context.dispatch('pagination/set', {
                offset: 0,
                totalItemCount: 0,
              });
            }
          });
      }

      return Promise.reject();
    },

    fetchForShop(context, { shopId, params }) {
      const isTransfer = params.status?.[0] === 'pending';

      context.commit(types.FETCH_SUCCESS, []);
      context.commit(types.FETCH_REQUEST);
      context.commit(types.UPDATE_SEARCH_QUERY, null);

      return apiV2(context).orders.getOrders(shopId, params)
        .then((response) => {
          const data = response.data ? addExtraOrderData(response.data, {
            type: isTransfer ? 'transfer' : 'order',
          }) : [];
          context.commit(types.FETCH_SUCCESS, data);
          context.dispatch('pagination/set', response?.meta);

          return {
            ...response,
            data,
          };
        })
        .catch((err) => {
          if (!isCancel(err)) {
            context.commit(types.FETCH_FAILED);
          }
        });
    },
    fetchItemMappingsForShop(context, shopId) {
      return api(context).pcs.getAliases(shopId)
        .then((aliasResponse) => {
          const mainShopId = aliasResponse.data[0].id;
          api(context).pcs.getDataMap(mainShopId)
            .then((response) => {
              context.commit(types.SET_PCS_DATA_MAP, response);
            })
            .catch((error) => {
              throw error;
            });
        })
        .catch((error) => {
          throw error;
        });
    },

    removeTransfer(context, id) {
      context.commit(types.REMOVE_ITEM, id);
    },

    updateTrackAndTrace(context, payload) {
      context.commit(types.UPDATE_TRACK_AND_TRACE, payload);
    },

    fetchDeclineReasons(context) {
      return apiV2(context).orders.getDeclineReasons()
        .then((response) => {
          context.commit(types.SET_DECLINE_REASONS, response.data);
        })
        .catch((error) => {
          throw error;
        });
    },
  },
};
