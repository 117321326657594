<template>
  <div class="delivery-info">
    <div class="delivery-info__header">Track &amp; Trace</div>
    <div class="delivery-info__content">
      <div class="delivery-info__provider">
        <div>
          <shipping-provider-label
            :provider-id="getProviderName"
            class="delivery-info__provider-label"
          />
        </div>
      </div>
      <div class="delivery-info__carrier">
        <div class="delivery-info__carrier-label">Product will be delivered with</div>
        <div class="delivery-info__carrier-info">{{ getProviderName }}</div>
        <div class="delivery-info__carrier-label">Tracking number</div>
        <div class="delivery-info__carrier-info">{{ trackingNumber }}</div>
      </div>
      <div class="delivery-info__print">
        <div class="delivery-info__print-popup delivery-info__print-popup-selected">
          <tooltip
            :disabled="printSelectedAvailable"
            content="In this sales channel this option is not available"
            placement="top-start"
            max-width="180"
          >
            <ui-button
              type="submit"
              class="delivery-info__print-selected"
              :disabled="!printSelectedAvailable"
              @click="handlePrint"
            >
              <span>Print selected</span>
              <ui-icon
                class="delivery-info__print-icon"
                size="4"
                type="triangle"
                color="#FFFFFF"
              />
            </ui-button>
            <orders-print
              v-if="printSelected"
              :shop-id="shopId"
              :order-id="orderId"
              :documents="printDocuments"
              @cancel="handlePrint"
            />
          </tooltip>
        </div>
        <div class="delivery-info__print-popup delivery-info__print-popup-all">
          <ui-button
            type="submit"
            @click="handlePrintAll"
          >
            Print all on one page
          </ui-button>
          <orders-print
            v-if="printAllSelected"
            :shop-id="shopId"
            :order-id="orderId"
            :documents="printDocuments"
            auto-print
            @cancel="handlePrintAll"
          />
        </div>
        <div class="delivery-info__print-eco">
          <ui-icon
            class="delivery-info__print-eco-icon"
            type="eco"
            size="13"
            color="#11BA35"
          />
          <span>Think green, print one page</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UiButton,
  UiIcon,
} from '@ui';
import {
  Tooltip,
} from 'iview';

import OrdersPrint from '@/modules/Orders/components/OrdersPrint.vue';
import ShippingProviderLabel from './ProvidersListLabel.vue';

export default {
  components: {
    ShippingProviderLabel,
    UiButton,
    UiIcon,
    OrdersPrint,
    Tooltip,
  },

  props: {
    shopId: {
      type: String,
      required: true,
    },

    orderId: {
      type: String,
      required: true,
    },

    providerId: {
      type: String,
      required: true,
    },

    trackingNumber: {
      type: String,
      required: true,
    },

    printDocuments: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      printSelected: false,
      printAllSelected: false,
    };
  },

  computed: {
    getProviderName() {
      return this.providerId.split('_')[0];
    },

    printSelectedAvailable() {
      return this.$store.getters['orders/order/isPrintSelectedAvailable'];
    },
  },

  methods: {
    handlePrint() {
      this.printAllSelected = false;
      this.printSelected = !this.printSelected;
    },
    handlePrintAll() {
      this.printSelected = false;
      this.printAllSelected = !this.printAllSelected;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables/colors.scss';
@import '@/styles/mixins/breakpoints.scss';

.delivery-info {
  $provider-width: 130px;

  &__header {
    border-top: 1px solid $color-border-1;
    padding: 20px 0;
    color: #666;
    font-size: 14px;
    font-weight: bold;
    text-transform: capitalize;
  }

  &__content {
    display: grid;
    grid-template-columns: $provider-width auto auto;
    grid-column-gap: 30px;
  }

  &__provider {
    &-label {
      border-radius: 3px;
      border: 1px solid $color-border-1;
      align-content: center;
      padding: 30px 20px;
      min-width: $provider-width;
    }
  }

  &__carrier {
    &-label {
      color: #666;
      margin-bottom: 5px;
      font-weight: 500;
    }

    &-info {
      color: #333;
      font-size: 20px;
      margin-bottom: 15px;
      font-weight: 500;
      word-break: break-all;
    }
  }

  &__print {
    align-self: center;
    justify-self: flex-end;
    text-align: right;

    &-popup {
      display: inline-block;
      position: relative;
      padding-bottom: 4px;

      &-selected {
        margin-right: 10px;

        .orders-print {
          left: 0;
        }
      }

      &-all {
        .orders-print {
          left: auto;
          right: 0;
        }
      }
    }
  }

  &__print-selected {
    position: relative;
    padding-right: 30px;
  }

  &__print-icon {
    position: absolute;
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
  }

  &__print-eco {
    text-align: right;
    color: #999999;
  }

  &__print-eco-icon {
    margin-right: 5px;
  }

  @include for-tablet-portrait-down {
    &__content {
      grid-template-columns: 130px auto;
      grid-template-rows: auto auto;
      grid-template-areas:
          "provider provider"
          "print print";
      margin-bottom: 16px;
    }

    &__print {
      grid-area: print;
      justify-self: center;
    }

    &__print-eco {
      text-align: center;
    }
  }
}
</style>
