<template>
  <div class="ui-checkbox-input__wrapper">
    <input
      :id="id"
      :name="name"
      :value="value"
      :checked="checked"
      :disabled="disabled"
      class="ui-radio-input"
      type="checkbox"
      @click="handleClick"
    />
    <label :for="id">
      <slot />
    </label>
  </div>
</template>

<script>
export default {
  name: 'UiCheckboxInput',
  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      type: [Boolean, String, Number],
      default: null,
    },
    checked: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    id() {
      return `ui-checkbox-${this.name}`;
    },
  },
  methods: {
    handleClick(event) {
      event.stopPropagation();
      this.$emit('click', event.target.value, event.target.checked);
    },
  },
};
</script>

<style lang="scss">
  .ui-checkbox-input {
    &__wrapper {
      position: relative;
      & > label, input {
        padding: 4px;
        cursor: pointer;
        user-select: none;
      }
    }
  }
</style>
