<template>
  <ui-header>
    <template #title>
      Orders
      <orders-badge :pending="pendingOrdersCounter" :incomplete="incompleteOrdersCounter" />
    </template>
    <ui-header-item class="header__miinto-mark" inactive>
      <ui-icon type="miinto-mark" size="40" />
    </ui-header-item>
    <ui-header-item label="Orders" active>
      <ui-icon type="basket" size="25" />
      <orders-badge :pending="pendingOrdersCounter" :incomplete="incompleteOrdersCounter" class="header__orders-counter" />
    </ui-header-item>
  </ui-header>
</template>

<script>
import {
  UiHeader,
  UiHeaderItem,
  UiIcon,
} from '@ui';

import { setActiveFavicon } from '@/helpers';

import OrdersBadge from '@/modules/Orders/components/OrdersBadge.vue';

export default {
  components: {
    UiIcon,
    OrdersBadge,
    UiHeader,
    UiHeaderItem,
  },
  computed: {
    pendingOrdersCounter() {
      return this.$store.getters['statistics/totalCount'].pending;
    },

    incompleteOrdersCounter() {
      return this.$store.getters['statistics/totalCount'].incomplete + this.$store.getters['statistics/totalCount'].rmas;
    },
  },
  watch: {
    pendingOrdersCounter(newValue) {
      setActiveFavicon(newValue > 0);
    },
  },
};
</script>

<style lang="scss">
  @import '@/styles/mixins/breakpoints.scss';

  .header {
    &__orders {
      &-counter {
        position: absolute;
        right: 15px;
        bottom: 13px;
        z-index: 1;
      }
    }

    @include for-tablet-portrait-down {
      &__miinto-mark {
        display: none;
      }
    }
  }
</style>
