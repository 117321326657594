<template>
  <div class="rma-details-header">
    <h2 class="rma-details-header__id">Return #{{ id }} || {{ parsedReturnId }}</h2>
    <return-status-badge class="rma-details-header__status" :status="status" />
    <span class="rma-details-header__date">Created at {{ createdDate | date() }}</span>
    <span v-if="parentOrderId" class="rma-details-header__order">
      Order number:
      <router-link :to="{ name: 'orders', params: { shopId }, query: { context: 'search', search: parentOrderId } }">
        <a
          slot-scope="{ navigate, href }"
          class="rma-details-header__order-link"
          :href="href"
          @click.native.prevent="navigate"
        >{{ parentOrderId }} || {{ parsedOrderId }}</a>
      </router-link>
    </span>
  </div>
</template>

<script>
import DateFilter from '@/logic/filters/date';
import ReturnStatusBadge from '@/modules/Returns/components/ReturnStatusBadge.vue';
import { getOrderIdFromUUID, getReturnIdfromUUID } from '@/apiV2/api/Context/Context.helpers';

export default {
  name: 'ReturnsRmaDetailsHeader',
  filters: {
    date: DateFilter,
  },

  components: {
    ReturnStatusBadge,
  },

  props: {
    shopId: {
      type: String,
      required: true,
    },

    id: {
      type: String,
      required: true,
    },

    createdDate: {
      type: Date,
      required: true,
    },

    orderId: {
      type: String,
      required: true,
    },

    parentOrderId: {
      type: String,
      required: false,
      default: '',
    },

    status: {
      type: String,
      required: true,
    },
  },
  computed: {
    parsedReturnId() {
      return getReturnIdfromUUID(this.id);
    },
    parsedOrderId() {
      return getOrderIdFromUUID(this.parentOrderId);
    },
  },
};
</script>

<style lang="scss">
.rma-details-header {
  margin-bottom: 32px;

  &__id {
    display: inline-block;
    font-weight: normal;
    font-size: 30px;
    line-height: 1em;
    margin-bottom: 12px;
  }

  &__order,
  &__date {
    display: block;
    font-size: 12px;
    line-height: 1em;
    margin-bottom: 1em;
  }

  &__order-link {
    color: inherit;
    text-decoration: underline;
  }

  &__status {
    display: inline-block;
    vertical-align: middle;;
    margin: -12px 0 0 12px;
  }
}
</style>
