/* eslint-disable class-methods-use-this */
import dayjs from 'dayjs';
import { sessionLifeTime } from '@/const';
import Channel from './Channel';

class AuthService {
  constructor(authApi) {
    this.authApi = authApi;
    this.channel = null;
  }

  createChannel(identifier, secret) {
    this.channel = null;

    if (typeof identifier !== 'string') {
      throw new TypeError('Identifier is not a string');
    }

    if (typeof secret !== 'string') {
      throw new TypeError('Secret is not a string');
    }

    return new Promise((resolve, reject) => {
      this.authApi
        .createChannel(identifier, secret)
        .then((data) => {
          const channel = new Channel(data.id, data.token, {
            accessor: {
              id: data.data.accessorId,
              email: identifier,
            },
            privileges: data.privileges,
          });

          this.channel = channel;
          resolve(channel);
        })
        .catch(() => {
          reject(new Error(''));
        });
    });
  }

  getChannel() {
    return this.channel;
  }

  destroyChannel() {
    this.channel = null;
    this.save();

    return Promise.resolve();
  }

  isAuthenticated() {
    return !!this.getChannel() && !this.hasSessionExpired();
  }

  hasSessionExpired() {
    const sessionExpirationDate = window.localStorage.getItem('sessionAuth');
    return dayjs().isAfter(sessionExpirationDate);
  }

  hasSession() {
    return !!window.localStorage.getItem('sessionAuth');
  }

  deleteSession() {
    window.localStorage.removeItem('sessionAuth');
  }

  save() {
    const channel = this.getChannel();

    if (!channel) {
      window.localStorage.removeItem('auth');
    } else {
      window.localStorage.setItem(
        'auth',
        channel
          ? JSON.stringify({
            id: channel.id,
            token: channel.token,
            data: channel.data,
          })
          : null,
      );

      window.localStorage.setItem('sessionAuth', dayjs(new Date()).add(sessionLifeTime, 'minute'));
    }
  }

  load() {
    const data = JSON.parse(window.localStorage.getItem('auth'));

    if (data && data.id && data.token && !this.hasSessionExpired()) {
      this.channel = new Channel(data.id, data.token, data.data);
    }
  }
}

export default AuthService;
