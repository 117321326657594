<template>
  <order-base :order="order">
    <template #positions>
      <order-incomplete-process
        v-if="!hasTrackingNumber"
        :has-msa="hasMsa"
        :currency="order.currency"
        :order-id="order.id"
        :position-ids="order.positionIds"
        :shop-id="shopId"
        :is-shippment-was-not-created="isShippmentWasNotCreated"
        @onBookFinished="$emit('onBookFinished')"
      />
      <order-positions-list
        :accepted="order.acceptedPositions"
        :declined="order.declinedPositions"
        :currency="order.currency"
        :show-declined="true"
      />
    </template>
  </order-base>
</template>

<script>
import OrderBase from './OrderBase.vue';
import OrderPositionsList from '../Position/OrderPositionsList.vue';
import OrderIncompleteProcess from './OrderIncomplete/OrderIncompleteProcess.vue';

export default {
  components: {
    OrderBase,
    OrderPositionsList,
    OrderIncompleteProcess,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    shopId: {
      type: String,
      required: true,
    },
    isShippmentWasNotCreated: {
      type: Boolean,
    },
  },

  computed: {
    hasMsa() {
      return this.order.shippingInformation.deliveryInfo.canUseShippingService;
    },

    hasTrackingNumber() {
      return !!this.order.shippingInformation.deliveryInfo.trackingNumber;
    },
  },

  mounted() {
    // Make sure to run after loading order and shops from API

    if (this.hasMsa) {
      this.$store.dispatch('orders/order/withMSA/init', {
        pickupPoints: this.$store.getters['shops/byId'](this.shopId).shippingInformation.pickupPoints,
      });
    }
  },
};
</script>

<style lang="scss">
.order-incomplete {
  &__shipping-notice {
    margin-top: 10px;
    margin-bottom: 5px;
    color: red;
    font-weight: bold;
  }
}
</style>
