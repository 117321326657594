<template>
  <RadioGroup
    class="providers-list"
    type="button"
    :value="selectedId"
    @on-change="onChange"
  >
    <Radio
      v-for="provider in providers"
      :key="provider.id"
      :label="provider.id"
      class="providers-list__label"
    >
      <shipping-provider-label
        :provider-id="provider.id"
        :provider-name="provider.name"
        class="providers-list__label"
      />
    </Radio>
  </RadioGroup>
</template>

<script>
import { RadioGroup, Radio } from 'iview';
import ShippingProviderLabel from './ProvidersListLabel.vue';

export default {
  components: {
    RadioGroup,
    Radio,
    ShippingProviderLabel,
  },

  props: {
    providers: {
      type: Array,
      required: true,
    },

    selectedId: {
      type: String,
      required: false,
      default: null,
    },
  },

  methods: {
    onChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>

<style lang="scss">
  .providers-list {
    display: flex;
    flex-wrap: wrap;

    &__label {
      &.ivu-radio-default {
        height: auto;
        line-height: normal;
        padding: 11px;
        margin-right: 10px;
        margin-bottom: 10px;
        width: 100px;
        font-size: 14px;
        border-radius: 2px;

        &:first-child,
        &:last-child,
        &:first-child:last-child {
          border-radius: 2px;
        }
      }

      &.ivu-radio-wrapper-checked,
      &.ivu-radio-wrapper-checked.ivu-radio-focus {
        border-width: 2px;
        box-shadow: -2px 0 0 0 #2d8cf0;
        padding: 10px;
      }

      .ivu-radio {
        display: initial;
      }

      .ivu-radio-inner {
        display: inline;
        border: none;
      }
    }
  }
</style>
