<template>
  <ui-nav-item
    ref="item"
    class="shops-list-item"
    :active="isActive"
    :href="href"
    @click.prevent="handleClick"
  >
    <flag
      class="shops-list-item__flag"
      :squared="true"
      :iso="countryData.iso"
    />

    <slot />

    <orders-badge
      class="shops-list-item__counter"
      :pending="pendingOrdersCounter"
      :incomplete="incompleteOrdersCounter"
    />

    <slot name="action" />
  </ui-nav-item>
</template>

<script>
import {
  UiNavItem,
} from '@ui';
import { countriesData } from '@/const';
import OrdersBadge from '@/modules/Orders/components/OrdersBadge.vue';

export default {
  name: 'ShopsShopsListItemView',

  components: {
    UiNavItem,
    OrdersBadge,
  },

  props: {
    shopId: {
      type: String,
      default: null,
    },

    country: {
      type: String,
      default: null,
      required: true,
    },

    disableLink: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    pendingOrdersCounter() {
      return this.$store.getters['statistics/countByShop'](this.shopId).pending;
    },

    incompleteOrdersCounter() {
      return this.$store.getters['statistics/countByShop'](this.shopId).incomplete + this.$store.getters['statistics/countByShop'](this.shopId).rmas;
    },

    isActive() {
      return this.shopId === this.$route.params.shopId;
    },

    href() {
      if (this.disableLink) return '';
      return this.$router.resolve({ name: 'orders', params: { shopId: this.shopId } }).href;
    },

    countryData() {
      return countriesData[this.country];
    },
  },

  methods: {
    handleClick(e) {
      this.$emit('click', e);

      if (this.disableLink) return;
      this.$router.push(this.href).catch(() => {});
    },
  },

};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.shops-list-item {
  background-color: #fff;
  color: #000;
  padding: 0 16px;
  margin: 12px 10px;
  font-size: 14px;
  white-space: nowrap;

  &.is-active {
    background-color: #006EF5;
    color: #fff;

    &:after {
      content: none;
    }
  }

  &__flag {
    margin-right: 10px;
    border-radius: 7px;
  }

  &__counter {
    margin-left: 5px;
  }

  @include for-tablet-portrait-down {
    margin: 0 16px 0 0;

    &:last-child {
      margin: 0;
    }
  }
}
</style>
