<template>
  <div class="ui-nav-bar-item" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiNavBarItem',
  props: {
    grow: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'ui-nav-bar-item--grow': this.grow,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.ui-nav-bar-item {
  display: flex;
  vertical-align: middle;
  align-items: center;
  flex-grow: 0;
  flex-shrink: 1;
  flex-basis: auto;
  padding-left: 8px;
  padding-right: 8px;

  &--grow {
    flex-grow: 1;
  }

  @include for-tablet-portrait-down {
    padding: 0;
    margin: 0 8px 0 0;
  }
}
</style>
