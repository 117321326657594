<template>
  <div class="ui-tabs" :class="classes">
    <slot />
    <div class="ui-tabs__right">
      <slot name="right" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiTabs',
  computed: {
    classes() {
      return {
        'ui-tabs--with-right': this.$slots.right,
      };
    },
  },
};
</script>

<style lang="scss">
.ui-tabs {
  position: relative;
  overflow-y: hidden;
  overflow-x: scroll;
  display: flex;
  align-items: center;

  padding: 10px 25px 0 25px;

  &--with-right {
    justify-content: space-between;
  }

  &__right {
    display: flex;
    flex-grow: 1;
    justify-content: flex-end;
    align-items: center;
  }

  &:before {
    content: '';
    position: absolute;
    bottom: -9px;
    left: 0;
    right: 0;
    height: 10px;
    background-color: #E5E6E8;
  }
}
</style>
