<template>
  <div class="ui-nav" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiNav',
  props: {
    size: {
      type: String,
      default: null,
      validate: (size) => (['default', 'large'].indexOf(size) !== -1),
    },
  },

  computed: {
    classes() {
      return {
        'ui-nav--size-large': this.size === 'large',
      };
    },
  },
};
</script>

<style lang="scss">
.ui-nav {
  font-size: 14px;
  line-height: 55px;
  font-weight: 500;

  &--size-large {
    font-size: 16px;
    line-height: 72px;
  }
}
</style>
