import auth from '@/auth';

import unauthorizedResponseInterceptor from '@/logic/interceptors/unauthorizedResponseInterceptor';
import unauthorizedRequestInterceptor from '@/logic/interceptors/unautorizedRequestInterceptor';
import authRequestInterceptor from './interceptors/authRequestInterceptor';
import AsyncOperationsApi from './api/AsyncOperations/AsyncOperations';
import ContextApi from './api/Context/Context';
import ReturnsApi from './api/Returns/Returns';
import ShippingApi from './api/Shipping/Shipping';
import StatisticsApi from './api/Statistics/Statistics';
import OrdersApi from './api/Orders/Orders';

let ordersApi;
let asyncOperationsApi;
let contextApi;
let returnsApi;
let shippingApi;
let statisticsApi;

export default (storeContext) => {
  if (ordersApi && asyncOperationsApi && contextApi && returnsApi && shippingApi && statisticsApi) {
    return {
      orders: ordersApi,
      asyncOperations: asyncOperationsApi,
      shopContext: contextApi,
      returns: returnsApi,
      shipping: shippingApi,
      statistics: statisticsApi,
    };
  }

  const authenticationRequestInterceptor = authRequestInterceptor(auth);
  const unauthorizedReqInterceptor = unauthorizedRequestInterceptor(auth, storeContext);
  const unauthorizedResInterceptor = unauthorizedResponseInterceptor(storeContext);
  ordersApi = new OrdersApi(
    {
      baseURL: window.APP_CONFIG.apiV2,
    },
    {
      request: [authenticationRequestInterceptor, unauthorizedReqInterceptor],
      response: [unauthorizedResInterceptor],
    },
  );

  asyncOperationsApi = new AsyncOperationsApi(
    {
      baseURL: window.APP_CONFIG.apiV2,
    },
    {
      request: [authenticationRequestInterceptor, unauthorizedReqInterceptor],
      response: [unauthorizedResInterceptor],
    },
  );

  contextApi = new ContextApi(
    {
      baseURL: window.APP_CONFIG.apiV2,
    },
    {
      request: [authenticationRequestInterceptor, unauthorizedReqInterceptor],
      response: [unauthorizedResInterceptor],
    },
  );

  returnsApi = new ReturnsApi(
    {
      baseURL: window.APP_CONFIG.apiV2,
    },
    {
      request: [authenticationRequestInterceptor, unauthorizedReqInterceptor],
      response: [unauthorizedResInterceptor],
    },
  );

  shippingApi = new ShippingApi(
    {
      baseURL: window.APP_CONFIG.apiV2,
    },
    {
      request: [authenticationRequestInterceptor, unauthorizedReqInterceptor],
      response: [unauthorizedResInterceptor],
    },
  );

  statisticsApi = new StatisticsApi(
    {
      baseURL: window.APP_CONFIG.apiV2,
    },
    {
      request: [authenticationRequestInterceptor, unauthorizedReqInterceptor],
      response: [unauthorizedResInterceptor],
    },
  );

  return {
    orders: ordersApi,
    asyncOperations: asyncOperationsApi,
    shopContext: contextApi,
    returns: returnsApi,
    shipping: shippingApi,
    statistics: statisticsApi,
  };
};
