<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div class="page-login">
    <iv-modal
      v-model="shouldShowModal"
      footer-hide
      :closable="false"
      :mask-closable="false"
      class-name="page-login__ios-modal"
      class="shipping-awaiting"
    >
      <div class="page-login__ios-modal-img">
        <img src="/illustration-sleepy-basket.svg" />
      </div>
      <div class="page-login__ios-modal-message">
        <span class="page-login__ios-modal-main-text">Unfortunately, your device is not yet supported.</span>
        <br>
        <span class="page-login__ios-modal-additional-text">Support for your device is coming soon.</span>
      </div>
    </iv-modal>
    <div class="page-login__panel">
      <div class="page-login__wrapper">
        <div class="page-login__content">
          <svg class="page-login__logo">
            <use xlink:href="@/assets/logo.svg#logo" />
          </svg>
        </div>
        <div class="page-login__notice">
          <span v-if="hasSession" class="page-login__notice-text">Your session has expired. Please log back in.</span>
        </div>
        <div class="page-login__form">
          <LoginForm
            ref="loginForm"
            @submit="handleSubmit"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import {
  Modal as IvModal,
} from 'iview';
import LoginForm from './LoginForm.vue';

export default {
  name: 'PageLogin',

  components: {
    LoginForm,
    IvModal,
  },

  props: {
    nextUrl: {
      type: String,
      default: '/shops',
    },
  },
  data() {
    return {
      shouldShowModal: true,
    };
  },
  computed: {
    ...mapState('auth', ['hasSession']),
  },

  created() {
    // tepmporary solution for PATCH issue on iOS version < 13
    this.shouldShowModal = this.checkIosVersion();
    this.$store.dispatch('auth/setSession');
  },

  mounted() {
    if (this.$store.state.auth.isLoggedIn) {
      this.$router.push({ path: this.nextUrl }).catch(() => {});
    }
  },

  methods: {
    handleSubmit(data) {
      this.$store.dispatch('auth/login', data).then(() => {
        this.$root.$emit('auth/loggedIn');
        this.$router.push({ path: this.nextUrl }).catch(() => {});
      }).catch(() => {
        this.$refs.loginForm.setError();
      });
    },

    checkIosVersion() {
      // eslint-disable-next-line consistent-return
      function iOSversion() {
        if (/iP(hone|od|ad)/.test(navigator.platform)) {
          const v = (navigator.appVersion).match(/OS (\d+)_(\d+)_?(\d+)?/);
          return [parseInt(v[1], 10), parseInt(v[2], 10), parseInt(v[3] || 0, 10)];
        }
      }

      const iosVersion = iOSversion();

      return iosVersion && iosVersion[0] < 13;
    },
  },
};
</script>

<style lang="scss">
  @import '@/styles/mixins/breakpoints.scss';

  .page-login {
    min-height: 100%;
    width: 100%;
    background-color: #F2F3F5;
    display: flex;
    justify-content: center;
    align-items: center;

    @include for-phone-only {
      height: 100%;
    }

    &__panel {
      max-width: 1100px;
      width: 100%;
      background-image: url('~@/assets/login-bg.jpg');
      background-repeat: no-repeat;
      background-position: 50% 50%;
      background-size: cover;
      display: flex;
      flex-direction: row;
      box-shadow: 0 0 10px 0 #E5E6E8;

      @include for-tablet-portrait-down {
        width: unset;
        background: none;
      }

      @include for-phone-only  {
        width: 100%;
        height: 100%;
      }
    }

    &__wrapper {
      background-color: #fff;
      padding: 80px 70px 100px 80px;
      flex-direction: column;

      @include for-phone-only  {
        padding: 32px;
        width: 100%;
      }
    }

    &__content {
      margin: 0 auto 35px;
      text-align: center;

      @include for-phone-only  {
        margin: 0 auto 40px;
      }
    }

    &__form {
      width: 100%;
    }

    &__logo {
      display: block;
      width: 100%;
      max-height: 65px;
      fill: #000;
    }

    &__notice {
      text-align: center;
      margin-bottom: 30px;
    }

    &__ios-modal-message {
      font-family: 'Sofia';
      font-size: 14px;
      text-align: center;
      color: #333;
      font-weight: normal;
    }

    &__ios-modal-main-text {
      font-weight: bold;
      color: #0078FB;
    }

    &__ios-modal-img {
      text-align: center;
      margin: 16px 0;

      img {
        width: 120px;
      }
    }

    &__ios-modal {
      display: flex;
      align-items: center;
      justify-content: center;

      .ivu-modal {
        top: 0;
      }
      .ivu-modal-content {
        border-radius: 0;
      }
    }
  }
</style>
