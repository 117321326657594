<template>
  <div class="rma-details-addresses">
    <div class="rma-details-addresses__section">
      <h3 class="rma-details-addresses__title">Return address</h3>
      <p class="rma-details-addresses__address">
        {{ returnTo.name }}<br>
        {{ returnTo.address.postcode }} {{ returnTo.address.city }}<br>
        {{ returnTo.address.street }}<br>
        {{ returnTo.address.country }}
      </p>
    </div>
    <div class="rma-details-addresses__section">
      <h3 class="rma-details-addresses__title">Client address</h3>
      <p class="rma-details-addresses__address">
        {{ customer.name }}
        {{ customer.address.street }} <br>
        {{ customer.address.postcode }} {{ customer.address.city }}<br>
        {{ customer.address.country }}<br>
        {{ customer.email }}<br>
        {{ customer.phone }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ReturnsRmaDetailsAddresses',
  props: {
    returnTo: {
      type: Object,
      default: () => ({
        name: null,
        address: {
          city: null,
          country: null,
          countryCode: null,
          postcode: null,
          street: null,
        },
      }),
    },

    customer: {
      type: Object,
      default: () => ({
        email: null,
        name: null,
        phone: null,
        address: {
          city: null,
          country: null,
          countryCode: null,
          postcode: null,
          street: null,
        },
      }),
    },
  },
};
</script>

<style lang="scss">
.rma-details-addresses {
  display: flex;
  margin-bottom: 32px;

  &__section {
    width: 50%;
  }

  &__title {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 26px;
    font-weight: 600;
  }

  &__address {
    font-size: 14px;
    line-height: 2em;
  }
}
</style>
