<!-- eslint-disable vue/no-lone-template -->
<template>
  <ul class="order-positions-list">
    <li
      v-for="position in positions"
      :key="position.id"
      class="order-positions-list__item"
    >
      <template>
        <slot name="position" :position="position" />
      </template>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    positions: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss">
.order-positions-list {
  list-style: none;
  padding: 0;
  margin: 0;

  &__item {
    border-bottom: 1px solid #dcdee2;
    padding: 10px 0;

    &:last-child {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}
</style>
