/* eslint-disable */
import { remapValues } from '@/apiV2/remapValues';
import invert from 'lodash.invert';
import iso3311Country from 'iso-3166-1-alpha-2';

const ORDER_POSITION_LEGACY_TO_NEW_MAPPING = {
  'id': 'id',
  'item.brand': 'product.brand',
  'item.color': 'product.color',
  'item.gtin': 'item.gtin',
  'item.imageUrls.*': 'product.imageUrls.*',
  'item.miintoItemId': 'item.id',
  'item.name': 'product.title',
  'item.productId': 'product.id',
  'item.size': 'item.size.name',
  'item.styleId': 'product.styleId',
  'price': 'price.value',
  'status': 'status',
};

const ORDER_POSITION_NEW_TO_LEGACY_MAPPING = invert(ORDER_POSITION_LEGACY_TO_NEW_MAPPING);

const ORDER_FROM_LEGACY_TO_NEW_MAPPING = {
  'billingInformation.address.city': 'billingInformation.address.city',
  'billingInformation.address.countryCode': 'billingInformation.address.countryCode',
  'billingInformation.address.postcode': 'billingInformation.address.postcode',
  'billingInformation.address.street': 'billingInformation.address.street',
  'billingInformation.email': 'billingInformation.email',
  'billingInformation.name': 'billingInformation.name',
  'billingInformation.phone': 'billingInformation.phone',
  'parentOrder.id': 'orderId',
  'parentOrder.createdAt': 'createdAt',
  'currency': 'price.currency',
  'createdAt': 'createdAt',
  'shippingInformation.deliveryAddress.address.city': 'shippingInformation.address.city',
  'shippingInformation.deliveryAddress.address.countryCode': 'shippingInformation.address.countryCode',
  'shippingInformation.deliveryAddress.address.postcode': 'shippingInformation.address.postcode',
  'shippingInformation.deliveryAddress.address.street': 'shippingInformation.address.street',
  'shippingInformation.deliveryAddress.company': 'shippingInformation.address.company',
  'shippingInformation.deliveryAddress.email': 'shippingInformation.email',
  'shippingInformation.deliveryAddress.name': 'shippingInformation.name',
  'shippingInformation.deliveryAddress.phone': 'shippingInformation.phone',
  'shippingInformation.deliveryAddress.type': 'deliveryInformation.parameters.type',
  'shippingInformation.deliveryInfo.canUseShippingService': 'deliveryInformation.isShippingServiceAvailable',
  'shippingInformation.deliveryInfo.isInternational': 'deliveryInformation.isInternational',
  'shippingInformation.deliveryInfo.isPremium': 'deliveryInformation.isPremium',
  'shippingInformation.deliveryInfo.price': 'deliveryInformation.price.value',
  'shippingInformation.deliveryInfo.trackingNumber': 'deliveryInformation.trackingNumber',
  'shippingInformation.deliveryOptions.initial.providerId': 'deliveryInformation.providerId',
  'shippingInformation.status': 'deliveryInformation.status'
};

export const ORDER_FROM_NEW_TO_LEGACY_MAPPING = invert(ORDER_FROM_LEGACY_TO_NEW_MAPPING);

export const parseOrdersToLegacy = (orders) => {
  if (!Array.isArray(orders) && !orders.length) return [];

  const legacyOrdersObj = orders.filter(order => order.status !== 'returned').reduce((acc, curr) => {
    let acceptedPositions = [];
    let pendingPositions = [];
    let declinedPositions = [];

    const remappedPosition = remapValues(ORDER_POSITION_NEW_TO_LEGACY_MAPPING, curr);
    const remappedPositionValues = {...remappedPosition.remappedValues, ids: [remappedPosition.remappedValues.id]}

    if (!Object.keys(acc).includes(curr.orderId)) {
      const order = remapValues(ORDER_FROM_NEW_TO_LEGACY_MAPPING, curr).remappedValues;
      const orderWithCountry = {
        ...order,
        shippingInformation: {...order.shippingInformation, deliveryAddress: {...order.shippingInformation.deliveryAddress,  address: {...order.shippingInformation.deliveryAddress.address, country: iso3311Country.getCountry(order.shippingInformation.deliveryAddress.address.countryCode)} }},
        billingInformation: {...order.billingInformation,  address: {...order.billingInformation.address, country: iso3311Country.getCountry(order.billingInformation.address.countryCode)} },
      }
      if (remappedPositionValues.status === 'accepted') acceptedPositions = [{...remappedPositionValues, quantity: 1}];
      if (remappedPositionValues.status === 'pending') pendingPositions = [{...remappedPositionValues, quantity: 1}];
      if (remappedPositionValues.status === 'declined') declinedPositions = [{...remappedPositionValues, quantity: 1}];
      return {
        ...acc,
        [curr.orderId]: {
          ...orderWithCountry,
          id: orderWithCountry.parentOrder.id,
          positionIds: [remappedPositionValues.id],
          acceptedPositions,
          pendingPositions,
          declinedPositions,
          status: curr.status
        },
      };
    }

    if (remappedPositionValues.status === 'accepted') {
      const currentRelatedPositions = [...acc[curr.orderId].acceptedPositions?.filter((ap) => ap.item.miintoItemId === remappedPositionValues.item.miintoItemId)]

      const relatedPositions = [...currentRelatedPositions, remappedPositionValues];
      const relatedPositionsWithoutReturned = relatedPositions.filter(ap => ap.legacyStatus !== 'returned')
      const relatedPositionsWithoutReturnedLength = relatedPositionsWithoutReturned.reduce((acc, curr) => acc + curr.ids.length, 0)
      const relatedPositionsIds = remappedPositionValues.legacyStatus !== 'returned'? [...currentRelatedPositions.filter(ap => ap.legacyStatus !== 'returned').reduce((acc, curr) => [...acc, ...curr.ids], []), remappedPositionValues.id] : currentRelatedPositions.filter(ap => ap.legacyStatus !== 'returned').reduce((acc, curr) => [...acc, ...curr.ids], []);
      const agregatedPositionId = [...relatedPositionsIds].join('|');

      acceptedPositions = [...acc[curr.orderId].acceptedPositions?.filter((ap) => ap.item.miintoItemId !== remappedPositionValues.item.miintoItemId), {
        ...relatedPositions[0], id: agregatedPositionId, ids: relatedPositionsIds, quantity: relatedPositionsWithoutReturnedLength,
      }];
    }

    if (remappedPositionValues.status === 'pending') {
      const currentRelatedPositions = [...acc[curr.orderId].pendingPositions?.filter((ap) => ap.item.miintoItemId === remappedPositionValues.item.miintoItemId)]
      const relatedPositions = [...currentRelatedPositions, remappedPositionValues];
      const relatedPositionsLength = relatedPositions.reduce((acc, curr) => acc + curr.ids.length, 0)
      const relatedPositionsIds = [...currentRelatedPositions.reduce((acc, curr) => [...acc, ...curr.ids], []), remappedPositionValues.id];
      const agregatedPositionId = [...relatedPositionsIds].join('|');

      pendingPositions = [...acc[curr.orderId].pendingPositions?.filter((ap) => ap.item.miintoItemId !== remappedPositionValues.item.miintoItemId), {
        ...relatedPositions[0], id: agregatedPositionId, ids: relatedPositionsIds, quantity: relatedPositionsLength,
      }];
    }
    if (remappedPositionValues.status === 'declined') {
      const currentRelatedPositions = [...acc[curr.orderId].declinedPositions?.filter((ap) => ap.item.miintoItemId === remappedPositionValues.item.miintoItemId)]

      const relatedPositions = [...currentRelatedPositions, remappedPositionValues];
      const relatedPositionsLength = relatedPositions.reduce((acc, curr) => acc + curr.ids.length, 0)
      const relatedPositionsIds = [...currentRelatedPositions.reduce((acc, curr) => [...acc, ...curr.ids], []), remappedPositionValues.id];
      const agregatedPositionId = [...relatedPositionsIds].join('|');

      declinedPositions = [...acc[curr.orderId].declinedPositions?.filter((ap) => ap.item.miintoItemId !== remappedPositionValues.item.miintoItemId), {
        ...relatedPositions[0], id: agregatedPositionId, ids: relatedPositionsIds, quantity: relatedPositionsLength
      }];
    }

    return {
      ...acc,
      [curr.orderId]: {
        ...acc[curr.orderId],
        positionIds: [...acc[curr.orderId].positionIds, remappedPositionValues.id],
        acceptedPositions,
        pendingPositions,
        declinedPositions,
        status: curr.status
      },
    };
  }, {});

  return Object.keys(legacyOrdersObj).map((key) => legacyOrdersObj[key]);
};
