<template>
  <div class="ui-layout-viewport">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiViewport',
};
</script>

<style lang="scss">
.ui-layout-viewport {
  margin: 0 auto;
  width: 100%;
  min-width: 1200px;
}
</style>
