/* eslint-disable */

export const parseContextToLegacy = (shopContext) => {
  if (!shopContext) return {};

  const legacyShopContext = {
    id: shopContext.id,
    name: shopContext.name,
    shippingInformation: {
      hasMiintoAgreement: shopContext.shippingDetails.hasShippingAgreement,
      pickupPoints: shopContext.shippingDetails.pickupPoints,
    },
    availabilityOptions: {
      refundProduct: shopContext.systemSettings.refund,
      rma: shopContext.systemSettings.return,
      shipping: {modification: shopContext.systemSettings.shipping.modifications, documentsTypes: shopContext.systemSettings.shipping.documentTypes}
    }
  };
  return legacyShopContext;
};

export const CHANNEL_COUNTRY_CODE = {
  '045': 'cb473b1f-540c-406b-b2d1-a5a800dee649',
  '046': 'b2c87ec3-2d50-42b7-87b6-a5a800df7e3a',
  '031': 'f0fac18d-17b4-45d7-b7e2-a5a800dfc3f4',
  '047': '535765db-5437-4eac-b0b5-a5a800df2290',
  '032': '9a4dca95-6485-4745-a060-a7a900d32ab0',
  '041': 'bfa5a3d0-9174-437c-aaf2-a8c700acdb3d',
  '039': '54c850bb-6137-4cca-825e-165a7202c255',
  '033': '44b206a0-8f7b-4e4c-acde-9d3f819105af',
  '034': '7d1ac383-e0e3-4f4f-8280-d16d4b650ebe',
  '049': '048073a5-2b9f-492f-ad23-bf38150211c9',
  '358': 'f9425cd6-a73d-4437-9c9c-56a8fa9d9f32',
  '044': '477097bb-8249-4fe5-85f1-ca44dbbf0e2f',
  '048': '8f1b4338-be00-4026-9dec-a7c800ffa5cc',
  '086': '2fc7fe4f-edcd-fe27-58fe-c76cc9305aaa',
  '001': 'aba06f13-606e-4a10-a207-07ba9ed29897'
}

export const getOrderIdFromUUID = (uuid) => uuid ? parseInt(uuid?.split('-')?.[4], 16) : null
export const getReturnIdfromUUID = (uuid) => uuid ? parseInt(uuid?.split('-')?.[0], 16) : null
