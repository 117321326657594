<template>
  <div>
    <div v-if="state.matches('selectProviders.withMSA')">
      <div v-if="!packageTypes" class="shipping-form__content">
        <ui-spinner class="shipping-form__spinner" />
      </div>
      <shipping-form-with-msa
        v-if="packageTypes && packageTypes.length > 0 && !isShippingInProgress"
        :providers="providers"
        :selected-provider="selectedProvider"
        :pickup-points="pickupPoints"
        :selected-pickup-point="selectedPickupPoint"
        :package-types="packageTypes"
        :selected-package-type="selectedPackageType"
        :is-loading="state.matches('selectProviders.withMSA.fetching')"
        @select-provider="onProviderSelect"
        @select-address="onPickupPointSelect"
        @select-package="onPackageSelect"
        @save="onShippingSave"
      >
        <template v-if="state.matches('selectProviders.withMSA.error')" #errors>
          <alert v-if="state.matches('selectProviders.withMSA.error.noSelectedPickupPoint')" type="error">Select pickup address!</alert>
        </template>
      </shipping-form-with-msa>

      <ui-error
        v-if="packageTypes && packageTypes.length === 0"
        :subtitle="errorMsg"
        @try-again="onTryAgain"
      />
    </div>
    <shipping-form-without-msa
      v-if="state.matches('selectProviders.withoutMSA')"
      :providers="providers"
      :selected-provider="selectedProvider"
      :tracking-number="trackingNumber"
      @select-provider="onProviderSelect"
      @tracking-number-change="onTrackingNumberChange"
      @save="onShippingSave"
    />
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

import {
  UiError,
  UiSpinner,
} from '@ui';
import { Alert } from 'iview';
import ShippingFormWithMsa from './ShippingFormWithMsa.vue';
import ShippingFormWithoutMsa from './ShippingFormWithoutMsa.vue';

export default {
  components: {
    Alert,
    UiError,
    UiSpinner,
    ShippingFormWithMsa,
    ShippingFormWithoutMsa,
  },
  props: {
    state: {
      type: Object,
      required: true,
    },

    stateMachine: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState('orders/order/providers', {
      providers: 'providers',
      selectedProvider: 'selectedProvider',
      errorMsg: 'errorMsg',
    }),
    ...mapState('orders/order/withMSA', {
      pickupPoints: 'pickupPoints',
      selectedPickupPoint: 'selectedPickupPoint',
      selectedPackageType: 'packageTypeId',
    }),
    ...mapState('orders/order/withoutMSA', {
      trackingNumber: 'trackingNumber',
    }),
    ...mapGetters('orders/order/providers', {
      packageTypes: 'packageTypes',
    }),
    ...mapGetters('orders/order/withMSA', {
      noSelectedPickupPoint: 'noSelectedPickupPoint',
    }),
    ...mapGetters('orders/order', {
      isShippingInProgress: 'isShippingInProgress',
    }),
  },

  methods: {
    onPickupPointSelect(address) {
      this.stateMachine.send('SELECT_PICKUP_ADDRESS', { address });
    },

    onProviderSelect(providerId) {
      this.stateMachine.send('SELECT_PROVIDER', { providerId });
    },

    onPackageSelect(packageType) {
      this.stateMachine.send('SELECT_PACKAGE', { packageType });
    },

    onShippingSave() {
      this.$emit('save');
    },

    onTrackingNumberChange(trackingNumber) {
      this.stateMachine.send('SET_TRACKING_NUMBER', { trackingNumber });
    },

    onTryAgain() {
      this.stateMachine.send('TRY_AGAIN');
    },
  },
};
</script>

<style lang="scss">
  .shipping-form {
    &__section-cancel {
      margin-left: 10px;
    }

    &__content {
      text-align: center;
      margin: 50px auto;
      font-size: 17px;
    }

    &__spinner {
      margin: 20px auto;
    }
  }
</style>
