<template>
  <ui-nav-bar class="layout-menu">
    <ui-nav-bar-item class="layout-menu__header">
      Orders
    </ui-nav-bar-item>
    <ui-nav-bar-item grow>
      <ui-nav>
        <ui-nav-item :active="true" @click="onYourOrdersClick">Your orders</ui-nav-item>
      </ui-nav>
    </ui-nav-bar-item>
  </ui-nav-bar>
</template>

<script>
import {
  UiNav,
  UiNavItem,
  UiNavBar,
  UiNavBarItem,
} from '@ui';

export default {
  name: 'LayoutLayoutMenu',
  components: {
    UiNav,
    UiNavItem,
    UiNavBar,
    UiNavBarItem,
  },
  methods: {
    onYourOrdersClick() {
      const shops = this.$store.getters['shops/list'];

      if (shops.length > 0) {
        this.$router.replace({
          name: 'orders',
          params: {
            shopId: shops[0].id,
          },
        }).catch(() => {});
      } else {
        this.$router.replace({ name: 'shops' });
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.layout-menu {
  margin-left: 0;
  margin-right: 0;
  padding: 0 30px;

  &__header {
    color: #333333;
    font-size: 18px;
    letter-spacing: 0.4px;
    line-height: 23px;
  }

  @include for-tablet-portrait-down {
    padding: 0 16px;

    &__header {
      display: none;
    }
  }
}
</style>
