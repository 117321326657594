<template>
  <ui-badge
    v-if="value > 0"
    :value="value"
    class="orders-badge"
    :class="classes"
  />
</template>

<script>
import {
  UiBadge,
} from '@ui';

export default {
  components: {
    UiBadge,
  },

  props: {
    pending: {
      type: Number,
      default: null,
    },

    incomplete: {
      type: Number,
      default: null,
    },
  },

  computed: {
    type() {
      if (this.pending > 0) {
        return 'pending';
      }

      if (this.incomplete > 0) {
        return 'incomplete';
      }

      return null;
    },

    classes() {
      return {
        'orders-badge--pending': this.type === 'pending',
        'orders-badge--incomplete': this.type === 'incomplete',
      };
    },

    value() {
      if (this.type === 'pending') {
        return this.pending;
      }

      if (this.type === 'incomplete') {
        return this.incomplete;
      }

      return null;
    },
  },
};
</script>

<style lang="scss">
  .orders-badge {
    &--pending {
      background: #B3008F;
    }

    &--incomplete {
      background: #FFAA1C;
    }
  }
</style>
