<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <textarea
    :value="value"
    class="ui-textarea"
    :placeholder="placeholder"
    :disabled="disabled"
    @input="onInput"
  />
</template>

<script>
export default {
  name: 'UiTextarea',
  props: {
    placeholder: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    value: {
      type: String,
      default: null,
    },
  },

  methods: {
    onInput(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style lang="scss">
.ui-textarea {
  border: 1px solid #E1E1E1;
  border-radius: 7px;
  padding: 15px;
  font-size: 14px;
  color: #333333;
  display: block;

  width: 100%;

  &[disabled] {
    background: #eee;
  }
}
</style>
