import auth from '@/auth';
import authRequestInterceptor from '@/logic/interceptors/authRequestInterceptor';
import unauthorizedResponseInterceptor from '@/logic/interceptors/unauthorizedResponseInterceptor';
import unauthorizedRequestInterceptor from '@/logic/interceptors/unautorizedRequestInterceptor';
import OrdersApi from '@/logic/api/Orders';
import PcsDataMap from '@/logic/api/PcsDataMap';

let ordersApi;
let pcsApi;

export default (storeContext) => {
  if (ordersApi && pcsApi) return { orders: ordersApi, pcs: pcsApi };

  const authenticationRequestInterceptor = authRequestInterceptor(auth);
  const unauthorizedReqInterceptor = unauthorizedRequestInterceptor(auth, storeContext);
  const unauthorizedResInterceptor = unauthorizedResponseInterceptor(storeContext);
  ordersApi = new OrdersApi(
    {
      baseURL: window.APP_CONFIG.api.orders,
    },
    {
      request: [authenticationRequestInterceptor, unauthorizedReqInterceptor],
      response: [unauthorizedResInterceptor],
    },
  );
  pcsApi = new PcsDataMap(
    {
      baseURL: window.APP_CONFIG.api.pcs,
    },
    {
      request: [],
      response: [],
    },
  );
  return {
    orders: ordersApi,
    pcs: pcsApi,
  };
};
