<template>
  <router-view>
    <template slot="tabs">
      <router-view name="tabs">
        <template slot="right">
          <router-view name="tabsRight" />
        </template>
      </router-view>
    </template>
  </router-view>
</template>

<script>
export default {
  name: 'ShopShopView',
};
</script>
