<template>
  <refresh-stats class="layout__grow shops">
    <div class="page-shops">
      <ui-nav-bar
        :transparent="true"
        class="page-shops__nav-bar"
        :class="{ 'has-dropdown': hasDropDownList }"
      >
        <ui-nav-bar-item grow class="page-shops__list">
          <span v-if="isLoading">Loading...</span>
          <shops-list v-else :has-drop-down-list="hasDropDownList" :shops="shops" />
        </ui-nav-bar-item>

        <ui-nav-bar-item class="page-shops__search">
          <router-view name="search" />
        </ui-nav-bar-item>
      </ui-nav-bar>
    </div>

    <div class="page-orders layout__grow">
      <template v-if="!isLoading">
        <keep-alive>
          <router-view />
        </keep-alive>
      </template>

      <template v-if="!shopSelected">
        <message-template
          icon="/illustration-no-results.svg"
          text="No shop selected"
          additional-text="Select a shop to view orders"
        />
      </template>
    </div>
  </refresh-stats>
</template>

<script>
import {
  UiNavBar,
  UiNavBarItem,
  UiIcon,
} from '@ui';

import MessageTemplate from '@/modules/Orders/components/Messages/_MessageTemplate.vue';
import RefreshStats from '../components/ShopsRefreshStats.vue';
import ShopsList from './ShopsList.vue';

export default {
  name: 'ShopsShopsView',
  components: {
    UiNavBar,
    UiNavBarItem,
    // eslint-disable-next-line vue/no-unused-components
    UiIcon,
    RefreshStats,
    ShopsList,
    MessageTemplate,
  },

  data() {
    return {
      counter: 10,
    };
  },

  computed: {
    shops() {
      return this.$store.getters['shops/list'];
    },

    isLoading() {
      return this.$store.state.shops.isLoading;
    },

    areShopsInitialized() {
      return this.$store.state.shops.isInitialized;
    },

    shopSelected() {
      return !!this.$route.params.shopId;
    },

    hasDropDownList() {
      return this.shops.length >= 8;
    },
  },

  watch: {
    $route: {
      handler: function onChangeRoute() {
        if (!this.$route.params.shopId) {
          this.redirectToMain();
        }
      },
      immediate: true,
    },

  },

  mounted() {
    if (this.areShopsInitialized) {
      this.handleShopsInitialization();
    } else {
      this.$root.$once('shops/initialized', this.handleShopsInitialization);
    }
  },

  destroyed() {
    this.$root.$off('shops/initialized', this.handleShopsInitialization);
  },

  methods: {
    redirectToMain() {
      if (this.shops.length > 0) {
        this.$router.replace({
          name: 'orders',
          params: {
            shopId: this.shops[0].id,
          },
        });
      }
    },

    handleShopsInitialization() {
      if (!this.$route.params.shopId) {
        this.redirectToMain();
      }
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.shops {
  @include for-tablet-portrait-down {
    display: block;
  }
}

.page-shops {
  padding: 0 30px;

  &__list {
    padding-left: 0;
  }

  &__label {
    padding-left: 0;
    color: #717171;
    align-items: start;
    line-height: 40px;
    margin-top: 12px;
  }

  &__icon {
    margin-right: 15px;
    line-height: 40px;
  }

  &__search {
    align-items: start;
    margin-top: 5px;
  }

  &__nav-bar {
    margin-bottom: 12px;
  }

  @include for-tablet-portrait-down {
    padding: 0 16px;

    &__list,
    &__search {
      padding: 0;
      width: 100%;
      margin-bottom: 16px;
    }

    &__label {
      display: none;
    }

    &__nav-bar {
      line-height: 0;
      margin-bottom: 0;
    }
  }

  @include for-phone-only {
    &__nav-bar {
       display: block;
    }
  }

  @include for-tablet-landscape-down {
    &__nav-bar:not(.has-dropdown) {
      display: block;
    }
  }
}
.page-orders {
  padding: 0 30px;

  &__add-additional {
    width: 200px;
    vertical-align: middle;
    display: inline-block;
    padding-left: 10px;
    padding-right: 10px;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60px;
  }

  @include for-tablet-portrait-down {
    padding: 0;
  }
}
</style>
