<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="ui-text-input__wrapper">
    <input
      ref="input"
      class="ui-text-input"
      :value="value"
      :type="type"
      :placeholder="placeholder"
      :class="getInputClass"
      @keyup.enter="handleSubmit"
      @keyup.esc="onClearClick"
      @input="handleInput"
      @focus="inputFocus = true"
      @blur="inputFocus = false"
    >
    <div
      v-if="value && showClear"
      class="ui-text-input__clear"
    >
      <ui-icon
        ref="clear"
        type="x"
        size="14"
        @click="onClearClick"
      />
    </div>
  </div>
</template>

<script>
import UiIcon from '@ui/Icon/Icon.vue';

export default {
  name: 'UiTextInput',
  components: {
    UiIcon,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    placeholder: {
      type: String,
      default: null,
    },
    showClear: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
  },

  data() {
    return {
      inputFocus: false,
    };
  },

  computed: {
    getInputClass() {
      return {
        'is-active': this.inputFocus,
        'is-dirty': this.value && !this.inputFocus,
      };
    },
  },

  methods: {
    onClearClick() {
      this.$emit('clear');
    },
    handleInput(event) {
      this.$emit('input', event.target.value);
    },
    handleSubmit() {
      this.$emit('submit');
    },
  },
};
</script>

<style lang="scss">
  .ui-text-input {
    position: relative;
    height: 40px;
    line-height: 40px;
    width: 300px;
    border-radius: 6px;
    border: 1px solid #D8D8D8;
    outline: none;
    text-indent: 15px;

    &__wrapper {
      position: relative;
    }

    &__clear {
      position: absolute;
      top: 50%;
      right: 10px;
      transform: translateY(-50%);
      height: auto;
      font-size: 14px;
      color: #000;
    }

    &.is-active {
      border: 1px solid #006EF5;
      box-shadow: 0 0 1px 1px #006EF5 ,inset 0 1px 3px 0 rgba(0,0,0,0.5), 0 0 4px 0 rgba(0,110,245,0.5);
    }

    &.is-dirty {
      border: 1px solid #000;
    }
  }
</style>
