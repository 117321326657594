<template>
  <div class="shipping-form-without-msa">
    <div v-if="providers" class="shipping-form-without-msa__content">
      <form name="shippingDetails" @submit.prevent="onSubmit">
        <div class="shipping-form-without-msa__provider-section">
          <div class="shipping-form-without-msa__section-header">
            <span class="shipping-form-without-msa__title"><ui-icon
              class="shipping-form-without-msa__warning"
              type="warning"
              size="23"
              color="#FFB200"
            />TRACK &amp; TRACE</span>
            <span class="shipping-form-without-msa__title-copy">All fields are required</span>
          </div>
          <div>
            <providers-list
              :providers="providers"
              :selected-id="selectedProvider"
              @change="onProviderChange"
            />
          </div>
        </div>

        <div class="shipping-form-without-msa__section">
          <p class="shipping-form-without-msa__section-title">Tracking number</p>
          <div>
            <ui-text-input
              :value="trackingNumber"
              placeholder="Provide tracking number"
              @input="onTrackingChange"
            />
          </div>
        </div>
        <ui-button
          :disabled="!selectedProvider || !trackingNumber"
          type="submit"
          html-type="submit"
          @click="onSubmit"
        >
          Update
        </ui-button>
        <ui-button
          class="shipping-form-without-msa__section-clear"
          type="link-blue"
          html-type="reset"
          @click="onClear"
        >
          Clear
        </ui-button>
      </form>
    </div>
  </div>
</template>

<script>
import {
  UiIcon,
  UiTextInput,
  UiButton,
} from '@ui';

import ProvidersList from './ProvidersList.vue';

export default {
  components: {
    UiButton,
    UiTextInput,
    UiIcon,
    ProvidersList,
  },

  props: {
    providers: {
      type: Array,
      default: () => [],
    },

    selectedProvider: {
      type: String,
      default: null,
    },

    trackingNumber: {
      type: String,
      default: null,
    },
  },
  computed: {
    orderId() {
      return this.$route?.params?.orderId;
    },
  },
  watch: {
    orderId: {
      handler: function onChangeOrderId(value) {
        if (value) {
          this.onClear();
        }
      },
      immediate: true,
    },

  },
  methods: {
    onSubmit() {
      this.$emit('save');
    },

    onClear() {
      this.$emit('select-provider', null);
      this.$emit('tracking-number-change', null);
    },

    onProviderChange(providerId) {
      this.$emit('select-provider', providerId);
    },

    onTrackingChange(value) {
      const trackingNumber = value.length > 0 ? value : null;
      this.$emit('tracking-number-change', trackingNumber);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.shipping-form-without-msa {
  margin: 30px 0 40px;

  &__provider-section {
    margin-bottom: 10px;
  }

  &__section {
    margin-bottom: 30px;
    width: 50%;
  }

  &__section-header {
    margin-bottom: 30px;
    font-size: 14px;
    color: #666666;
  }

  &__section-title {
    margin-bottom: 10px;
    font-size: 14px;
    color: #666666;
  }

  &__title {
    margin-right: 20px ;
    font-size: 14px;
    color: #666666;
    font-weight: bold;
  }

  &__warning {
    margin-right: 10px;
  }

  &__title-copy {
    color: #999999;
  }

  .ui-text-input {
    width: 100%;
  }

  &__section-clear {
    margin-left: 10px;
  }

  @include for-tablet-landscape-down {
    &__section {
      width: 60%;
    }
  }

  @include for-phone-only {
    &__section {
      width: 100%;
    }
  }
}
</style>
