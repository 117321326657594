<template>
  <div class="ui-search">
    <ui-text-input
      ref="input"
      :value="value"
      class="ui-search__input"
      name="search"
      :placeholder="placeholderText"
      :show-clear="true"
      @input="handleInput"
      @click="inputFocus = true"
      @clear="onClearClick"
      @submit="onSearchClick"
    />
    <div class="ui-search__icon">
      <ui-icon
        ref="search"
        type="search"
        size="17"
        @click="onSearchClick"
      />
    </div>
  </div>
</template>

<script>
import { UiTextInput, UiIcon } from '@ui';

export default {
  name: 'UiSearch',
  components: {
    UiTextInput,
    UiIcon,
  },

  props: {
    value: {
      type: String,
      default: null,
    },
    placeholderText: {
      type: String,
      default: 'Order id, customer name, or email',
    },
  },

  methods: {
    onClearClick() {
      this.$emit('clear');
    },
    onSearchClick() {
      this.$emit('search');
    },
    handleInput(value) {
      this.$emit('input', value);
    },
  },
};

</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

  .ui-search {
    white-space: nowrap;

    &__input {
      display: inline-block;
    }

    &__icon {
      display: inline-block;
      padding-left: 15px;
    }

    @include for-tablet-portrait-down {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      input {
        width: 100%;
      }

      &__icon {
        padding: 8px 16px;
      }
    }
  }
</style>
