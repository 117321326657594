<template>
  <message-template
    icon="/illustration-no-results.svg"
    text="Sorry, no returns found"
    additional-text=""
  />
</template>

<script>
import MessageTemplate from '@/modules/Orders/components/Messages/_MessageTemplate.vue';

export default {
  components: {
    MessageTemplate,
  },
};
</script>
