<template>
  <message-template
    icon="/illustration-sleepy-basket.svg"
    text="No orders yet"
    additional-text="Here you will find your accepted orders."
  />
</template>

<script>
import MessageTemplate from '@/modules/Orders/components/Messages/_MessageTemplate.vue';

export default {
  components: {
    MessageTemplate,
  },
};
</script>
