<template>
  <order-base :order="order">
    <template #positions>
      <order-positions-list
        :declined="order.declinedPositions"
        :currency="order.currency"
        :show-declined="true"
      />
    </template>
  </order-base>
</template>

<script>
import OrderBase from './OrderBase.vue';
import OrderPositionsList from '../Position/OrderPositionsList.vue';

export default {
  components: {
    OrderBase,
    OrderPositionsList,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },
  },
};
</script>
