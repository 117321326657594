<template>
  <div class="message">
    <div class="message__icon">
      <slot name="icon" />
    </div>
    <div class="message__text">
      <slot name="text" />
    </div>
    <div class="message__text-additional">
      <slot name="additional-text" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderMessage',
};
</script>

<style lang="scss">
  .message {
    margin: 50px 0;
    text-align: center;
    color: #dbdbdb;

    &__icon {
      font-size: 70px;
    }

    &__text,
    &__text-additional {
      font-size: 18px;
      font-style: italic;
      display: block;
      margin: 0 auto;
      margin-top: 20px;
      width: 70%;
    }

    &__text {
      font-weight: bold;
      color: #c5c5c5;
    }

    &__text-additional {
      margin-top: 20px;
    }
  }

</style>
