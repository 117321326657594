<template>
  <div class="ui-spinner-container" :class="classes">
    <slot />
    <div class="ui-spinner-container__mask">
      <spinner class="ui-spinner-container__spinner" />
    </div>
  </div>
</template>

<script>
import Spinner from './Spinner.vue';

export default {
  name: 'UiSpinnerContainer',
  components: {
    Spinner,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'is-loading': this.isLoading,
      };
    },
  },
};
</script>

<style lang="scss">
.ui-spinner-container {
  $this: &;
  position: relative;
  min-height: 60px;

  &__mask {
    display: none;
    background: rgba(255, 255, 255, 0.6);
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    z-index: 1;

    align-items: center;
    justify-content: center;
  }

  &__spinner {
    vertical-align: middle;
  }

  &.is-loading {
    #{$this} {
      &__mask {
        display: flex;
      }
    }
  }
}

</style>
