<template>
  <button
    class="ui-button"
    :class="classObject"
    :disabled="disabled"
    :type="htmlType"
    @click="handleClick"
  >
    <slot />
  </button>
</template>

<script>
export default {
  name: 'UiButton',
  components: {},

  props: {
    type: {
      type: String,
      default: 'primary',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    active: {
      type: Boolean,
      default: false,
    },
    htmlType: {
      type: String,
      default: null,
    },
    pressed: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classObject() {
      return {
        'is-active': this.active,
        [`ui-button--${this.type}`]: this.type,
        'is-pressed': this.pressed,
      };
    },
  },

  methods: {
    handleClick() {
      this.$emit('click');
    },
  },
};
</script>

<style lang="scss">
.ui-button {
  height: 40px;
  padding: 10px 20px;
  border-radius: 4px;
  outline: none;
  margin: 3px 0;
  line-height: 1;
  cursor: pointer;

  &--submit {
    background-color: #006ef5;
    border: 1px solid #006ef5;
    color: #ffffff;
  }

  &--success {
    border: 1px solid #b3daff;
    background-color: #ffffff;
    color: #0083fb;

    &.is-pressed {
      box-shadow: inset 0px 2px 2px rgba(0, 0, 0, 0.15);
      background-color: #f2f9ff;
    }

    &.is-active {
      border: none;
      background-color: #00ac00;
      color: #ffffff;
    }
  }

  &--danger {
    border: 1px solid #b3daff;
    background-color: #ffffff;
    color: #0083fb;

    &.is-active {
      border: none;
      background-color: #b70000;
      color: #ffffff;
    }
  }

  &:disabled {
    background-color: #e8e8e8;
    border: 1px solid #d6d6d6;
    color: #666666;
    cursor: not-allowed;
  }

  &--primary {
    border: 1px solid #dddddd;

    &:disabled {
      color: #aaaaaa;
      background-color: #ffffff;
    }
  }

  &--link {
    border: none;
    background: none;

    &:disabled {
      border: none;
      color: #aaaaaa;
      background-color: #ffffff;
    }
  }

  &--link-blue {
    border: none;
    color: #0078fb;
    background: none;

    &:disabled {
      border: none;
      color: #aaaaaa;
      background-color: #ffffff;
    }
  }

  &--link-error {
    border: none;
    background: none;
    color: #ff1c1c;

    &:disabled {
      border: none;
      color: #aaaaaa;
      background-color: #ffffff;
    }
  }
}
</style>
