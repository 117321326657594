/* eslint-disable no-underscore-dangle */

import Vue from 'vue';
import auth from '@/auth';
import { hasAccessToAllStores } from '@/helpers';

export const types = {
  RESET: 'RESET',
  AUTHENTICATE: 'AUTH/AUTHENTICATE',
  LOGOUT: 'AUTH/LOGOUT',
  LOAD: 'AUTH/LOADED',
  INIT: 'AUTH/INIT',
  ADDITIONAL_SHOP_ADD: 'AUTH/ADDITIONAL_SHOP_ADD',
  ADDITIONAL_SHOP_INIT: 'AUTH/ADDITIONAL_SHOP_INIT',
  SESSION: 'AUTH/SESSION',
};

const initialState = () => ({
  isLoggedIn: false,
  accessorEmail: null,
  accessorId: null,
  privileges: null,
  hasAccessToAllStores: false,
  additionalShops: [],
  hasSession: false,
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  getters: {
    availableShops: (state) => {
      if (!state.privileges) {
        return null;
      }

      if (hasAccessToAllStores(state)) {
        return state.additionalShops;
      }

      // eslint-disable-next-line no-underscore-dangle
      let ids = state.privileges.__GLOBAL__.Store;
      ids = Object.keys(ids);
      ids = ids.filter((id) => id !== '*');

      return ids;
    },
  },

  mutations: {
    [types.RESET](state) {
      Object.assign(state, initialState());
    },

    [types.AUTHENTICATE](state, channelData) {
      state.privileges = channelData.data.privileges;
      state.accessorEmail = channelData.data.accessor.email;
      state.accessorId = channelData.data.accessor.id;
      state.hasAccessToAllStores = channelData.hasAccessToAllStores;
      state.isLoggedIn = true;
    },

    [types.LOGOUT](state) {
      state.isLoggedIn = false;
    },

    [types.INIT](state, isLoggedIn) {
      state.isLoggedIn = isLoggedIn;
    },

    [types.ADDITIONAL_SHOP_ADD](state, id) {
      state.additionalShops.push(id);
    },

    [types.ADDITIONAL_SHOP_INIT](state, ids) {
      Vue.set(state, 'additionalShops', ids);
    },

    [types.SESSION](state, hasSession) {
      state.hasSession = hasSession;
    },
  },

  actions: {
    login({ commit, dispatch }, data) {
      return new Promise((resolve, reject) => {
        auth.createChannel(data.email, data.password).then((channel) => {
          auth.save();
          const channelData = channel.serialize();
          commit(types.AUTHENTICATE, channelData);
          dispatch('setSession');
          dispatch('setFeedbackScriptUsername', channelData.data.accessor.email);
          dispatch('loadAdditionalShops');
          resolve(channel);
        }).catch((reason) => {
          reject(reason);
        });
      });
    },

    logout({ dispatch, commit }, data) {
      return new Promise((resolve) => {
        if (data && data.shouldDeleteSession) {
          auth.deleteSession();
        }

        auth.destroyChannel().then((response) => {
          commit(types.LOGOUT);
          dispatch('setSession');
          dispatch('reset', null, { root: true });
          resolve(response);
        });
      });
    },

    setSession({ commit }) {
      commit(types.SESSION, auth.hasSession());
    },

    load({ commit }) {
      auth.load();
      commit(types.AUTHENTICATE);
    },

    init({ commit, dispatch }) {
      const channel = auth.getChannel();

      if (channel) {
        const channelData = channel.serialize();
        commit(types.AUTHENTICATE, channelData);
        dispatch('setFeedbackScriptUsername', channelData.data.accessor.email);
        dispatch('loadAdditionalShops');
      }
    },

    loadAdditionalShops({ commit }) {
      commit(types.ADDITIONAL_SHOP_INIT, JSON.parse(window.localStorage.getItem('additionalShops')) || []);
    },

    setFeedbackScriptUsername(email) {
      if (window._gscq && email) {
        window._gscq.push(['user', 'name', email]);
      }
    },

    saveAdditionalShop({ commit }, id) {
      const shops = JSON.parse(window.localStorage.getItem('additionalShops')) || [];
      if (shops.indexOf(id) === -1) {
        shops.push(id);
        window.localStorage.setItem('additionalShops', JSON.stringify(shops));

        commit(types.ADDITIONAL_SHOP_ADD, id);
      }
    },
  },
};
