import AuthService from './AuthService';
import AuthApi from './AuthApi';

// eslint-disable-next-line func-names
export default function (config) {
  const authApi = new AuthApi(config.authApi);
  const instance = new AuthService(authApi);

  return instance;
}
