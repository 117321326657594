<template>
  <message-template
    icon="/illustration-no-results.svg"
    text="Sorry, no orders found"
    additional-text="Try a different search"
  />
</template>

<script>
import MessageTemplate from '@/modules/Orders/components/Messages/_MessageTemplate.vue';

export default {
  components: {
    MessageTemplate,
  },
};
</script>
