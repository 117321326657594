<template>
  <span
    class="ui-tag"
    :class="getClasses"
  >
    <slot />
  </span>
</template>

<script>
export default {
  name: 'UiTag',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
  },

  computed: {
    getClasses() {
      return {
        [`ui-tag--${this.type}`]: this.type,
      };
    },
  },
};
</script>

<style lang="scss">
.ui-tag {
  padding: 5px 10px;
  border-radius: 4px;
  cursor: default;
  pointer-events: none;
  white-space: nowrap;

  &--submit {
    background-color: #006EF5;
    color: #ffffff;
  }

  &--success {
    background-color: #19be6b;
    color: #ffffff;
  }

  &--danger {
    background-color: #B70000;
    color: #ffffff;
  }

  &--primary {
    background: #ccc;
    color: #333;
  }
}
</style>
