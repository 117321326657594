<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div class="ui-radio-input__wrapper">
    <input
      :id="id"
      :name="name"
      :value="value"
      :checked="shouldBeChecked"
      :disabled="disabled"
      class="ui-radio-input"
      type="radio"
      @click="handleClick"
      @change="onChange"
    >
  </div>
</template>

<script>
export default {
  name: 'RadioInput',

  model: {
    prop: 'modelValue',
    event: 'change',
  },

  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      type: [Boolean, String, Number],
      default: null,
    },
    modelValue: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: null,
    },
  },

  computed: {
    shouldBeChecked() {
      return this.modelValue === this.value;
    },
  },

  methods: {
    handleClick(event) {
      event.stopPropagation();
      this.$emit('click', event.target.value);
    },

    onChange() {
      this.$emit('change', this.value);
    },
  },
};
</script>

<style lang="scss">
  .ui-radio-input {
    &__wrapper {
      position: relative;
    }
  }
</style>
