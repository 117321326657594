import apiV2 from '@/apiV2/api';

const initialState = () => ({
  isLoading: false,
  isFailed: false,
  data: null,
  errorMsg: null,
});

export const types = {
  RESET: 'RESET',
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILED: 'FETCH_FAILED',
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    [types.FETCH_REQUEST](state) {
      state.data = null;
      state.isLoading = true;
      state.isFailed = false;
      state.errorMsg = null;
    },

    [types.FETCH_SUCCESS](state, { data }) {
      state.isLoading = false;
      state.isFailed = false;
      state.data = data;
    },

    [types.FETCH_FAILED](state, msg) {
      state.isLoading = false;
      state.isFailed = true;
      state.errorMsg = msg;
    },
  },

  getters: {
    rmaRequest: (state) => ({
      ...state.data,
      relatedOrderId: state.data ? state.data.groupId : null,
    }),
  },

  actions: {
    fetchRmaRequestV2(context, { contextId, returnId }) {
      context.commit(types.FETCH_REQUEST);

      return apiV2(context).returns.getRma(contextId, returnId)
        .then(async ({ data: rmaData, meta }) => {
          const orderId = rmaData?.positionId;

          const { data: mainOrder } = await apiV2(context).orders.getOrderRMA(contextId, orderId, 'accepted', true);
          const returnPositionIds = context.rootGetters['rmaRequests/returns']?.find((item) => item.groupId === rmaData.groupId)?.returnPositionIds || [];

          let restOrder = [];
          const relatedPositions = mainOrder.relatedPositionIds?.filter((id) => id !== rmaData?.positionId && returnPositionIds?.includes(id));

          if (relatedPositions.length) {
            const { data } = await apiV2(context).orders.getOrderRMA(contextId, relatedPositions, 'accepted', true);
            restOrder = Object.values(data);
          }

          const source = relatedPositions.length ? [mainOrder, ...restOrder].filter((n) => n) : [mainOrder];

          const itemIds = [...new Set(source.map((src) => src.item.id))];
          const collectedOrders = itemIds.reduce((acc, curr) => {
            const items = source.filter((f) => curr === f.item.id);
            const order = {
              ...items[0],
              name: items[0].product.title,
              amount: items[0].price.value * items.length,
              currency: items[0].price.currency,
              color: items[0].product.color,
              brand: items[0].product.brand,
              reasonForReturning: items[0].reasonForReturning || rmaData.returnReason,
              size: items[0].item.size.name,
              price: items[0].price.value,
              totalAmount: items[0].price.value * items.length,
              quantity: items.length,
              duplicatePositon: items[0].orderId,
            };

            return [...acc, order];
          }, []);

          const finalRmaData = {
            ...rmaData,
            id: rmaData.groupId,
            createdDatetime: rmaData.createdAt,
            parentOrderId: mainOrder.orderId,
            orderStatus: mainOrder.status,
            status: rmaData.status,
            returnedProducts: [
              ...collectedOrders,
            ],
            customer: {
              ...mainOrder.shippingInformation,
            },
            currency: mainOrder.price.currency,
            amount: collectedOrders.map((el) => el.totalAmount).reduce((a, b) => a + b, 0),
          };

          context.commit(types.FETCH_SUCCESS, { data: finalRmaData, meta });
          return finalRmaData;
        })
        .catch((rmaError) => {
          context.commit(types.FETCH_FAILED, rmaError.msg);
          throw rmaError;
        });
    },
  },
};
