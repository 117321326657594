import axios from 'axios';

export default class AuthApi {
  constructor(config) {
    this.http = axios.create(config);
  }

  createChannel(identifier, secret) {
    return new Promise((resolve, reject) => {
      this.http.post('/channels', {
        identifier,
        secret,
      }).then((response) => {
        resolve(response.data.data);
      }).catch(() => {
        reject(new Error());
      });
    });
  }
}
