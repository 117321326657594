<template>
  <div class="ui-thumbnail__wrapper">
    <picture>
      <source
        :srcset="src.replace(/\.[^.]+$/, '.avif')"
        type="image/avif"
      >
      <source
        :srcset="src.replace(/\.[^.]+$/, '.webp')"
        type="image/webp"
      >
      <img
        class="ui-thumbnail"
        :src="src.replace(/\.[^.]+$/, '.jpg')"
        :width="width"
        :height="height"
        :alt="alt"
      >
    </picture>
  </div>
</template>

<script>
export default {
  name: 'UiThumbnail',
  props: {
    src: {
      type: String,
      required: true,
    },
    width: {
      type: String,
      default: '65px',
    },
    height: {
      type: String,
      default: '65px',
    },
    alt: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
  .ui-thumbnail {
      padding: 1px;
      border: 1px solid #BBBBBB;

    &__wrapper {
      display: inline-block;
      margin: 7px 3px auto 0;
    }
  }
</style>
