<!-- eslint-disable vuejs-accessibility/anchor-has-content -->
<template>
  <div class="header-mobile">
    <a href="#" class="header-mobile__button" @click.prevent="toggle()">
      <ui-icon :type="!expanded ? 'hamburger' : 'x'" />
    </a>
    <div class="header-mobile__title">
      <slot name="title">
        {{ title }}
      </slot>
    </div>
  </div>
</template>

<script>
import UiIcon from '@/ui/Icon/Icon.vue';

export default {
  name: 'UiHeaderMobile',
  components: {
    UiIcon,
  },

  props: {
    title: {
      type: String,
      default: null,
    },

    expanded: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    toggle() {
      this.$emit('toggle', !this.expanded);
    },
  },
};
</script>

<style lang="scss">
@import './header-variables.scss';

.header-mobile {
  background: white;
  border-bottom: 1px solid #F0F0F0;
  text-align: center;
  height: $mobile-header-height;

  &__title {
    font-size: 16px;
    line-height: $mobile-header-height;
  }

  &__button {
    position: absolute;
    top: 0;
    left: 0;
    padding: 0px 16px;
    font-size: 20px;
    line-height: $mobile-header-height;
    color: black;

    &:hover, &:active {
      color: black;
    }
  }
}
</style>
