export const refreshInterval = 60000;
export const sessionLifeTime = 1440; // in minutes
export const shippingProvidersMap = {
  /* eslint-disable quote-props */
  'BRING-CUR': 'posten_logo',
  'BRING-NO-INTEGRATION': 'bring_logo',
  'DB-SCHENKER-NO-INTEGRATION': 'db_schenker_logo',
  'DHL': 'dhl_logo',
  'DHL-EXPRESS': 'dhl_logo',
  'DHL-EXPRESS-NO-INTEGRATION': 'dhl_logo',
  'DHL-NO-INTEGRATION': 'dhl_logo',
  'DHLSETMP': 'dhl_logo',
  'DPD': 'dpd_logo',
  'DPD-NO-INTEGRATION': 'dpd_logo',
  'DPD-PRIVATE': 'dpd_logo',
  'FEDEX-NO-INTEGRATION': 'fedex_logo',
  'GLS': 'gls_pakkeshop',
  'GLS-NO-INTEGRATION': 'gls_home',
  'GLS-PRIVATE': 'gls_home',
  'POST-DANMARK-NO-INTEGRATION': 'post_danmark',
  'POST-NORD-NO-INTEGRATION': 'postnord_logo',
  'POSTEN': 'posten_logo',
  'POSTEN-DK-NO-INTEGRATION': 'posten_logo',
  'POSTENSE-NO-INTEGRATION': 'posten_logo',
  'SAME-DAY-DELIVERY': '80min_Delivery_Icon2',
  'SWISSPOST': 'schweizerische_die_post_logo',
  'UPS': 'ups_logo',
  'UPS-HOME-DELIVERY-INTERNATIONAL': 'ups_logo',
  'UPS-SERVICE-POINT-INTERNATIONAL': 'ups_logo',
  'UPS-NO-INTEGRATION': 'ups_logo',
  /* eslint-enable quote-props */
};
export const tabsParamsMapping = {
  awaiting: {
    status: ['pending'],
  },
  accepted: {
    status: ['accepted'],
  },
};
export const countriesData = {
  '045': {
    name: 'Denmark',
    iso: 'dk',
  },
  '046': {
    name: 'Sweden',
    iso: 'se',
  },
  '047': {
    name: 'Norway',
    iso: 'no',
  },
  '031': {
    name: 'Netherlands',
    iso: 'nl',
  },
  '032': {
    name: 'Belgium',
    iso: 'be',
  },
  '048': {
    name: 'Poland',
    iso: 'pl',
  },
  '041': {
    name: 'Switzerland',
    iso: 'ch',
  },
  '049': {
    name: 'Germany',
    iso: 'de',
  },
  '034': {
    name: 'Spain',
    iso: 'es',
  },
  '358': {
    name: 'Finland',
    iso: 'fi',
  },
  '033': {
    name: 'France',
    iso: 'fr',
  },
  '039': {
    name: 'Italy',
    iso: 'it',
  },
  '044': {
    name: 'Great Britain',
    iso: 'gb',
  },
  '086': {
    name: 'China',
    iso: 'cn',
  },
  '001': {
    name: 'United States',
    iso: 'us',
  },
};

export default {
  refreshInterval,
  shippingProvidersMap,
  tabsParamsMapping,
  countriesData,
};
