<template>
  <div class="ui-dropdown">
    <div ref="anchor" class="ui-dropdown__anchor-wrapper">
      <slot
        class="ui-dropdown__anchor"
        name="anchor"
        :toggle="toggle"
        :is-opened="opened"
      />
    </div>
    <portal to="outer-portal">
      <div
        v-if="opened"
        class="ui-dropdown__body"
        :class="bodyClasses"
        :style="styles"
      >
        <div class="ui-dropdown__content">
          <slot :toggle="toggle" :is-opened="opened" />
        </div>
      </div>
    </portal>
  </div>
</template>

<script>
import CurrentDropdown from './CurrentDropdown';

export default {
  name: 'UiDropdown',
  props: {
    nowrap: {
      type: Boolean,
      default: false,
    },
    horizontalAlign: {
      type: String,
      default: 'left',
    },
    verticalAlign: {
      type: String,
      default: 'under',
    },
    width: {
      type: Number,
      default: null,
    },

    bodyClass: {
      type: String,
      default: null,
    },
    /**
     * ID of parent element that is scrollable
     * and can affect the dropdown position.
     */
    scrollContext: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      opened: false,
      styles: null,
    };
  },

  computed: {
    bodyClasses() {
      const classes = [];

      if (this.nowrap) {
        classes.push('ui-dropdown__body--nowrap');
      }

      if (this.bodyClass) {
        classes.push(this.bodyClass);
      }

      return classes;
    },
  },

  mounted() {
    // eslint-disable-next-line prefer-destructuring
    this.scrollParent = document.getElementsByClassName(this.scrollContext)[0];
  },

  beforeDestroy() {
    this.toggle(false);
  },

  methods: {
    resetData() {
      Object.assign(this.$data, this.$options.data());
    },

    toggle(state) {
      if (state) {
        if (CurrentDropdown.getDropdown()) {
          CurrentDropdown.getDropdown().resetData();
          CurrentDropdown.clearDropdown();
        }

        this.startListeners();
        this.calculateStyles();
        CurrentDropdown.setDropdown(this);
      } else {
        this.stopListeners();
        CurrentDropdown.clearDropdown();
      }

      this.opened = state;
    },

    startListeners() {
      window.addEventListener('resize', this.calculateStyles);
      window.addEventListener('orientationchange', this.hideDropdown);

      if (this.scrollParent) {
        this.scrollParent.addEventListener('scroll', this.calculateStyles);
      }
    },

    stopListeners() {
      window.removeEventListener('resize', this.calculateStyles);
      window.addEventListener('orientationchange', this.hideDropdown);

      if (this.scrollParent) {
        this.scrollParent.removeEventListener('scroll', this.calculateStyles);
      }
    },

    calculateStyles() {
      const anchorRect = this.anchorRectangle();
      const styles = {
        width: this.width ? `${this.width}px` : null,
      };

      if (this.horizontalAlign === 'right' && this.verticalAlign === 'under') {
        styles.top = `${anchorRect.bottom}px`;
        styles.right = `${window.innerWidth - anchorRect.right}px`;
      } else if (this.horizontalAlign === 'right' && this.verticalAlign === 'above') {
        styles.bottom = `${window.innerHeight - anchorRect.top}px`;
        styles.right = `${window.innerWidth - anchorRect.right}px`;
      } else if (this.horizontalAlign === 'left' && this.verticalAlign === 'under') {
        styles.top = `${anchorRect.bottom}px`;
        styles.left = `${anchorRect.left}px`;
      } else if (this.horizontalAlign === 'left' && this.verticalAlign === 'above') {
        styles.bottom = `${window.innerHeight - anchorRect.top}px`;
        styles.left = `${anchorRect.left}px`;
      }

      this.styles = styles;
    },

    anchorRectangle() {
      const domRect = this.$refs.anchor.getBoundingClientRect();

      return {
        left: domRect.left,
        top: domRect.top,
        right: domRect.right,
        bottom: domRect.bottom,
        width: domRect.width,
        height: domRect.height,
      };
    },

    hideDropdown() {
      this.toggle(false);
    },
  },
};
</script>

<style lang="scss">
.ui-dropdown {
  position: relative;
  display: inherit;
  flex-direction: inherit;

  &__anchor-wrapper {
    display: inherit;
    flex-direction: inherit;
  }

  &__body {
    z-index: 1;
    position: absolute;
    background-color: #fff;
    border: 1px solid #ccc;
    box-shadow: 0 2px 10px 3px rgba(0,0,0,0.2);
    border-radius: 3px;
    padding: 20px;
    text-align: left;

    &--nowrap {
      white-space: nowrap;
    }
  }

  &__content {
    white-space: normal;
  }
}
</style>
