<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <ui-nav-bar slot="header" class="returns-nav-bar" :transparent="true">
    <ui-nav-bar-item class="returns-nav-bar__refresh">
      <ui-icon
        type="refresh"
        size="16"
        @click="handleRefresh"
      />
    </ui-nav-bar-item>
    <div class="orders__search-wrapper">
      <uuid-search
        @search-uuid="handleSearchUuid"
      />
    </div>
    <ui-nav-bar-item class="returns-nav-bar__filter-box">
      <Select :label="statusFilterLabel" :value="status" @on-change="handleFilterStatusChange">
        <Option
          v-for="option in statusFilterOptions"
          :key="option.value"
          :value="option.value"
        >
          {{ option.text }}
        </Option>
      </Select>
    </ui-nav-bar-item>
    <ui-nav-bar-item v-if="total > limit" class="returns-nav-bar__pagination">
      <ui-pagination
        :limit="limit"
        :offset="offset"
        :total-item-count="total"
        @page-change="handlePaginationChange"
      />
    </ui-nav-bar-item>
  </ui-nav-bar>
</template>

<script>
import {
  Select,
  Option,
} from 'iview';
import {
  UiIcon,
  UiNavBar,
  UiNavBarItem,
  UiPagination,
} from '@ui';

import UuidSearch from '../UUIDSearch.vue';

export default {
  name: 'LayoutNavBar',
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Select,
    // eslint-disable-next-line vue/no-reserved-component-names
    Option,
    UiIcon,
    UiNavBar,
    UiNavBarItem,
    UiPagination,
    UuidSearch,
  },

  props: {
    limit: {
      type: Number,
      default: 0,
    },

    offset: {
      type: Number,
      default: 0,
    },

    total: {
      type: Number,
      default: 0,
    },

    status: {
      type: String,
      default: '',
    },

    statusFilterOptions: {
      type: Array,
      default: () => ([
        { value: '', text: 'All returns' },
        { value: 'approved', text: 'Approved' },
        { value: 'pending', text: 'Pending' },
        { value: 'rejected', text: 'Rejected' },
      ]),
    },
  },

  computed: {
    statusFilterLabel() {
      return this.statusFilterOptions.find((option) => option.value === '').text;
    },
  },

  methods: {
    handleRefresh() {
      this.$emit('refresh');
    },

    handleFilterStatusChange(val) {
      this.$emit('filter-status-change', val);
    },

    handlePaginationChange(params) {
      this.$emit('pagination-change', params);
    },

    handleSearchUuid(query) {
      this.$emit('search-uuid', query);
    },
  },
};
</script>

<style lang="scss">
.returns-nav-bar {
  line-height: normal;
  padding: 13px 0;
  min-height: 72px;

  &__filter-box {
    width: 150px;
    margin-left: auto;

    .ivu-select-selection {
      height: 29px;
      border-radius: 6px;

      .ivu-select-selected-value {
        height: 25px;
        line-height: 27px;
        font-size: inherit;
      }
    }
  }
}
</style>
