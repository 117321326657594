import { isCancel } from 'axios';

export const getCountryCodeFromShopId = (shopId) => shopId.split('-')[2];
export const locationReload = () => window.location.reload();
export const addExtraOrderData = (data, extra) => data.map((item) => ({
  ...item,
  ...extra,
}));
export const b64toBlob = (b64Data, contentType = 'application/pdf', sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  return new Blob(byteArrays, { type: contentType });
};
export const hasAccessToAllStores = (data) => {
  if (data.privileges) {
    if (Object.hasOwnProperty.call(data.privileges, '*')) {
      return true;
    }

    // eslint-disable-next-line no-underscore-dangle
    if (Object.keys(data.privileges.__GLOBAL__.Store).filter((id) => id === '*').length > 0) {
      return true;
    }
  }
  return false;
};
export const setActiveFavicon = (active) => {
  const link = document.querySelector('link[rel*=\'icon\']') || document.createElement('link');
  link.type = 'image/x-icon';
  link.rel = 'shortcut icon';
  link.href = active ? '/favicon-active.png' : '/favicon.png';
  document.getElementsByTagName('head')[0].appendChild(link);
};
export const parseSuccessResponse = (response) => response.data;
export const parseErrorResponse = (error) => {
  if (isCancel(error)) throw error;

  const r = error.response;
  const e = {};
  if (r && r.data && r.data.meta) {
    e.status = r.data.meta.status;
    e.msg = r.data.meta.msg || 'We were unable to perform the action you requested, please contact us for technical assistance.';
  }
  throw e;
};

export default {
  getCountryCodeFromShopId,
  locationReload,
  addExtraOrderData,
  b64toBlob,
  hasAccessToAllStores,
  setActiveFavicon,
  parseSuccessResponse,
  parseErrorResponse,
};
