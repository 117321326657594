<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div class="rma-details-actions">
    <ui-button type="link-error" :disabled="disableButtons" @click="$emit('decline')">Decline Return</ui-button>
    <ui-button type="submit" :disabled="disableButtons" @click="$emit('accept')">Accept Return</ui-button>
    <p v-if="disableButtons">
      RMA is not ready yet - please click <span class="refresh-button" @click="$emit('refresh')">Refresh</span> after 1 minute
    </p>
  </div>
</template>

<script>
import { UiButton } from '@/ui';

export default {
  name: 'ReturnsRmaDetailsActions',
  components: {
    UiButton,
  },
  props: {
    disableButtons: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
.rma-details-actions {
  text-align: right;
}
.refresh-button {
  color: blue;
  text-decoration: underline;
  cursor: pointer;
}
</style>
