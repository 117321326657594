<template>
  <div>
    <ui-spinner-container :is-loading="isLoading || isHandling" style="height: 100%;">
      <rma-details-container v-if="rma.id">
        <rma-details-header
          :id="rma.id"
          :shop-id="shopId"
          :created-date="new Date(rma.createdDatetime)"
          :order-id="rma.relatedOrderId"
          :parent-order-id="rma.parentOrderId"
          :status="rma.status"
        />
        <rma-details-addresses
          :return-to="rma.returnTo"
          :customer="rma.billingInformation"
        />

        <rma-details-items-list
          :items="items"
          :total="rma.amount"
          :currency="rma.currency"
        />

        <template v-if="canHandleRma">
          <ui-message style="margin-bottom: 48px;">The return has been requested by a customer for the reason stated above. After you accept the return the paid amount will be refunded to the customer and will be deducted from your order. If you decline the return you should send the goods back to the customer and the order will stay unchanged.</ui-message>

          <ui-error
            v-if="actionHappened && isHandlingFailed"
            :subtitle="handlingError"
            :show-cancel="true"
            @try-again="handleTryAgain"
            @cancel="actionHappened = false"
          />
          <template v-else>
            <div
              v-if="displayReturnReason"
              style="display: flex; justify-content: flex-end;"
            >
              <rma-return-reason-form
                style="max-width: 400px;"
                @submit="handleSubmitReason"
              />
            </div>
            <rma-details-actions
              v-else
              :disable-buttons="actionHappened || isOrderAwaiting || isProcessingCurrentRma"
              @accept="handleReturnAccept"
              @decline="handleReturnDecline"
              @refresh="fetch"
            />
          </template>
        </template>
      </rma-details-container>
      <message-template
        v-if="!isLoading && isFailed"
        icon="/illustration-no-results.svg"
        text="Return does not exist"
      />
    </ui-spinner-container>
    <slot name="close" />
  </div>
</template>

<script>
import {
  UiError,
  UiSpinnerContainer,
  UiMessage,
} from '@ui';
import MessageTemplate from '@/modules/Orders/components/Messages/_MessageTemplate.vue';
import RmaDetailsContainer from '../components/Rma/Details/DetailsContainer.vue';
import RmaDetailsHeader from '../components/Rma/Details/Header.vue';
import RmaDetailsAddresses from '../components/Rma/Details/Addresses.vue';
import RmaDetailsItemsList from '../components/Rma/Details/Items/List.vue';
import RmaDetailsActions from '../components/Rma/Details/Actions.vue';
import RmaReturnReasonForm from '../components/Rma/Details/ReturnReasonForm.vue';

export default {
  name: 'ReturnsRmaView',
  components: {
    UiError,
    UiSpinnerContainer,
    UiMessage,
    MessageTemplate,
    RmaDetailsContainer,
    RmaDetailsHeader,
    RmaDetailsAddresses,
    RmaDetailsItemsList,
    RmaDetailsActions,
    RmaReturnReasonForm,
  },

  props: {
    shopId: {
      type: String,
      default: null,
    },
    returnId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      actionHappened: false,
      actionFailed: false,
      displayReturnReason: false,
      currentAction: null,
      declineReasonForm: null,
    };
  },

  computed: {
    canHandleRma() {
      return this.rma.status === 'pending';
    },

    isOrderAwaiting() {
      return this.rma.orderStatus !== 'accepted';
    },

    isLoading() {
      return this.$store.state.rmas.rma.isLoading || this.$store.state.rmas.isLoading;
    },

    readyForFetch() {
      return !this.$store.state.rmas.isLoading;
    },

    isFailed() {
      return this.$store.state.rmas.rma.isFailed;
    },

    isHandling() {
      return this.$store.state.rmas.rma.isHandling;
    },

    isHandlingFailed() {
      return this.$store.state.rmas.rma.isHandlingFailed;
    },

    handlingError() {
      return this.$store.state.rmas.rma.handlingError;
    },

    rma() {
      return this.$store.getters['rmas/rma/rma'];
    },

    items() {
      const rmaData = this.$store.state.rmas.rma.data;

      return rmaData?.returnedPositions.map((item) => ({
        ...item,
        reason: rmaData.reasonForReturning,
      }));
    },
    fetchTriggerByShopId() {
      return this.shopId;
    },
    fetchTriggerByReturnId() {
      return this.returnId;
    },
    isProcessingCurrentRma() { return this.$store.getters['rmas/rma/isProcessingCurrentRma'](this.returnId); },

  },

  watch: {
    fetchTriggerByShopId: 'fetch',
    fetchTriggerByReturnId: 'fetch',
    readyForFetch: 'fetch',
  },
  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      if (this.readyForFetch) {
        this.actionHappened = false;
        this.$store.dispatch('rmas/rma/fetchRmaV2', {
          contextId: this.shopId,
          returnId: this.returnId,
        });
      }
    },

    handleReturnAccept() {
      return this.$store.dispatch('rmas/rma/acceptV2', {
        shopId: this.shopId,
        returnId: this.returnId,
      })
        .then(() => {
          this.fetch();
          this.$emit('changed');
          this.$emit('accepted');
        })
        .catch(() => {})
        .finally(() => {
          this.actionHappened = true;
          this.currentAction = 'acceptance';
        });
    },

    handleReturnDecline() {
      this.displayReturnReason = true;
    },

    handleSubmitReason(data) {
      this.declineReasonForm = data;

      return this.$store.dispatch('rmas/rma/declineV2', {
        shopId: this.shopId,
        returnId: this.returnId,
        reason: data.reason,
      })
        .then(() => {
          this.fetch();
          this.$emit('changed');
          this.$emit('accepted');
        })
        .catch(() => {})
        .finally(() => {
          this.actionHappened = true;
          this.currentAction = 'rejection';
          this.displayReturnReason = false;
        });
    },

    handleTryAgain() {
      if (this.currentAction === 'acceptance') {
        this.handleReturnAccept();
      } else if (this.currentAction === 'rejection') {
        this.handleSubmitReason(this.declineReasonForm);
      }
    },
  },
};
</script>
