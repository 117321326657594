<template>
  <div
    class="header-item"
    :class="getClasses"
  >
    <div class="header-item__icon">
      <slot />
    </div>
    <div class="header-item__text">
      <slot name="label">
        <span class="header-item__label">{{ label }}</span>
      </slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiHeaderItem',
  props: {
    label: {
      type: String,
      default: '',
    },
    active: {
      type: Boolean,
      default: false,
    },
    inactive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    getClasses() {
      return {
        'is-active': this.active,
        'is-inactive': this.inactive,
      };
    },
  },
};
</script>

<style lang="scss">
  .header-item {
    white-space: nowrap;
    height: 60px;
    display: grid;
    grid-template-columns: 80px auto;
    align-items: center;

    &.is-active,
    &:not(.is-inactive):hover {
      background: #f0faff;
      cursor: pointer;

      .header-item {
        &__icon {
          color: #2d8cf0;
        }
      }
    }

    &__icon {
      position: relative;
      text-align: center;
    }

    &__text {
      font-weight: 500;
      line-height: 26px;
    }
  }
</style>
