<template>
  <form class="reasons-list" @submit.prevent="submit">
    <div class="reasons-list__header">
      <div class="reasons-list__title">
        I'm declining the product because...
      </div>
      <div class="reasons-list__subtitle">
        Your answer helps us improve our services
      </div>
    </div>
    <ul class="reasons-list__list">
      <li v-for="reason in reasons" :key="reason.id" class="reasons-list__item">
        <ui-radio-input
          :id="`radio-${reason.id}`"
          v-model="current.id"
          name="reason-choice"
          :value="reason.id"
          class="reasons-list__radio"
        />
        <div class="reasons-list__item-content">
          <label
            :for="`radio-${reason.id}`"
            class="reasons-list__label"
          >
            {{ reason.defaultTranslation }}
          </label>
          <ui-textarea
            v-if="reason.requiresTextAnswer"
            v-show="showTextarea"
            ref="reasonText"
            v-model="current.text"
            :disabled="!showTextarea"
            placeholder="What's your reason?"
          />
        </div>
      </li>
    </ul>
    <div class="reasons-list__actions">
      <ui-button
        class="reasons-list__action-save"
        html-type="submit"
        type="submit"
        :disabled="isInvalid"
      >
        Save
      </ui-button>
      <ui-button class="reasons-list__action-cancel" html-type="button" @click="cancel">
        Cancel
      </ui-button>
    </div>
  </form>
</template>

<script>
import {
  UiButton,
  UiRadioInput,
  UiTextarea,
} from '@ui';

export default {
  components: {
    UiButton,
    UiRadioInput,
    UiTextarea,
  },

  props: {
    reasons: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      current: {
        id: null,
        text: '',
      },
    };
  },

  computed: {
    isInvalid() {
      return !this.current.id || (this.showTextarea && this.currentTextSanitized.length < 2);
    },

    showTextarea() {
      return this.currentReason && this.currentReason.requiresTextAnswer;
    },

    currentReason() {
      return this.reasons.find((reason) => reason.id === this.current.id);
    },

    currentTextSanitized() {
      return this.current.text.trim();
    },
  },

  watch: {
    /**
     * Focus on textarea when proper option selected
     */
    showTextarea: function onTexareaChange(showTextarea) {
      if (showTextarea) {
        this.$nextTick(() => {
          this.$refs.reasonText[0].$el.focus();
        });
      }
    },
  },

  methods: {
    cancel() {
      this.$emit('cancel');
    },

    submit() {
      this.$emit('save', {
        id: this.current.id,
        text: this.currentTextSanitized,
      });
    },
  },
};
</script>

<style lang="scss">
.reasons-list {
  &__header {
    margin-bottom: 10px;
  }

  &__title {
    font-size: 16px;
  }

  &__subtitle {
    font-size: 14px;
    color: #B6B6B6;
  }

  &__list {
    display: block;
    list-style: none;
    margin-bottom: 10px;
  }

  &__item {
    margin: 10px 0;
    padding: 0;
  }

  &__item-content {
    display: block;
    margin-left: 30px;
  }

  &__radio,
  &__label {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 5px;
  }

  &__radio {
    position: absolute;
  }

  &__action-save {
    margin-right: 10px;
  }
}
</style>
