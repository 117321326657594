import { Machine } from 'xstate';

export default Machine(
  {
    id: 'awaiting',
    initial: 'selectPositions',

    states: {
      selectPositions: {
        initial: 'selecting',
        states: {
          selecting: {},
          noSelectedPositionsStatus: {
            on: {
              SELECT_POSITION: {
                target: '#awaiting.selectPositions.selecting',
                actions: 'SELECT_POSITION',
              },
            },
          },
        },
        on: {
          CONTINUE: [
            { cond: 'noSelectedPositionsStatus', target: '.noSelectedPositionsStatus' },
            { target: '#awaiting.updatingOrder' },
          ],

          SELECT_POSITION: {
            actions: 'SELECT_POSITION',
          },

          SET_REASON: {
            actions: 'SET_REASON',
          },
        },
      },

      updatingOrder: {
        initial: 'updating',
        on: {
          BACK: [
            { target: 'selectPositions' },
          ],
        },
        states: {
          updating: {
            invoke: {
              src: 'UPDATE_ORDER',
              onDone: [
                { cond: 'allPositionsAreDeclined', target: '#awaiting.final' }, // can do something else
                { target: '#awaiting.final' },
              ],
              onError: [{
                target: '#awaiting.updatingOrder.error',
              }],
            },
          },
          selecting: {},
          error: {
            initial: 'generic',
            states: {
              generic: {},
            },
          },
        },
      },

      final: {
        type: 'final',
        entry: ['REDIRECT_TO_ORDER'],
      },
    },
  },
);
