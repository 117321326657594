import axios from 'axios';
import { parseSuccessResponse, parseErrorResponse } from '@/helpers';

export default class PcsDataMap {
  constructor(options, interceptors) {
    this.http = axios.create(options);

    if (interceptors && interceptors.request && interceptors.request.length) {
      interceptors.request.map((requestInterceptor) => this.http.interceptors.request.use(
        requestInterceptor.onRequest,
        requestInterceptor.onError,
      ));
    }

    if (interceptors && interceptors.response && interceptors.response.length) {
      interceptors.response.map((requestInterceptor) => this.http.interceptors.response.use(
        requestInterceptor.onResponse,
        requestInterceptor.onError,
      ));
    }
  }

  getDataMap(shopId) {
    return this.http.get(`/productmaps?unifiedLocationId=${shopId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getAliases(shopId) {
    return this.http.get(`locations/${shopId}/aliases`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }
}
