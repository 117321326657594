<template>
  <div v-if="displayAdditionalShopForm" class="remove-shop-form">
    <ui-button
      type="link-blue"
      :active="true"
      :disabled="removingShop"
      @click="removeShop"
    >
      Remove this shop
    </ui-button>
  </div>
</template>

<script>
import {
  UiButton,
} from '@ui';

export default {
  name: 'ShopsRemoveShopForm',
  components: {
    UiButton,
  },

  props: {
    shopId: {
      type: String,
      default: null,
      required: true,
    },
  },

  data() {
    return {
      removingShop: false,
    };
  },

  computed: {
    displayAdditionalShopForm() {
      return this.$store.state.auth.hasAccessToAllStores;
    },
  },

  methods: {
    removeShop() {
      if (this.displayAdditionalShopForm) {
        this.removingShop = true;
        this.$store.dispatch('shops/removeShop', this.shopId)
          .then(() => {
            this.removingShop = false;
            this.redirectToMain();
          })
          .catch(() => {
            this.removingShop = false;
          });
      }
    },

    redirectToMain() {
      const shops = this.$store.getters['shops/list'];

      if (shops.length > 0) {
        this.$router.replace({
          name: 'orders',
          params: {
            shopId: shops[0].id,
          },
        }).catch(() => {});
      } else {
        this.$router.replace({ name: 'shops' });
      }
    },
  },
};
</script>

<style lang="scss">
  .remove-shop-form {
    text-align: center;
  }
</style>
