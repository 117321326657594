<template>
  <div>
    <ivu-modal
      v-model="showModal"
      footer-hide
      width="600"
      :closable="false"
      :mask-closable="false"
    >
      <ui-error
        v-if="showErrorMessage"
        :subtitle="errorMsg"
        show-cancel
        @try-again="handlePrint"
        @cancel="handleCancel"
      />
      <div v-else class="orders-print__modal-title">Printing label, please wait.</div>
    </ivu-modal>
    <div v-if="!autoPrint" class="orders-print">
      <ui-error
        v-if="showErrorMessage"
        class="orders-print__error"
        :subtitle="errorMsg"
        show-cancel
        :vertical="true"
        @try-again="handlePrint"
        @cancel="showErrorMessage = false"
      />
      <div v-else>
        <div class="orders-print__options">
          <div class="orders-print__option">
            <ui-checkbox-input
              value="labels"
              name="documents"
              class="orders-print__checkbox"
              :checked="isDocumentSelected('labels')"
              :disabled="printing"
              @click="handleDocument"
            /> Labels (A6)
          </div>
          <div class="orders-print__option">
            <ui-checkbox-input
              value="info"
              name="documents"
              class="orders-print__checkbox"
              :checked="isDocumentSelected('info')"
              :disabled="printing"
              @click="handleDocument"
            /> Order summary & information
          </div>
        </div>
        <div class="orders-print__actions">
          <ui-button
            type="submit"
            :active="printing"
            :disabled="!selectedDocuments.length || printing"
            @click="handlePrint"
          >
            <template v-if="printing">Printing <span v-if="adblockActive">*</span></template>
            <template v-else>Print <span v-if="adblockActive">*</span></template>
          </ui-button>
          <ui-button
            type="link"
            :disabled="printing"
            @click="handleCancel"
          >
            Cancel
          </ui-button>
        </div>
        <div v-if="adblockActive" class="orders-print__adblock">* Turn off your adblock to open the PDF in a new tab.</div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  UiCheckboxInput,
  UiButton,
  UiError,
} from '@ui';
import {
  b64toBlob,
} from '@/helpers';
import {
  Modal as IvuModal,
} from 'iview';

import adblockDetect from 'adblock-detect';

import { mapState } from 'vuex';

export default {
  components: {
    UiCheckboxInput,
    UiButton,
    UiError,
    IvuModal,
  },

  props: {
    shopId: {
      type: String,
      required: true,
    },
    orderId: {
      type: String,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
    autoPrint: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      adblockActive: false,
      showErrorMessage: false,
      printing: false,
      selectedDocuments: [],
      showModal: this.autoPrint,
    };
  },

  computed: {
    ...mapState('orders/order', {
      errorMsg: 'errorMsg',
    }),

    printOptions() {
      const docs = this.documents;
      const arr = [];
      if (docs.includes('label_shipping') && docs.includes('label_return')) arr.push('labels');
      if (docs.includes('order_info') && docs.includes('return_info')) arr.push('info');
      if (docs.includes('label_shipping') && docs.includes('label_china_shipping')) arr.push('china');
      return arr;
    },
  },

  mounted() {
    this.adblockNotification().then(() => {
      if (this.autoPrint) {
        this.handlePrint();
      }
    });

    this.selectedDocuments = this.printOptions;
  },

  methods: {
    adblockNotification() {
      return new Promise((resolve) => {
        adblockDetect((adblockDetected) => {
          this.adblockActive = adblockDetected;
          resolve();
        });
      });
    },

    handlePrint() {
      this.showErrorMessage = false;
      this.printing = true;
      this.$store.dispatch('orders/order/generateLabel', {
        shopId: this.shopId,
        orderId: this.orderId,
        documents: this.autoPrint ? this.printOptions : this.selectedDocuments,
      }).then((response) => {
        if (response.data && response.data.processData.content) {
          this.printPDF(response.data.processData.content);
        } else {
          this.printing = false;
          this.showErrorMessage = true;
        }
      }).catch(() => {
        this.printing = false;
        this.showErrorMessage = true;
      });
    },

    printPDF(base64) {
      if (base64) {
        const blob = b64toBlob(base64);
        const url = URL.createObjectURL(blob);

        if (this.adblockActive) {
          window.location = url;
        } else {
          window.open(url, '_blank');
        }
      } else {
        this.showErrorMessage = true;
      }

      this.printing = false;
      this.showModal = false;
    },

    handleCancel() {
      this.$emit('cancel');
    },

    handleDocument(value, checked) {
      const pos = this.selectedDocuments.indexOf(value);
      if (checked && pos === -1) {
        this.selectedDocuments.push(value);
      } else if (!checked && pos >= 0) {
        this.selectedDocuments.splice(pos, 1);
      }
    },

    isDocumentSelected(type) {
      return this.selectedDocuments.indexOf(type) !== -1;
    },
  },
};
</script>

<style lang="scss">
.orders-print {
  position: absolute;
  top: 100%;
  left: 10px;
  z-index: 1;
  padding: 15px;
  background-color: #fff;
  box-shadow: 0 5px 10px 3px #999;
  width: 300px;
  border-radius: 3px;
  margin-top: -5px;

  &__options {
    margin-bottom: 25px;
  }

  &__option {
    margin-bottom: 10px;
    display: flex;
    line-height: 25px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__checkbox {
    margin-right: 15px;
  }

  &__actions {
    display: flex;
    justify-content: space-between;
  }

  &__adblock {
    color: #999;
    line-height: 16px;
    margin-top: 20px;
  }

  &__error {
    color: red;
    line-height: 16px;
  }

  &__modal {
    &-title {
      text-align: center;
      font-size: 20px;
      margin: 20px 0;
    }
  }
}
</style>
