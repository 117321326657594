<template>
  <div>
    <ui-tag class="order-tag" :class="classes">
      <slot>{{ text }}</slot>
    </ui-tag>
  </div>
</template>

<script>
import {
  UiTag,
} from '@ui';

export default {
  components: {
    UiTag,
  },

  props: {
    type: {
      type: String,
      default: 'default',
      validator: (type) => (['default', 'pending', 'incomplete', 'premium'].indexOf(type) !== -1),
    },
  },

  data() {
    return {
      contents: {
        default: '',
        pending: 'New Order',
        incomplete: 'Missing track & trace',
        premium: 'VIP',
      },
    };
  },

  computed: {
    classes() {
      return [
        `order-tag--${this.type}`,
      ];
    },

    text() {
      return this.contents[this.type];
    },
  },
};
</script>

<style lang="scss">
.order-tag {
  text-transform: capitalize;

  &--incomplete {
    background: #FFA900;
    color: #fff;
  }

  &--pending {
    background: #B3008F;
    color: #fff;
  }

  &--premium {
    background: #006ef5;
    color: #fff;
  }
}
</style>
