<!-- eslint-disable vue/no-v-html -->
<template>
  <div
    class="ui-error__wrapper"
    :class="classObject"
  >
    <div
      class="ui-error__image-wrapper"
    >
      <img
        class="ui-error__image"
        src="/illustration-error.svg"
        alt=""
      >
    </div>
    <div class="ui-error__content">
      <div class="ui-error__text">
        <p class="ui-error__title">Something went wrong</p>
        <p class="ui-error__subtitle" v-html="subtitle" />
      </div>
      <div class="ui-error__actions">
        <ui-button
          type="submit"
          @click="handleSubmit"
        >
          Try again
        </ui-button>
        <ui-button
          v-if="showCancel"
          type="link-blue"
          @click="handleCancel"
        >
          Cancel
        </ui-button>
      </div>
    </div>
  </div>
</template>

<script>
import UiButton from '../Button/Button.vue';

export default {
  name: 'UiError',
  components: {
    UiButton,
  },

  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    subtitle: {
      type: String,
      default: null,
    },
    showCancel: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classObject() {
      return {
        'ui-error--vertical': this.vertical,
      };
    },
  },

  methods: {
    handleSubmit() {
      this.$emit('try-again');
    },
    handleCancel() {
      this.$emit('cancel');
    },
  },
};
</script>

<style lang="scss">
  .ui-error {
    &__wrapper {
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }

    &__image-wrapper {
      height: 120px;
      margin-right: 20px;
    }

    &__image {
      height: 100px;
      margin: 10px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-height: 120px;
    }

    &__text {
      font-size: 14px;
      color: #3A3A3A;
    }

    &__title {
      font-size: 16px;
      font-weight: bold;
      color: #FF0000;
    }

    &__subtitle {
      padding: 5px 0 15px;
    }

    &__actions {
      display: flex;
      justify-content: space-between;
    }

    &--vertical {
      flex-direction: column;
      width: 230px;
      min-height: 250px;
      margin: 0 auto;

      .ui-error__image-wrapper {
        margin: 0;
      }

      .ui-error__text {
        text-align: center;
      }
    }
  }
</style>
