var render = function render(){var _vm=this,_c=_vm._self._c;return _c('returns-layout-container',{attrs:{"is-loading":_vm.isLoading,"with-details":_vm.withDetails},on:{"rma-changed":_vm.handleRmaChanged}},[_vm._t("tabs",null,{"slot":"tabs"}),_c('router-link',{staticClass:"returns-container__close-link",attrs:{"slot":"close","to":{
      name: 'returns',
      params: {
        shopId: _vm.$route.params.shopId,
      },
      query: _vm.$route.query,
    }},slot:"close"},[_c('ui-icon',{staticClass:"order__close-icon",attrs:{"type":"x"}})],1),_c('returns-nav-bar',{attrs:{"slot":"navBar","status":_vm.filter.status,"total":_vm.total,"offset":_vm.offset,"limit":_vm.limit},on:{"refresh":_vm.refresh,"pagination-change":_vm.onPaginationChange,"filter-status-change":_vm.onFilterStatusChange,"search-uuid":_vm.onSearchUuid},slot:"navBar"}),(_vm.returns.length > 0)?[_c('returns-list',{attrs:{"items":_vm.returns},scopedSlots:_vm._u([{key:"item",fn:function({ item, itemKey, classNames }){return [_c('router-link',{attrs:{"to":{ name: 'rma', params: { shopId: _vm.shopId, returnId: item.id } }},scopedSlots:_vm._u([{key:"default",fn:function({ isActive }){return [_c('returns-list-item',{key:itemKey,attrs:{"item":{ ...item },"class-names":classNames,"id-active":isActive},on:{"navigate":_vm.onClickItem}})]}}],null,true)})]}}],null,false,41609969)})]:[_c('no-rmas-found-message')]],2)
}
var staticRenderFns = []

export { render, staticRenderFns }