<template>
  <Form
    ref="form"
    class="uuid-search"
    :model="model"
    :rules="rules"
    label-position="top"
    @submit.native.prevent="handleSubmit"
  >
    <FormItem prop="Search" class="uuid-search__form-item">
      <ui-text-input
        ref="input"
        v-model="model.Search"
        :value="value"
        class="uuid-search__input"
        name="search"
        placeholder="Search for UUID"
        @input="handleInput"
        @click="inputFocus = true"
        @submit="handleSubmit"
      />
    </FormItem>
    <div class="uuid-search__icon">
      <ui-icon
        ref="search"
        type="search"
        size="17"
        @click="handleSubmit"
      />
    </div>
  </Form>
</template>

<script>
import {
  UiTextInput,
  UiIcon,
} from '@ui';

import { Form, FormItem } from 'iview';

export default {
  name: 'UUIDSearch',
  components: {
    UiTextInput,
    UiIcon,
    // eslint-disable-next-line vue/no-reserved-component-names
    Form,
    FormItem,
  },

  data() {
    const validateUUID = (rule, value, callback) => {
      const uuidRegex = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/i;

      if (value.length < 12) {
        callback(new Error('Please type at least 12 characters)'));
      } else if (value.length > 12 && !uuidRegex.test(value)) {
        callback(new Error('Wrong UUID pattern (XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX)'));
      } else {
        callback();
      }
    };

    return {
      query: null,

      model: {
        Search: this.$route.query.query || null,
      },

      rules: {
        Search: [
          { required: true, message: 'UUID Search can\'t be empty', trigger: 'blur' },
          { validator: validateUUID, trigger: 'change' },
        ],
      },
    };
  },

  methods: {
    handleSubmit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.$router.push({
            path: this.$route.path,
            query: {
              context: 'search',
              query: this.$refs.input.value,
              status: this.$route.query.context === 'awaiting' ? 'pending' : this.$route.query.context,
            },
          }).catch(() => {});
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

  .uuid-search {
    white-space: nowrap;
    display: flex;
    align-items: center;

    &__input {
      display: inline-block;
      font-size: 14px;
    }

    &__icon {
      display: inline-block;
      padding-left: 15px;
      cursor: pointer;
    }

    &__form-item {
      margin-bottom: 0;
    }

    @include for-tablet-portrait-down {
      width: 100%;
      display: grid;
      grid-template-columns: 1fr auto;
      align-items: center;

      input {
        width: 100%;
      }

      &__icon {
        padding: 8px 16px;
      }
    }
  }
</style>
