<template>
  <div class="shipping-form-with-msa">
    <div class="shipping-form-with-msa__section-header">
      <span class="shipping-form-with-msa__header-title">
        <ui-icon
          class="shipping-form-without-msa__warning"
          type="warning"
          size="23"
          color="#FFB200"
        />TRACK &amp; TRACE</span>
      <span class="shipping-form-with-msa__title-copy">All fields are required</span>
    </div>
    <div v-if="providers" class="shipping-form-with-msa__content">
      <form name="shippingDetails" class="shipping-form-with-msa__form" @submit.prevent="onSubmit">
        <div v-if="isLoading">
          <ui-spinner />
        </div>
        <div class="shipping-form-with-msa__section">
          <h4 class="shipping-form-with-msa__section-title">Pickup address</h4>
          <div class="shipping-form-with-msa__header">
            <pickup-address
              :initial-value="selectedPickupPoint"
              :pickup-addresses="pickupPoints"
              @change="onAddressChange"
            />
          </div>
        </div>
        <div class="shipping-form-with-msa__provider-section">
          <providers-list
            :providers="providers"
            :selected-id="selectedProvider"
            @change="onProviderChange"
          />
        </div>
        <div v-if="selectedProvider" class="shipping-form-with-msa__section">
          <h4 class="shipping-form-with-msa__section-title">Type of package</h4>
          <div>
            <package-variants
              :types="packageTypes"
              :selected-id="selectedPackageType"
              @change="onPackageTypeChange"
            />
          </div>
        </div>

        <slot name="errors" />
        <ui-button
          :disabled="!selectedPickupPoint || !selectedProvider || !selectedPackageType"
          type="submit"
          html-type="submit"
        >
          Update
        </ui-button>
        <ui-button
          class="shipping-form-without-msa__section-clear"
          type="link-blue"
          html-type="reset"
          @click="onClear"
        >
          Clear
        </ui-button>
      </form>
    </div>
  </div>
</template>

<script>
import {
  UiIcon,
  UiButton,
  UiSpinner,
} from '@ui';

import PickupAddress from './PickupAddress.vue';
import PackageVariants from './PackageVariants.vue';
import ProvidersList from './ProvidersList.vue';

export default {
  components: {
    UiIcon,
    UiButton,
    UiSpinner,
    PickupAddress,
    PackageVariants,
    ProvidersList,
  },

  props: {
    providers: {
      type: Array,
      default: () => [],
    },

    selectedProvider: {
      type: String,
      default: null,
    },

    pickupPoints: {
      type: Array,
      default: () => [],
    },

    selectedPickupPoint: {
      type: String,
      default: null,
    },

    packageTypes: {
      type: Array,
      default: () => [],
    },

    selectedPackageType: {
      type: String,
      default: null,
    },

    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    onSubmit() {
      this.$emit('save');
    },

    onClear() {
      this.$emit('select-package', null);
    },

    onProviderChange(providerId) {
      this.$emit('select-provider', providerId);
    },

    onPackageTypeChange(typeId) {
      this.$emit('select-package', typeId);
    },

    onAddressChange(address) {
      this.$emit('select-address', address);
    },
  },
};
</script>

<style lang="scss">
.shipping-form-with-msa {
  font-size: 14px;

  &__provider-section {
    margin-bottom: 10px;
  }

  &__section {
    margin-bottom: 30px;
    max-width: 400px;
  }

  &__section-header {
    padding-top: 30px;
    margin-bottom: 30px;
    color: #666666;
  }

  &__warning {
    margin-right: 10px;
  }

  &__header-title {
    margin-right: 20px;
    color: #666666;
    font-weight: bold;
  }

  &__title-copy {
    color: #999999;
  }

  &__section-title {
    margin-bottom: 10px;
    color: #7D7D7D;
    font-weight: normal;
  }

  &__section-clear {
    margin-left: 10px;
  }

  &__form {
    position: relative;
    margin-bottom: 50px;
  }
}
</style>
