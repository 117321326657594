<template>
  <PositionTemplate :position="position" :currency="currency" status="accepted" />
</template>

<script>
import PositionTemplate from './_PositionTemplate.vue';

export default {
  components: {
    PositionTemplate,
  },
  extends: PositionTemplate,
};
</script>
