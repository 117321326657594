import { locationReload } from '@/helpers';

export default (authService, storeContext) => ({
  onRequest(config) {
    if (authService.isAuthenticated()) {
      return config;
    }

    storeContext.dispatch('auth/logout')
      .then(() => {
        locationReload();
      });
    return null;
  },

  onError(error) {
    throw error;
  },
});
