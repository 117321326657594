<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <div>
    <template
      v-if="hasOnePickupAddress"
    >
      {{ firstPickupAddress.address.city }}
      , {{ firstPickupAddress.address.street }}
      , {{ firstPickupAddress.address.country }}
    </template>
    <Select
      v-else
      :value="initialValue"
      placeholder="Select pickup address"
      @on-change="handleChange"
    >
      <Option
        v-for="pickupAddress in pickupAddresses"
        :key="pickupAddress.id"
        :value="pickupAddress.id"
      >
        {{ pickupAddress.address.city }}
        , {{ pickupAddress.address.street }}
        , {{ pickupAddress.address.country }}
      </Option>
    </Select>
  </div>
</template>

<script>
import { Select, Option } from 'iview';

export default {
  components: {
    // eslint-disable-next-line vue/no-reserved-component-names
    Select,
    // eslint-disable-next-line vue/no-reserved-component-names
    Option,
  },

  props: {
    pickupAddresses: {
      type: Array,
      required: true,
    },

    initialValue: {
      type: String,
      default: null,
    },
  },

  computed: {
    hasOnePickupAddress() {
      return this.pickupAddresses.length === 1;
    },
    firstPickupAddress() {
      return this.pickupAddresses[0];
    },
  },

  created() {
    if (this.hasOnePickupAddress) {
      this.handleChange(this.firstPickupAddress.id);
    }
  },

  methods: {
    handleChange(value) {
      this.$emit('change', value);
    },
  },
};
</script>
