<template>
  <div class="orders layout__grow">
    <ui-section>
      <slot slot="header" name="navBar" />
      <slot name="tabs" />

      <orders-layout :with-details="withDetails">
        <ui-spinner-container :is-loading="isLoading" class="layout__grow">
          <template v-if="!isLoading">
            <slot />
          </template>
        </ui-spinner-container>

        <template slot="details">
          <router-view @changed="$emit('rma-changed')">
            <slot slot="close" name="close" />
          </router-view>
        </template>
      </orders-layout>
    </ui-section>
  </div>
</template>

<script>
import {
  UiSection,
  UiSpinnerContainer,
} from '@ui';
import OrdersLayout from '@/modules/Orders/components/OrdersLayout.vue';

export default {
  name: 'LayoutContainer',
  components: {
    UiSection,
    UiSpinnerContainer,
    OrdersLayout,
  },

  props: {
    isLoading: {
      type: Boolean,
      default: false,
    },

    withDetails: {
      type: Boolean,
      default: false,
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.returns-container {
  $order-padding-desktop: 32px;
  $order-padding-mobile: 16px;

  &__close-link {
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    padding: $order-padding-desktop;
    color: #333;
  }

  @include for-tablet-portrait-down {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    &__close-link {
      top: 0;
      right: 0;
      z-index: 2;
      padding: $order-padding-mobile;
    }
  }
}
</style>
