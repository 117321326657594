<template>
  <div class="rma-customer-info">
    <div class="rma-customer-info__section">
      <h3 class="rma-customer-info__title">Client address</h3>
      <p class="rma-customer-info__address">
        {{ customer.name }}<br>
        {{ customer.email }}<br>
        {{ customer.phone }}
      </p>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    customer: {
      type: Object,
      default: () => ({
        email: null,
        name: null,
        phone: null,
      }),
    },
  },
};
</script>

<style lang="scss">
.rma-customer-info {
  display: flex;
  margin-bottom: 32px;

  &__section {
    width: 50%;
  }

  &__title {
    text-transform: uppercase;
    font-size: 14px;
    line-height: 14px;
    margin-bottom: 26px;
    font-weight: 600;
  }

  &__address {
    font-size: 14px;
    line-height: 2em;
  }
}
</style>
