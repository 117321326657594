import { parseSuccessResponse, parseErrorResponse } from '@/helpers';
import ApiClass from '../Api/Api';
import { parseContextToLegacy, CHANNEL_COUNTRY_CODE } from './Context.helpers';

export default class ContextApi extends ApiClass {
  getContext(contextId) {
    if (!contextId) {
      return Promise.reject();
    }

    return this.http.get(`/context/${contextId}`)
      .then(parseSuccessResponse)
      .then(async (response) => {
        const channelId = CHANNEL_COUNTRY_CODE[contextId?.split('-')?.[2]];
        const { data } = await this.getSettings(contextId, channelId);
        return {
          ...response,
          data: parseContextToLegacy({ ...response.data.items[0], systemSettings: data?.locations[0]?.settings }),
        };
      })
      .catch(parseErrorResponse);
  }

  getSettings(contextId, channelId) {
    if (!contextId) {
      return Promise.reject();
    }

    return this.http.get(`/context/${contextId}/channels/${channelId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }
}
