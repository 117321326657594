let dropdown = null;

export default {
  setDropdown(dropdownInstance) {
    dropdown = dropdownInstance;
  },
  clearDropdown() {
    dropdown = null;
  },
  getDropdown() {
    return dropdown;
  },
};
