import apiV2 from '@/apiV2/api';

export const types = {
  RESET: 'RESET',
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILED: 'FETCH_FAILED',
  SELECT_POSITION: 'SELECT_POSITION',
  SELECT_QUANTITY: 'SELECT_QUANTITY',
  CREATE_REQUEST: 'CREATE_REQUEST',
  CREATE_SUCCESS: 'CREATE_SUCCESS',
  CREATE_FAILED: 'CREATE_FAILED',
};

const initialState = () => ({
  isLoading: false,
  isFailed: false,
  data: null,
  errorMsg: null,
  selectedIds: [],
  selectedQuantities: {},
  isCreating: false,
  isCreatingFailed: false,
  creatingErrorMsg: null,
});

const getAsyncRequestResult = async (context, requestId) => {
  context.commit(types.FETCH_REQUEST);

  return new Promise((resolve, reject) => {
    let attempts = 1;
    // eslint-disable-next-line consistent-return
    const fetchRetry = (n) => apiV2(context).asyncOperations.getAsyncOperationResult(requestId).then((response) => {
      if (n === 1) {
        context.commit(types.CREATE_FAILED, 'Your request is queued for processing');
        throw new Error('Something went wrong');
      } else if (response.data.processStatus === 'failure') {
        context.commit(types.CREATE_FAILED, 'Please contact with our support');
        throw new Error('Something went wrong');
      } else if (response.data.processStatus !== 'success') {
        setTimeout(() => {
          attempts++;
          fetchRetry(n - 1);
        }, attempts * 1000);
      } else {
        context.commit(types.CREATE_SUCCESS);
        return resolve(response);
      }
    }).catch((error) => {
      if (n === 1 || error.message === 'Something went wrong') {
        reject(error);
        context.commit(types.CREATE_FAILED, error.message);
      } else {
        setTimeout(() => {
          attempts++;
          fetchRetry(n - 1);
        }, attempts * 1000);
      }
    });
    // eslint-disable-next-line no-promise-executor-return
    return fetchRetry(10);
  });
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    [types.RESET](state) {
      Object.assign(state, initialState());
    },

    [types.FETCH_REQUEST](state) {
      state.isLoading = true;
      state.isFailed = false;
      state.errorMsg = null;
    },

    [types.FETCH_SUCCESS](state, data) {
      state.isLoading = false;
      state.isFailed = false;
      state.data = {
        ...data,
        acceptedPositions: data.acceptedPositions
          .filter((item) => item.quantity > 0)
          .map((position) => ({
            ...position,
            currency: data.currency,
            isSelected: false,
            selectedQuantity: 1,
          })),
      };
    },

    [types.FETCH_FAILED](state, msg) {
      state.isLoading = false;
      state.isFailed = true;
      state.errorMsg = msg;
    },

    [types.SELECT_POSITION](state, { positionId, selected }) {
      state.data = {
        ...state.data,
        acceptedPositions: state.data.acceptedPositions.map((item) => ({
          ...item,
          isSelected: item.id === positionId ? selected : item.isSelected,
        })),
      };
    },

    [types.SELECT_QUANTITY](state, { positionId, quantity }) {
      state.data = {
        ...state.data,
        acceptedPositions: state.data.acceptedPositions.map((item) => ({
          ...item,
          selectedQuantity: item.id === positionId ? quantity : item.selectedQuantity,
        })),
      };
    },

    [types.CREATE_REQUEST](state) {
      state.isCreating = true;
    },

    [types.CREATE_SUCCESS](state) {
      state.isCreating = false;
      state.isCreatingFailed = false;
      state.creatingErrorMsg = null;
    },

    [types.CREATE_FAILED](state, msg) {
      state.isLoading = false;
      state.isCreating = false;
      state.isCreatingFailed = true;
      state.creatingErrorMsg = msg;
    },
  },

  getters: {
    order: (state) => (state.data ? {
      id: state.data.id,
      createdAt: new Date(state.data.createdAt),
      shippingInformation: state.data.shippingInformation,
      currency: state.data.currency,
      parentOrder: state.data.parentOrder,
    } : null),

    positions: (state) => (state.data ? state.data.acceptedPositions : []),

    selectedPositions: (state, getters) => getters.positions
      .filter((item) => item.isSelected)
      .map((item) => ({ positionId: item.id, quantity: item.selectedQuantity })),

    totalRefundValue: (state, getters) => getters.positions
      .reduce((sum, item) => sum + (item.isSelected ? item.selectedQuantity * item.price : 0), 0),
  },

  actions: {
    fetchOrder(context, { shopId, orderId }) {
      context.commit(types.FETCH_REQUEST);

      return apiV2(context).orders.getOrder(shopId, orderId)
        .then((response) => {
          context.commit(types.FETCH_SUCCESS, response.data);
          return response;
        })
        .catch((error) => {
          context.commit(types.FETCH_FAILED, error.msg);
          throw error;
        });
    },

    selectPosition({ commit }, { positionId, selected }) {
      commit(types.SELECT_POSITION, { positionId, selected });
    },

    selectQuantity({ commit }, { positionId, quantity }) {
      commit(types.SELECT_QUANTITY, { positionId, quantity });
    },

    async create(context, { shopId }) {
      context.commit(types.CREATE_REQUEST);
      const positions = context.getters.selectedPositions?.reduce((acc, curr) => {
        const ids = curr?.positionId?.split('|').map((id) => ({ id }));
        return [...acc, ...(ids?.slice(0, curr.quantity) || [])];
      }, []);

      const requestData = await apiV2(context).returns.createRmaRequest(
        shopId,
        positions,
      ).catch((error) => {
        context.commit(types.CREATE_FAILED, error.msg || error.message);
        throw error;
      });
      const result = await getAsyncRequestResult(context, requestData?.data?.requestId);
      context.commit(types.CREATE_SUCCESS, result);
    },
  },
};
