export default {
  data() {
    return {
      filter: {
        status: undefined,
      },
    };
  },

  watch: {
    shopId: {
      immediate: true,
      handler(newValue, oldValue) {
        if (newValue !== oldValue) {
          this.filter.status = this.$route.query?.status === '' ? undefined : this.$route.query?.status;
          if (this.$route.query.offset && this.$route.query.limit) {
            this.limit = this.$route.query.limit;
            this.offset = this.$route.query.offset;
          }
          this.fetch(this.$route.query);
        }
      },
    },

    $route(newValue, oldValue) {
      if (newValue?.query?.status !== oldValue?.query?.status) {
        this.filter.status = newValue?.query?.status;

        this.fetch({
          status: newValue?.query?.status,
          offset: 0,
        });
      } else if ((newValue?.query?.limit !== oldValue?.query?.limit) || (newValue?.query?.offset !== oldValue?.query?.offset)) {
        this.fetch(this.$route.query);
      }
    },
  },

  methods: {
    fetch() {},

    onPaginationChange(data) {
      const newPathArray = this.$route.path.split('/');
      if (this.$route.params?.returnId) newPathArray.pop();
      this.$router.push({
        path: newPathArray.join('/'),
        query: Object.fromEntries(Object.entries({
          status: this.$route?.query?.status,
          offset: data?.offset,
          limit: data?.limit,
          // eslint-disable-next-line
        }).filter(([_, v]) => v !== null && v !== '')),

      });
    },

    onFilterStatusChange(value) {
      const newPathArray = this.$route.path.split('/');
      if (this.$route.params?.returnId || this.$route.params?.rmaRequestId) newPathArray.pop();
      this.$router.push({ path: newPathArray.join('/'), query: value ? { status: value } : {} });
    },

    refresh() {
      this.fetch(this.$route.query);
    },

    onSearchUuid(query) {
      this.fetch({ ...this.$route.query.status, query });
    },
  },
};
