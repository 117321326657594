<template>
  <PositionTemplate :position="position" :currency="currency" status="pending">
    <div slot="status" class="order-position-pending__actions">
      <ui-button
        type="success"
        :active="status === 'accepted'"
        @click="toggleAcceptedStatus"
      >
        <template v-if="status === 'accepted'">Accepted</template>
        <template v-else>Accept</template>
      </ui-button>
      <ui-dropdown
        nowrap
        horizontal-align="right"
        vertical-align="above"
        scroll-context="layout-content"
        body-class="order-position-pending__decline-dropdown"
      >
        <template #anchor="{ toggle, isOpened }">
          <ui-button
            type="danger"
            :active="status === 'declined'"
            :pressed="isOpened"
            @click="toggleDeclinedStatus(toggle, isOpened)"
          >
            <template v-if="status === 'declined'">Declined</template>
            <template v-else>Decline</template>
          </ui-button>
        </template>
        <template #default="{ toggle }">
          <reasons-list-dropdown
            :reasons="declineReasons"
            @cancel="toggle(false)"
            @save="saveDeclineReason($event, toggle)"
          />
        </template>
      </ui-dropdown>
    </div>

    <template slot="stock">
      <div v-if="position.physicalStock !== null" class="order-position-pending__stock">
        <Tooltip content="Last stock synchronization" placement="top">
          Stock: {{ position.physicalStock }}
        </Tooltip>
      </div>
    </template>
  </PositionTemplate>
</template>

<script>
import {
  Tooltip,
} from 'iview';

import {
  UiButton,
  UiDropdown,
} from '@ui';

import ReasonsListDropdown from '@/modules/Order/views/Position/ReasonsListDropdown.vue';
import PositionTemplate from './_PositionTemplate.vue';

export default {
  components: {
    Tooltip,
    PositionTemplate,
    UiButton,
    UiDropdown,
    ReasonsListDropdown,
  },
  extends: PositionTemplate,

  props: {
    status: {
      type: String,
      default: null,
    },
  },

  computed: {
    declineReasons() {
      return this.$store.state.orders.declineReasons;
    },
  },

  methods: {
    toggleAcceptedStatus() {
      if (this.position.newStatus !== 'accepted') {
        this.setStatus('accepted');
      } else {
        this.setStatus(null);
      }
    },

    toggleDeclinedStatus(dropdownToggle, dropdownOpened) {
      if (this.position.newStatus !== 'declined') {
        if (!this.declineReasons || !this.declineReasons.length) {
          // eslint-disable-next-line no-console
          console.warn('Reasons list not loaded. Skipping reject reasons tooltip');

          this.setStatus('declined');
        } else {
          dropdownToggle(!dropdownOpened);
        }
      } else {
        this.setStatus(null);
      }
    },

    setStatus(status) {
      this.$emit('change-status', this.position.id, status);
    },

    setDeclineReason(reason) {
      this.$emit('change-decline-reason', this.position.id, reason);
    },

    saveDeclineReason(reason, dropdownToggle) {
      dropdownToggle(false);
      this.setDeclineReason(reason);
      this.setStatus('declined');
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.order-position-pending {
  &__actions {
    display: flex;
    flex-direction: column;
  }

  &__action {
    width: 120px;

    .ivu-icon {
      margin-top: -3px;
    }
  }

  &__stock {
    margin-bottom: 10px;
    color: #888888;
  }

  &__decline-dropdown {
    width: 400px;
  }

  @include for-tablet-portrait-down {
    &__actions {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-column-gap: 16px;
      width: 100%;
    }

    &__decline-dropdown {
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
    }
  }
}
</style>
