<template>
  <ul class="orders-legend">
    <li class="orders-legend__item">
      <span class="counter-status counter-status--dot counter-status--premium" />
      VIP
      <tooltip
        content="New VIP - Prioritised order"
        placement="right-start"
        max-width="250"
        z-index="100"
      >
        <ui-icon
          type="question"
          size="20"
          color="#999"
        />
      </tooltip>
    </li>
    <li class="orders-legend__item">
      <span class="counter-status counter-status--dot counter-status--awaiting" /> New orders
    </li>
    <li class="orders-legend__item">
      <span class="counter-status counter-status--dot counter-status--incomplete" />Incomplete order
    </li>
  </ul>
</template>

<script>
import UiIcon from '@/ui/Icon/Icon.vue';
import { Tooltip } from 'iview';

export default {
  components: {
    UiIcon,
    Tooltip,
  },
};

</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.orders-legend {
  padding: 0;
  list-style: none;
  text-align: right;
  color: #999;

  &__item {
    display: inline-block;
    margin: 0 10px;
    line-height: 16px;

    &:last-child {
      margin-right: 0;
    }

    &:first-child {
      margin-left: 0;
    }
  }

  @include for-tablet-portrait-down {
    text-align: left;
    white-space: nowrap;

    &__item {
        margin-left: 0;
        margin-right: 10px;
    }
  }
}

.counter-status {
  &--dot {
    vertical-align: middle;
    display: inline-block;
    border-radius: 50%;
    width: 6px;
    height: 6px;
    margin: -2px 12px 0 10px;
  }

  &--awaiting {
    background: #B3008F;
  }

  &--incomplete {
    background: #FFAA1C;
  }

  &--premium {
    background: #006ef5;
  }
}
</style>
