<template>
  <div class="ui-spinner" />
</template>

<script>
export default {
  name: 'UiSpinner',
};
</script>

<style lang="scss">
@keyframes spinner-rotation {
  from{
    transform: rotate(0deg);
  }
  to{
    transform: rotate(360deg);
  }
}

.ui-spinner {
  width: 45px;
  height: 45px;
  border: 6px solid rgba(0,0,0,.15);
  border-top-color: #BBBBBB;
  border-radius: 50%;
  animation: spinner-rotation .8s ease infinite;
}
</style>
