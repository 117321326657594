import flatten from 'flat';

/* eslint-disable */
export const remapValues = (keysMap, values, abortUnsupportedFields) => {
    const flattenValues = flatten(values?.unsupportedFields ? {...values, ...values.unsupportedFields} : values)
    let newFlattenValues = {}
    let unsupportedFields = {}
    
    Object.keys(flattenValues).map((flattenValuesKey) => {
      const formattedKeysMap = Object.keys(keysMap).filter(key => key !== 'null').reduce((acc,key) => {
        if(key.slice(-1) === '*' && keysMap[key]?.slice(-1) === '*') {
            return {
                ...acc,
                [key.slice(0, -1) + flattenValuesKey.split(key.slice(0, -1))[1]]: keysMap[key].slice(0,-1)+flattenValuesKey.split(key.slice(0, -1))[1]
            }
        } else {
            return {
                ...acc,
                [key]: keysMap[key]
            }
        }
      }, {})
      const legacyKeys = Object.keys(formattedKeysMap)

      if(legacyKeys.includes(flattenValuesKey) && formattedKeysMap[flattenValuesKey]) {
        newFlattenValues = {...newFlattenValues, [formattedKeysMap[flattenValuesKey]]: flattenValues[flattenValuesKey] }
      }
      else if(!abortUnsupportedFields) {
        unsupportedFields = {...unsupportedFields, [flattenValuesKey]: flattenValues[flattenValuesKey]}
      }
    })
    
    if(abortUnsupportedFields) {
      return { remappedValues: flatten.unflatten(newFlattenValues) }
    }
    
    return {
      remappedValues: flatten.unflatten(newFlattenValues),
      unsupportedFields: flatten.unflatten(unsupportedFields)
    }
    }
  