<template>
  <div class="order">
    <ui-spinner-container :is-loading="isLoading" class="order__loader">
      <order-details
        v-if="details"
        :key="details.id"
        :order="details"
        :is-shippment-was-not-created="isShippmentWasNotCreated"
        @onBookShipmentFinished="fetchOrder(true)"
      />
      <template v-if="!isLoading && !details">
        <message-template
          icon="/illustration-no-results.svg"
          text="Order does not exist"
        />
      </template>
      <router-link
        class="order__close-link"
        :to="{
          name: 'orders',
          params: {
            shopId: $route.params.shopId,
          },
          query: $route.query,
        }"
      >
        <ui-icon
          v-if="!isLoading"
          type="x"
          class="order__close-icon"
        />
      </router-link>
    </ui-spinner-container>
  </div>
</template>

<script>
import {
  UiSpinnerContainer,
  UiIcon,
} from '@ui';

import { fix, unfix } from '@/helpers/bodyFixer';
import MessageTemplate from '@/modules/Orders/components/Messages/_MessageTemplate.vue';
import OrderDetails from './OrderDetails.vue';

export default {
  name: 'OrderOrderView',
  components: {
    UiSpinnerContainer,
    UiIcon,
    OrderDetails,
    MessageTemplate,
  },

  beforeRouteUpdate(to, from, next) {
    window.scrollTo(0, 0);
    next();
  },

  props: {
    shopId: {
      type: String,
      default: null,
    },

    orderId: {
      type: String,
      default: null,
    },

    orderType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      timeout: null,
      isShippmentWasNotCreated: false,
    };
  },

  computed: {
    isLoading() {
      return this.$store.state.orders.isLoading || !this.$store.state.shops.isInitialized || this.$store.state.orders.order.isLoading;
    },
    isDataLoaded() {
      return this.$store.state.shops.isInitialized && !this.$store.state.orders.isLoading && this.$store.state.orders.list.length;
    },
    details() {
      return this.$store.state.orders.order.data;
    },
    isShippingInProgress() {
      return this.$store.getters['orders/order/isShippingInProgress'];
    },
  },
  watch: {
    $route() {
      this.initialFetchOrder();
    },

    isDataLoaded(value) {
      if (value) this.initialFetchOrder();
    },
  },

  mounted() {
    this.fetchDeclineReasons();
  },

  created() {
    fix();
    this.initialFetchOrder();
  },

  beforeDestroy() {
    unfix();
    this.clearOrder();
  },

  methods: {
    initialFetchOrder() {
      this.$store.dispatch('orders/order/initialFetchOrder', {
        shopId: this.$props.shopId,
        orderId: this.$props.orderId,
        type: this.$props.orderType,
      }).then((response) => {
        if (response?.data) {
          if (response.data.shippingInformation.status === 'in_progress' && !(response.data.shippingInformation.deliveryInfo.trackingNumber && response.data.shippingInformation.deliveryInfo.trackingNumber !== '')) {
            this.isShippmentWasNotCreated = false;
          }
        }
      }).catch(() => {});
    },
    fetchOrder(isBackgroundUpdating = false) {
      if (!isBackgroundUpdating && this.timeout) {
        clearTimeout(this.timeout);
      }
      this.$store.dispatch('orders/order/fetchOrder', {
        shopId: this.$props.shopId,
        orderId: this.$props.orderId,
        type: this.$props.orderType,
        isBackgroundUpdate: isBackgroundUpdating,
      })
        .then((result) => {
          if (result.data.shippingInformation.status === 'in_progress' && !result.data.shippingInformation.deliveryInfo.trackingNumber) {
            clearTimeout(this.timeout);
            this.isShippmentWasNotCreated = false;
            this.timeout = setTimeout(() => {
              this.fetchOrder(true);
            }, 5000);
          } else if (isBackgroundUpdating && result.data.shippingInformation.status === 'not_created' && !result.data.shippingInformation.deliveryInfo.trackingNumber) {
            this.isShippmentWasNotCreated = true;
          }
        })
        .catch(() => {});
    },
    /**
     * Fetch reasons of rejections to handle positions decline process.
     */
    fetchDeclineReasons() {
      this.$store.dispatch('orders/fetchDeclineReasons');
    },

    clearOrder() {
      this.$store.dispatch('orders/order/reset');
    },
  },
};
</script>
<style lang="scss">
  @import '@/styles/mixins/breakpoints.scss';

  .order {
    $order-padding-desktop: 32px;
    $order-padding-mobile: 16px;

    &__loader {
      height: 100%;
    }

    &__close-link {
      position: absolute;
      top: 0;
      right: 0;
      padding: $order-padding-desktop;
      color: #333;
    }

    @include for-tablet-portrait-down {
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;

      &__close-link {
        top: 0;
        right: 0;
        z-index: 2;
        padding: $order-padding-mobile;
      }
    }
  }

</style>
