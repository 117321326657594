/* eslint-disable */

import apiV2 from '@/apiV2/api';

export const types = {
  RESET: 'RESET',
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILED: 'FETCH_FAILED',
  SELECT_PROVIDER: 'SELECT_PROVIDER',
  SET_PROVIDERS: 'SET_PROVIDERS',
};

const initialState = () => ({
  providers: [],
  selectedProvider: null,
  errorMsg: null,
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  getters: {
    packageTypes: (state) => {
      if (state.selectedProvider !== null) {
        return state.providers.find((provider) => provider.id === state.selectedProvider).variants;
      }

      return null;
    },
  },

  mutations: {
    [types.SELECT_PROVIDER](state, payload) {
      state.selectedProvider = payload;
    },

    [types.SET_PROVIDERS](state, payload) {
      state.providers = payload;
    },

    [types.RESET](state) {
      Object.assign(state, initialState());
    },

    [types.FETCH_REQUEST](state) {
      state.errorMsg = null;
    },

    [types.FETCH_SUCCESS]() {

    },

    [types.FETCH_FAILED](state, msg) {
      state.errorMsg = msg;
    },
  },

  actions: {
    init({ commit }, payload) {
      commit(types.SET_PROVIDERS, payload.providers);
    },

    selectProvider({ commit }, payload) {
      commit(types.SELECT_PROVIDER, payload);
    },

    fetchProviders(context, data) {
      context.commit(types.FETCH_REQUEST);

      return apiV2(context).shipping.getShippingProviders(data.shopId, data.positionIds[0])
        .then((response) => {
          const resData = response.data.items.map((item) => ({
            ...item,
            variants: item.variants.map((variant) => ({
              id: variant.id, name: variant.id, price: variant.price.value, currency: variant.price.currency,
            })),
          }));
          const initialProvider = resData.length === 1 ? resData[0] : null;

          context.commit(types.SET_PROVIDERS, resData);

          if (initialProvider !== null) {
            context.dispatch('selectProvider', initialProvider.id);

            if (initialProvider.variants.length === 1) {
              context.dispatch('orders/order/withMSA/selectPackage', initialProvider.variants[0].id, { root: true });
            }
          }

          return resData;
        })
        .catch((error) => {
          context.commit(types.FETCH_FAILED, error.msg);
          throw error;
        });
    },

    reset({ commit }) {
      commit(types.RESET);
    },
  },
};
