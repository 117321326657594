<template>
  <div class="ui-nav-bar" :class="classes">
    <slot />
  </div>
</template>

<script>
export default {
  name: 'UiNavBar',
  props: {
    transparent: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    classes() {
      return {
        'ui-nav-bar--transparent': this.transparent,
      };
    },
  },
};
</script>

<style lang="scss">
.ui-nav-bar {
  background: #fff;
  line-height: 55px;
  display: flex;
  align-items: stretch;

  &--transparent {
    background: transparent;
  }
}
</style>
