<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <div v-if="isLoggedIn" class="user-info">
    <div class="user-info__email">{{ email }}</div>
    <div><a class="user-info__logout" @click="logout">Log out</a></div>
  </div>
</template>

<script>
import {
  locationReload,
} from '@/helpers';

export default {
  name: 'UiHeaderUserInfo',
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    email() {
      return this.$store.state.auth.accessorEmail;
    },
  },
  methods: {
    logout() {
      this.$store.dispatch('auth/logout', { shouldDeleteSession: true }).then(() => {
        locationReload();
      });
    },
  },
};
</script>

<style lang="scss">
  .user-info {
    cursor: initial;
    line-height: initial;

    &__email {
      white-space: nowrap;
      overflow: hidden;
    }

    &__logout {
      color: #666;
    }
  }
</style>
