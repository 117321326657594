<template>
  <div class="header" :class="{ 'is-expanded': isExpanded }">
    <header-mobile
      class="header__mobile"
      :expanded="isExpanded"
      @toggle="toggleMobileMenu"
    >
      <template #title>
        <slot name="title">{{ title }}</slot>
      </template>
    </header-mobile>
    <div class="header__wrapper">
      <div class="header__content">
        <slot />
        <div class="header__bottom">
          <header-item v-if="displayAdditionalShopForm" inactive>
            <add-shop-form slot="label" />
          </header-item>
          <header-item id="beamerButton" label="What's new">
            <ui-icon
              type="notification"
              size="25"
              color="black"
            />
          </header-item>
          <header-item data-gsc-widget="420565" label="Your feedback">
            <ui-icon type="feedback" size="25" color="black" />
          </header-item>
          <header-item class="header__user" inactive>
            <slot name="user">
              <header-user-picture />
              <header-user-info slot="label" />
            </slot>
          </header-item>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { fix, unfix } from '@/helpers/bodyFixer';
import AddShopForm from '@/modules/Shops/components/ShopsAddShopForm.vue';
import HeaderItem from './HeaderItem.vue';
import HeaderUserPicture from './HeaderUserPicture.vue';
import HeaderUserInfo from './HeaderUserInfo.vue';
import HeaderMobile from './HeaderMobile.vue';
import UiIcon from '../Icon/Icon.vue';

export default {
  name: 'UiHeader',
  components: {
    HeaderItem,
    HeaderUserPicture,
    HeaderUserInfo,
    HeaderMobile,
    UiIcon,
    AddShopForm,
  },

  props: {
    title: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      isExpanded: false,
    };
  },

  computed: {
    displayAdditionalShopForm() {
      return this.$store.state.auth.hasAccessToAllStores;
    },
  },

  beforeDestroy() {
    unfix();
    this.toggleMobileMenu(false);
  },
  methods: {
    toggleMobileMenu(expanded) {
      if (expanded) {
        fix();
      } else {
        unfix();
      }

      this.isExpanded = expanded;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';
@import './header-variables.scss';

.header {
  $this: &;

  width: $desktop-base-width;

  &__wrapper {
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 2;
    background: #FFFFFF;
    width: $desktop-base-width;
    transition: width .1s ease;
  }

  &__content {
    width: $desktop-expanded-width; // Make sure content doesnt wrap
    display: flex;
    flex-direction: column;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }

  &__bottom {
    margin-top: auto;
    margin-bottom: 20px;
  }

  &__user {
    border-top: 1px solid #D9DADB;
  }

  .beamer_icon {
    &.active {
      top: 10px;
      left: 45px
    }
  }

  @include for-tablet-landscape-up {
    &__mobile {
      display: none;
    }

    &__wrapper {
      box-shadow: 10px 0 10px -10px rgba(0,0,0,0.1);
    }

    &:hover {
      #{$this}__wrapper {
        width: $desktop-expanded-width;
      }
    }
  }

  @include for-tablet-portrait-down {
    width: auto;

    &__wrapper {
      width: $mobile-base-width;
      top: $mobile-header-height;
      transition: none;
    }

    &__content {
      width: $mobile-expanded-width;
    }

    &.is-expanded {
      #{$this}__wrapper {
        width: $mobile-expanded-width;
      }
    }

  }
}
</style>
