<template>
  <div class="ui-section layout__grow">
    <div class="ui-section__header">
      <slot name="header" />
    </div>
    <div class="ui-section__content layout__grow">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'UiSection',
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.ui-section {
  $ui-section-border-radius: 2px;

  border-radius: $ui-section-border-radius;
  background-color: #fff;
  box-shadow: 0 0 10px 0 #E5E6E8;

  &__header {
    background-color: #fff;
    border-radius: $ui-section-border-radius $ui-section-border-radius 0 0;
    padding: 6px 20px;

    border-bottom: 1px solid #D8D8D8;
  }

  @include for-tablet-portrait-down {
    &__header {
      padding: 8px;
    }
  }
}
</style>
