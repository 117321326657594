import { parseSuccessResponse, parseErrorResponse } from '@/helpers';
import ApiClass from '../Api/Api';

export default class ShippingApi extends ApiClass {
  getShippingProviders(contextId, positionId) {
    return this.http.get(`/context/${contextId}/order-positions/${positionId}/shipping-providers`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  getShippingDocuments(contextId, payload) {
    return this.http.post(`/context/${contextId}/shipping-documents`, payload)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  bookShipping(contextId, payload) {
    return this.http.post(`/context/${contextId}/shippings`, payload)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }

  updateShipping(contextId, payload) {
    return this.http.patch(`/context/${contextId}/shippings`, payload)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }
}
