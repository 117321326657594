import { parseSuccessResponse, parseErrorResponse } from '@/helpers';
import ApiClass from '../Api/Api';

export default class AsyncOperationsApi extends ApiClass {
  getAsyncOperationResult(requestId) {
    return this.http.get(`/requests/${requestId}`)
      .then(parseSuccessResponse)
      .catch(parseErrorResponse);
  }
}
