const initialState = () => ({
  trackingNumber: null,
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    setTrackingNumber(state, payload) {
      state.trackingNumber = payload;
    },

    reset(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    setTrackingNumber({ commit }, payload) {
      commit('setTrackingNumber', payload);
    },

    reset({ commit }) {
      commit('reset');
    },
  },
};
