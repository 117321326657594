<template>
  <div class="add-shop-form">
    <ui-text-input
      v-model="newShopId"
      type="text"
      placeholder="Add shop by ID"
      class="add-shop-form__input"
    />
    <ui-button
      :disabled="!newShopId"
      class="add-shop-form__button"
      @click="addNewShop"
    >
      +
    </ui-button>
  </div>
</template>

<script>

import Button from '../../../ui/Button/Button.vue';
import TextInput from '../../../ui/Input/text.vue';

export default {
  name: 'ShopsAddShopForm',
  components: {
    UiButton: Button,
    UiTextInput: TextInput,
  },

  data() {
    return {
      newShopId: null,
    };
  },

  methods: {
    addNewShop() {
      if (this.newShopId) {
        this.$store.dispatch('shops/addShop', this.newShopId)
          .then((id) => {
            if (id) {
              this.$store.dispatch('auth/saveAdditionalShop', id).then(() => {
                const path = this.$router.resolve({ name: 'orders', params: { shopId: id } });
                this.$router.push(`${path.href}?context=awaiting`).catch(() => {});
              }).catch(() => {});
            }
            this.newShopId = null;
          })
          .catch(() => {
            // TODO: Display error notification (or in API interceptor)
          });
      }
    },
  },
};
</script>

<style lang="scss">
  .add-shop-form {
    display: flex;
    flex-direction: row;

    &__input input {
      width: 100%;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &__button {
      margin: 0;
      padding: 10px;
      border-left: none;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;

      &:disabled {
        border-left: none;
      }
    }
  }
</style>
