<template>
  <ui-search
    ref="search"
    v-model="query"
    @clear="clear()"
    @search="search()"
  />
</template>

<script>
import {
  UiSearch,
} from '@ui';

import {
  mapState,
} from 'vuex';

export default {
  components: {
    UiSearch,
  },

  props: {
    shopId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      query: null,
    };
  },

  computed: {
    ...mapState('orders', {
      currentQuery: (state) => state.searchQuery,
    }),
  },

  watch: {
    currentQuery(query) {
      this.query = query;
    },
  },

  methods: {
    search() {
      if (this.query && this.query.trim().length > 0) {
        this.$router.push({
          name: 'orders',
          params: { shopId: this.shopId },
          query: {
            context: 'search',
            search: this.query,
          },
        }).catch(() => {});
      } else {
        this.clear();
      }
    },

    clear() {
      this.$router.push({
        name: 'orders',
        params: { shopId: this.shopId },
        query: {},
      }).catch(() => {});
      this.query = null;
    },
  },
};
</script>
