<template>
  <div v-if="visiblePositions.length > 0" class="order-positions-base">
    <positions-list :positions="visiblePositions">
      <template
        slot="position"
        slot-scope="{ position }"
      >
        <position-accepted
          v-if="position.status === 'accepted'"
          :position="position"
          :currency="currency"
          :status="position.status"
          class="order-positions-base__position"
        />
      </template>
    </positions-list>
  </div>
</template>

<script>

import PositionsList from './PositionsList.vue';
import PositionAccepted from './Types/PositionAccepted.vue';

export default {
  components: {
    PositionsList,
    PositionAccepted,
  },

  props: {
    accepted: {
      type: Array,
      default: () => [],
    },

    declined: {
      type: Array,
      default: () => [],
    },

    currency: {
      type: String,
      required: true,
    },

    showDeclined: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    declinedPositions() {
      return this.declined.map((position) => ({ status: 'declined', ...position }));
    },

    acceptedPositions() {
      return this.accepted.map((position) => ({ status: 'accepted', ...position }));
    },

    visiblePositions() {
      if (this.showDeclined) {
        return [].concat(this.acceptedPositions, this.declinedPositions);
      }

      return this.acceptedPositions;
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/variables/colors.scss';

.order-positions-base {
  border-top: 1px solid $color-border-1;

  &__position {
    padding: 16px;
  }
}
</style>
