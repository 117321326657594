const initialState = () => ({
  total: 0,
  offset: 0,
  limit: 15,
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    reset(state) {
      Object.assign(state, initialState());
    },

    set(state, data) {
      state.total = data.total;
      state.offset = data.offset;
    },
  },

  actions: {
    set({ commit }, { totalItemCount, offset }) {
      // Response.meta returns totalItemCount and we use total in store
      commit('set', {
        total: totalItemCount,
        offset,
      });
    },
  },
};
