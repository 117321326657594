<!-- eslint-disable vuejs-accessibility/form-control-has-label -->
<template>
  <rma-details-container class="order-rma-request-view">
    <ui-spinner-container :is-loading="isLoading || isCreating">
      <template v-if="order">
        <order-rma-request-header
          :id="order.id"
          :created-date="order.createdAt"
        />

        <rma-details-addresses
          :return-to="returnAddress"
          :customer="customerAddress"
        />

        <rma-details-items-list
          :items="positions"
          :total="totalRefundValue"
          :currency="order.currency"
          total-label="Total refund"
          empty-message="No available product to return"
        >
          <template slot="shipping"><div /></template>
          <template slot="item" slot-scope="{ item }">
            <rma-details-items-item :item="item">
              <template slot="reason"><div /></template>
              <template slot="select">
                <Checkbox
                  :value="item.isSelected"
                  @on-change="handleSelectPosition($event, item.id)"
                />
              </template>
              <template slot="quantity">
                <Select
                  size="small"
                  style="max-width: 60px"
                  :value="item.selectedQuantity"
                  @on-change="handleSelectQuantity($event, item.id)"
                >
                  <Option v-for="n in item.quantity" :key="n" :value="n">{{ n }}</Option>
                </Select>
              </template>
            </rma-details-items-item>
          </template>
        </rma-details-items-list>

        <ui-error
          v-if="creatingHappened && isCreatingFailed"
          :subtitle="creatingErrorMsg"
          :show-cancel="true"
          @try-again="create"
          @cancel="creatingHappened = false"
        />
        <div v-else class="order-rma-request-view__actions">
          <router-link
            :to="{
              name: 'order',
              params: { shopId, orderId },
              query: { context },
            }"
          >
            <ui-button
              slot-scope="{ navigate, href }"
              :href="href"
              type="link-blue"
              :disabled="positions.length === 0"
              @click.native="navigate"
            >
              Discard
            </ui-button>
          </router-link>
          <ui-button
            type="submit"
            :disabled="positions.length === 0"
            @click="create"
          >
            Create RMA Request
          </ui-button>
        </div>
      </template>
    </ui-spinner-container>
  </rma-details-container>
</template>

<script>
import {
  Checkbox,
  Select,
  Option,
} from 'iview';
import {
  UiError,
  UiButton,
  UiSpinnerContainer,
} from '@ui';
import RmaDetailsContainer from '@/modules/Returns/components/Rma/Details/DetailsContainer.vue';
import RmaDetailsAddresses from '@/modules/Returns/components/Rma/Details/Addresses.vue';
import RmaDetailsItemsList from '@/modules/Returns/components/Rma/Details/Items/List.vue';
import RmaDetailsItemsItem from '@/modules/Returns/components/Rma/Details/Items/Item.vue';
import OrderRmaRequestHeader from '../components/RmaRequest/Header.vue';

export default {
  components: {
    Checkbox,
    // eslint-disable-next-line vue/no-reserved-component-names
    Select,
    // eslint-disable-next-line vue/no-reserved-component-names
    Option,
    UiError,
    UiButton,
    UiSpinnerContainer,
    RmaDetailsContainer,
    RmaDetailsAddresses,
    RmaDetailsItemsList,
    RmaDetailsItemsItem,
    OrderRmaRequestHeader,
  },

  props: {
    shopId: {
      type: String,
      default: null,
    },

    orderId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      creatingHappened: false,
    };
  },

  computed: {
    context() {
      return this.$route.query.context;
    },

    isLoading() {
      return this.$store.state.orders.order.rmaRequest.isLoading;
    },

    order() {
      return this.$store.getters['orders/order/rmaRequest/order'];
    },

    selectedPositions() {
      return this.$store.getters['orders/order/rmaRequest/selectedPositions'];
    },

    totalRefundValue() {
      return this.$store.getters['orders/order/rmaRequest/totalRefundValue'];
    },

    positions() {
      return this.$store.getters['orders/order/rmaRequest/positions'].map((position) => ({
        id: position.id,
        amount: position.price,
        color: position.item.color,
        currency: position.currency,
        name: position.item.name,
        positionId: position.id,
        quantity: position.quantity,
        size: position.item.size,
        totalAmount: position.quantity * position.price,
        reason: null,
        img: position.item.imageUrls[0],

        isSelected: position.isSelected,
        selectedQuantity: position.selectedQuantity,
      }));
    },

    returnAddress() {
      const currentShop = this.$store.getters['shops/byId'](this.shopId);

      return currentShop ? {
        ...currentShop.shippingInformation.pickupPoints[0],
        name: currentShop.name,
      } : null;
    },

    customerAddress() {
      return this.order ? this.order.shippingInformation.deliveryAddress : null;
    },

    isCreating() {
      return this.$store.state.orders.order.rmaRequest.isCreating;
    },

    isCreatingFailed() {
      return this.$store.state.orders.order.rmaRequest.isCreatingFailed;
    },

    creatingErrorMsg() {
      return this.$store.state.orders.order.rmaRequest.creatingErrorMsg;
    },
  },

  watch: {
    $route() {
      this.fetch();
    },
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      this.$store.dispatch('orders/order/rmaRequest/fetchOrder', {
        shopId: this.shopId,
        orderId: this.orderId,
        type: 'order',
      }).catch(() => {});
    },

    handleSelectPosition(checked, positionId) {
      this.$store.dispatch('orders/order/rmaRequest/selectPosition', {
        positionId,
        selected: checked,
      });
    },

    handleSelectQuantity(quantity, positionId) {
      this.$store.dispatch('orders/order/rmaRequest/selectQuantity', {
        positionId,
        quantity,
      });
    },

    create() {
      this.$store.dispatch('orders/order/rmaRequest/create', {
        shopId: this.shopId,
      })
        .then(() => {
          this.$router.push({
            name: 'order',
            params: { orderId: this.orderId, shopId: this.shopId },
            query: { context: this.context, messages: ['rmaRequestCreated'] },
          });
        })
        .finally(() => {
          this.creatingHappened = true;
        });
    },
  },
};
</script>

<style lang="scss">
.order-rma-request-view {
  &__actions {
    text-align: right;
  }
}
</style>
