<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div class="message__wrapper">
    <order-message class="message">
      <img slot="icon" :src="icon" />
      <span slot="text">{{ text }}</span>
      <span slot="additional-text">{{ additionalText }}</span>
    </order-message>
  </div>
</template>

<script>
import OrderMessage from '@/modules/Order/components/OrderMessage.vue';

export default {
  name: 'MessageTemplate',
  components: {
    OrderMessage,
  },

  props: {
    icon: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    additionalText: {
      type: String,
      default: null,
    },
  },
};
</script>

<style lang="scss">
  .message {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    margin: auto 0;

    &__wrapper {
      position: relative;
      height: 60vh;
      min-height: 400px;
    }

    .message {
      &__icon {
        font-size: inherit;
      }

      &__text,
      &__text-additional {
        color: #CBD2DF;
        font-style: normal;
      }

      &__text {
        margin: 0 auto;
        font-size: 40px;
      }

      &__text-additional {
        margin: 10px auto 0;
        font-size: 20px;
      }
    }
  }
</style>
