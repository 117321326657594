<template>
  <ui-layout class="layout">
    <the-header />
    <div class="layout__content layout__grow">
      <ui-layout-viewport class="layout__menu">
        <the-menu />
      </ui-layout-viewport>

      <router-view />
    </div>
  </ui-layout>
</template>

<script>
import {
  UiLayout,
  UiLayoutViewport,
} from '@ui';

import TheHeader from '../components/LayoutHeader.vue';
import TheMenu from '../components/LayoutMenu.vue';

export default {
  name: 'LayoutLayoutView',
  components: {
    UiLayout,
    UiLayoutViewport,
    TheHeader,
    TheMenu,
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.layout {
  color: #333333;
  min-height: 100%;
  display: flex;
  flex-direction: row;

  &__content {
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    overflow: hidden;
  }

  &__grow {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__menu {
    background: #fff;
    margin-bottom: 10px;
  }

  @include for-tablet-portrait-down {
    display: block;

    &__menu {
      margin-bottom: 16px;
    }
  }
}
</style>
