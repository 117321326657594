import Vue from 'vue';
import apiV2 from '@/apiV2/api';

const initialState = () => ({
  limit: 5,
  shops: {},
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    fetchNotifications() {
    },

    fetchNotificationSuccess(state, shops) {
      Object.keys(shops).forEach((shopId) => {
        Vue.set(state.shops, shopId, {
          incomplete: shops[shopId].orders,
          pending: shops[shopId].transfers,
          rmas: shops[shopId].rmas,
        });
      });
    },

    decrementPendingCounterForShop(state, shopId, count = 1) {
      if (Object.prototype.hasOwnProperty.call(state.shops, shopId)
        && state.shops[shopId].pending > 0) {
        Vue.set(state.shops, shopId, {
          incomplete: state.shops[shopId].incomplete,
          pending: state.shops[shopId].pending - count,
          rmas: state.shops[shopId].rmas,
        });
      }
    },

    decrementIncompleteCounterForShop(state, shopId, count = 1) {
      if (Object.prototype.hasOwnProperty.call(state.shops, shopId)
      && state.shops[shopId].pending > 0) {
        Vue.set(state.shops, shopId, {
          incomplete: state.shops[shopId].incomplete - count,
          pending: state.shops[shopId].pending,
          rmas: state.shops[shopId].rmas,
        });
      }
    },
  },

  getters: {
    overflowCount: (state) => state.limit,
    countByShop(state) {
      return (shopId) => state.shops[shopId] || { pending: 0, incomplete: 0, rmas: 0 };
    },

    totalCount(state) {
      return Object.values(state.shops).reduce((reducer, shop) => ({
        pending: reducer.pending + shop.pending,
        incomplete: reducer.incomplete + shop.incomplete,
        rmas: reducer.rmas + shop.rmas,
      }), {
        pending: 0,
        incomplete: 0,
        rmas: 0,
      });
    },
  },

  actions: {
    reset({ commit }) {
      commit('reset');
    },

    decrementPendingCounter({ commit }, shopId) {
      commit('decrementPendingCounterForShop', shopId);
    },

    decrementIncompleteCounter({ commit }, shopId) {
      commit('decrementIncompleteCounterForShop', shopId);
    },

    fetchAll(context, shopsIds) {
      context.commit('fetchNotifications', shopsIds);
      return apiV2(context)
        .statistics.getStatistics(shopsIds)
        .then((response) => {
          const mappedData = response.data.items?.reduce((acc, curr) => ({
            ...acc,
            [curr.locationId]: {
              'rmas': curr.orders.returns,
              'orders': curr.orders.accepted,
              'transfers': curr.orders.pending,
            },
          }), {});
          context.commit('fetchNotificationSuccess', mappedData);
          return response;
        });
    },
  },
};
