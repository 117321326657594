<template>
  <div v-if="positions.length > 0" class="pending-positions-list">
    <div class="pending-positions-list__header">
      <div class="pending-positions-list__copy">
        {{ getCopy() }}
      </div>
      <ui-button
        type="submit"
        :disabled="!canContinue"
        @click="save"
      >
        {{ allDeclinedButtonCopy }}
      </ui-button>
    </div>
    <div v-if="positions">
      <positions-list :positions="positions">
        <template slot="position" slot-scope="{ position }">
          <position-pending
            :position="position"
            :currency="currency"
            :status="position.newStatus"
            class="pending-positions-list__position"
            @change-status="handleChangeStatus"
            @change-decline-reason="handleChangeDeclineReason"
          />
        </template>
      </positions-list>
    </div>
  </div>
</template>

<script>
import {
  UiButton,
} from '@ui';

import PositionsList from './PositionsList.vue';
import PositionPending from './Types/PositionPending.vue';

export default {
  components: {
    UiButton,
    PositionsList,
    PositionPending,
  },

  props: {
    positions: {
      type: Array,
      required: true,
    },

    currency: {
      type: String,
      required: true,
    },

    canContinue: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      accepted: 0,
      declined: 0,
    };
  },

  computed: {
    allDeclinedButtonCopy() {
      return (this.declined === this.positions.length ? 'Reject order' : 'Accept order');
    },
  },

  methods: {
    handleChangeStatus(positionId, newStatus) {
      this.$emit('select', { positionId, newStatus });
      this.positionsStatus();
    },

    handleChangeDeclineReason(positionId, reason) {
      this.$emit('set-decline-reason', { positionId, reason });
    },

    positionsStatus() {
      let accepted = 0;
      let declined = 0;

      this.positions.forEach((position) => {
        if (position.newStatus === 'accepted') {
          accepted += 1;
        } else if (position.newStatus === 'declined') {
          declined += 1;
        }
      });

      this.accepted = accepted;
      this.declined = declined;
    },

    getCopy() {
      if (this.declined === this.positions.length) return 'All products declined. You may pay a comission for rejected orders.';
      if (this.accepted === this.positions.length) return `${this.accepted + (this.accepted > 1 ? ' products ' : ' product ')}accepted`;
      if (this.accepted + this.declined !== this.positions.length) return 'Please decide about all products to continue';
      if (this.accepted + this.declined === this.positions.length) {
        return `${this.accepted + (this.accepted > 1 ? ' products ' : ' product ')}accepted, ${this.declined}${this.declined > 1 ? ' products ' : ' product '}declined.`;
      }

      return '';
    },

    save() {
      this.$emit('continue');
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';
@import '@/styles/variables/colors.scss';

.pending-positions-list {
  &:hover {
    box-shadow: none;
  }

  &__header,
  &__position {
    padding: 16px;
  }

  &__header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    border-bottom: 1px solid $color-border-1;
  }

  &__copy {
    margin-right: 40px;
  }

  @include for-tablet-portrait-down {
    &__header,
    &__position {
      padding: 16px 0;
    }

    &__header {
      flex-direction: column;
      align-items: stretch;
    }

    &__copy {
      margin-bottom: 16px;
    }
  }
}
</style>
