<template>
  <table class="billing-information">
    <tr>
      <td class="billing-information__label">Name</td>
      <td class="billing-information__value">{{ billing.name }}</td>
    </tr>
    <tr v-if="billing.address">
      <td class="billing-information__label">Address</td>
      <td class="billing-information__value">{{ billing.address.street }}, {{ billing.address.postcode }}, {{ billing.address.city }}, {{ billing.address.country }}</td>
    </tr>
    <tr v-if="billing.email">
      <td class="billing-information__label">Email</td>
      <td class="billing-information__value">{{ billing.email }}</td>
    </tr>
    <tr v-if="billing.phone">
      <td class="billing-information__label">Phone</td>
      <td class="billing-information__value">{{ billing.phone }}</td>
    </tr>
  </table>
</template>
<script>
export default {
  props: {
    billing: {
      type: Object,
      default: null,
    },
  },
};
</script>
<style lang="scss">
  .billing-information {
    width: 100%;

    &__label {
      width: 70px;
      vertical-align: top;
      padding-top: 5px;
      color: #545454;
    }

    &__value {
      padding-top: 5px;
      color: #333333;
      word-break: break-all;
    }
  }
</style>
