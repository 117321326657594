import Vue from 'vue';

const initialState = () => ({
  positions: [],
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  getters: {
    allPositionsHaveStatus: (state) => state.positions.every((position) => position.newStatus),
    allPositionsAreDeclined: (state) => state.positions.every((position) => position.newStatus === 'declined'),
    newPositionsPayload: (state) => {
      const newPositions = {
        acceptedPositions: [],
        declinedPositions: [],
      };

      state.positions.forEach((position) => {
        if (position.newStatus === 'accepted') {
          newPositions.acceptedPositions.push({ id: position.id });
        } else if (position.newStatus === 'declined') {
          const reason = position.declineReason || {};

          newPositions.declinedPositions.push(
            { id: position.id, ...reason, declineReasonMiintoId: position.item.miintoItemId },
          );
        }
      });

      return newPositions;
    },
  },

  mutations: {
    setPositions(state, positions) {
      state.positions = positions.map((position) => ({ ...position, newStatus: null }));
    },

    selectPosition(state, payload) {
      const position = state.positions.find((pos) => pos.id === payload.positionId);

      position.newStatus = payload.newStatus;
    },

    setDeclineReason(state, payload) {
      const position = state.positions.find((pos) => pos.id === payload.positionId);
      const reason = {
        declineReasonCode: payload.reason.id,
      };

      if (payload.reason.text) {
        reason.declineReasonText = payload.reason.text;
      }

      /**
       * Use Vue.set to ensure property reactivity
       * and auto-refreshing computed properties.
       */
      Vue.set(position, 'declineReason', reason);
    },

    reset(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    init({ commit }, payload) {
      commit('setPositions', payload.positions);
    },

    selectPosition({ commit }, payload) {
      commit('selectPosition', payload);
    },

    setDeclineReason({ commit }, payload) {
      commit('setDeclineReason', payload);
    },

    reset({ commit }) {
      commit('reset');
    },
  },
};
