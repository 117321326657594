<template>
  <div class="returns-list-item" :class="classNames">
    <div class="returns-list-col returns-list-col--return-id">{{ item.groupId }} || {{ parsedReturnId }}</div>
    <div class="returns-list-col returns-list-col--order-id">{{ item.parentOrderId }} || {{ parsedOrderId }}</div>
    <div class="returns-list-col returns-list-col--date"><template v-if="item.createdDatetime">{{ item.createdDatetime | date() }}</template></div>
    <div class="returns-list-col returns-list-col--quantity">{{ item.quantity }}</div>
    <div class="returns-list-col returns-list-col--total"><template v-if="item.amount !== null && item.currency">{{ item.amount | price(item.currency) }}</template></div>
    <div class="returns-list-col returns-list-col--status"><return-status-badge v-if="item.status" :status="item.status" /></div>
    <div class="returns-list-col returns-list-col--actions"><a :href="item.url" @click.prevent="$emit('navigate', item.id)">View</a></div>
  </div>
</template>

<script>
import DateFilter from '@/logic/filters/date';
import PriceFilter from '@/logic/filters/price';
import { getOrderIdFromUUID, getReturnIdfromUUID } from '@/apiV2/api/Context/Context.helpers';
import ReturnStatusBadge from './ReturnStatusBadge.vue';

export default {
  filters: {
    date: DateFilter,
    price: PriceFilter,
  },

  components: {
    ReturnStatusBadge,
  },

  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({
        rmaId: null,
        positionId: null,
        parentOrderId: null,
        createdDatetime: null,
        quntity: null,
        amount: null,
        currency: null,
        status: null,
        url: null,
      }),
    },

    classNames: {
      type: [String, Array, Object],
      default: null,
    },

    isActive: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    parsedReturnId() {
      return getReturnIdfromUUID(this.item.groupId);
    },
    parsedOrderId() {
      return getOrderIdFromUUID(this.item.parentOrderId);
    },
  },
};
</script>

<style lang="scss">
@import './table.scss';
@import './columns.scss';

.returns-list-item {
  @include row();
  min-height: 68px;
}
</style>
