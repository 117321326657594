<template>
  <div class="order-item" :class="classes">
    <router-link
      :to="{ name: routeTarget, params: { shopId: shopId, orderId: order.id }, query: urlQuery }"
      class="order-item__link"
      active-class="is-active"
    >
      <div class="order-item__col order-item__col-select">
        <ui-radio-input
          :value="order.id"
          :checked="checked"
          :disabled="disableSelectingItem"
          name="selectedOrder"
          @click="handleChecked"
        />
      </div>
      <div class="order-item__col order-item__col-value">{{ value | price(order.currency) }}</div>
      <div v-if="isSearchContext" class="order-item__col order-item__col-value">
        <order-tag>{{ order.status }}</order-tag>
      </div>
      <div class="order-item__col order-item__col-date">{{ order.createdAt | date() }}</div>
      <div class="order-item__col order-item__col-id"><span class=" order-item__id-symbol">ID</span> {{ order.parentOrder.id }} || {{ parsedOrderId }}</div>
      <div class="order-item__col order-item__col-items">
        <ui-thumbnail
          v-for="(imageUrl, index) in thumbnails.images"
          :key="index"
          :src="imageUrl"
        />
        <div
          v-if="thumbnails.counter"
          class="order-item__thumbnail-counter"
        >
          + {{ thumbnails.counter }}
        </div>
      </div>
      <div
        v-if="extraInfo"
        class="order-item__col order-item__col-tags"
      >
        {{ extraInfo }}
      </div>
      <div class="order-item__col order-item__col-status">
        <order-tag v-if="orderTag" :type="orderTag" />
        <order-tag v-if="isPremium" type="premium" />
      </div>
    </router-link>
  </div>
</template>

<script>
import {
  UiRadioInput,
  UiThumbnail,
} from '@ui';

import DateFilter from '@/logic/filters/date';
import PriceFilter from '@/logic/filters/price';
import OrderTag from '@/modules/Order/components/OrderTag.vue';
import { getOrderIdFromUUID } from '@/apiV2/api/Context/Context.helpers';

export default {

  filters: {
    date: DateFilter,
    price: PriceFilter,
  },

  components: {
    UiRadioInput,
    OrderTag,
    UiThumbnail,
  },

  props: {
    order: {
      type: Object,
      default: () => {},
    },

    shopId: {
      type: String,
      required: true,
    },

    urlQuery: {
      type: Object,
      default: () => {},
    },

    checked: {
      type: Boolean,
      default: false,
    },

    context: {
      type: String,
      default: null,
    },
  },

  computed: {
    classes() {
      return {
        'order-item--type-pending': this.isPending,
        'order-item--type-incomplete': this.isIncomplete,
      };
    },

    routeTarget() {
      return this.order.type || 'order';
    },

    isPremium() {
      return !!this.order.shippingInformation.deliveryInfo.isPremium;
    },

    orderTag() {
      if (this.isPending) return 'pending';
      if (this.isIncomplete) return 'incomplete';
      return null;
    },

    isPending() {
      return this.order.status === 'pending';
    },

    isIncomplete() {
      // @todo move to request handler. The same as calculation of order.type
      return this.order.status === 'accepted' && !this.order.shippingInformation.deliveryInfo.trackingNumber;
    },

    value() {
      let value = 0;
      if (this.order.status === 'pending') {
        this.order.pendingPositions.forEach((position) => {
          value += position.price * position.quantity;
        });
      } else if (this.order.status === 'accepted') {
        this.order.acceptedPositions.forEach((position) => {
          value += position.price * position.quantity;
        });
      }
      return value;
    },

    parsedOrderId() {
      return getOrderIdFromUUID(this.order.parentOrder.id);
    },

    thumbnails() {
      const images = [];

      if (this.order.status) {
        const positionType = `${this.order.status}Positions`;
        if (this.order[positionType] && this.order[positionType].length > 0) {
          this.order[positionType].forEach((position) => {
            if (position.item && position.item.imageUrls && position.item.imageUrls.length > 0) {
              images.push(position.item.imageUrls[0]);
            }
          });
        }
      }

      const len = images.length;
      return {
        images: images.slice(0, 2),
        counter: len > 2 ? len - 2 : 0,
      };
    },

    disableSelectingItem() {
      return this.context === 'awaiting' || (this.context === 'search' && this.isPending);
    },

    isSearchContext() {
      return this.context === 'search';
    },

    extraInfo() {
      const info = [];
      if (this.order.shippingInformation.deliveryInfo.isInternational) {
        info.push('International');
      }
      return info.join(', ');
    },
  },

  methods: {
    handleChecked(value) {
      this.$emit('checked', value);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.order-item {
  position: relative;

  // indicator on left edge
  &:before {
    content: '';
    position: absolute;
    width: 2px;
    left: 0;
    top: 0;
    bottom: 0;
    background: transparent;
  }

  &__link {
    display: flex;
    justify-content: flex-start;
    min-height: 80px;
    align-items: center;
    color: inherit;

    &:hover {
      color: inherit;
    }

    &.is-active {
      background: #F2F9FF;
    }
  }

  &__col {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: auto;
    line-height: 20px;
    padding: 16px 25px;
  }

  &__col-select {
    width: 67px;
    padding-right: 0;
  }

  &__col-value {
    width: 150px;
    padding: 16px 0;
    font-size: 20px;
    font-weight: 500;
    text-align: left;
  }

  &__col-date {
    width: 180px;
  }

  &__col-items {
    display: flex;
    width: 229px;
    height: 100%;
    padding: 0;
  }

  &__col-status {
    margin-left: auto;
    & > div {
      display: inline-block;
      &:not(:first-child) {
        margin-left: 10px;
      }
    }
  }

  &__thumbnail-counter {
    display: inline-block;
    margin: 8px 0 0 5px;
    height: 65px;
    line-height: 65px;
  }

  &__col-tags {
    flex-grow: 1;
  }

  &__col-id {
    width: 180px;
    text-align: left;
  }

  &__id-symbol {
    font-weight: bold;
    color: #BBBBBB;
    margin-right: 5px;
  }

  &--type-pending {
    &:before{
      background: #B3008F;
    }
  }

  &--type-incomplete {
    &:before {
      background: #F68D33;
    }
  }

  @include for-tablet-landscape-down {
    &__col {
      padding: 16px 10px;
    }

    &__col-select {
      width: 50px;
    }

    &__col-date {
      width: 140px;
    }

    &__col-items {
      width: 180px;
    }

    &__col-id {
      width: 140px;
    }
  }

  @include for-tablet-portrait-down {
    padding: 16px;

    &__col-select {
      display: none;
    }

    &__link {
      display: block;
    }

    &__col-value {
      display: block;
      margin-bottom: 10px;
      padding: 0;
    }

    &__col-date {
      display: inline-block;
      width: auto;
      padding: 0;
      margin-right: 10px;
    }

    &__col-tags {
      padding-left: 0;
    }

    &__col-id {
      display: inline-block;
      width: auto;
      padding: 0;
    }

    &__col-status {
      padding: 0;
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
    }
  }
}
</style>
