import Vue from 'vue';
import Router from 'vue-router';
import auth from '@/auth';
import Login from './modules/Login/views/Login.vue';
import Shops from './modules/Shops/views/Shops.vue';
import Shop from './modules/Shop/views/Shop.vue';
import Orders from './modules/Orders/views/Orders.vue';
import Order from './modules/Order/views/Order.vue';
import Rmas from './modules/Returns/views/Rmas.vue';
import Rma from './modules/Returns/views/Rma.vue';
import RmaRequests from './modules/Returns/views/RmaRequests.vue';
import RmaRequest from './modules/Returns/views/RmaRequest.vue';
import SearchForm from './modules/Orders/views/SearchForm.vue';
import Layout from './modules/Layout/views/Layout.vue';
import OrdersLegend from './modules/Orders/components/OrdersLegend.vue';
import Tabs from './modules/Shop/views/Tabs.vue';
import OrderRmaRequest from './modules/Order/views/RmaRequest.vue';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkActiveClass: 'is-active',
  linkExactActiveClass: 'is-exact-active',
  routes: [
    {
      path: '/login',
      name: 'login',
      component: Login,
      props: (route) => ({
        nextUrl: route.query.nextUrl,
      }),
    },
    {
      path: '',
      component: Layout,
      meta: {
        requiresAuth: true,
      },
      children: [
        {
          path: '/',
          component: Shops,
        },
        {
          path: '/shops',
          component: Shops,
          name: 'shops',
          children: [
            {
              path: ':shopId',
              components: {
                default: Shop,
                search: SearchForm,
              },
              name: 'shop',
              props: {
                search: (route) => ({
                  shopId: route.params.shopId.toString(),
                }),
              },

              children: [
                {
                  path: 'orders',
                  components: {
                    default: Orders,
                    tabs: Tabs,
                    tabsRight: OrdersLegend,
                  },
                  props: {
                    default: (route) => ({
                      shopId: route.params.shopId.toString(),
                      context: route.query.context || 'awaiting',
                      search: route.query.search,
                    }),
                    tabs: (route) => ({
                      shopId: route.params.shopId.toString(),
                      context: route.query.context || 'awaiting',
                      search: route.query.search,
                    }),
                  },
                  name: 'orders',

                  children: [
                    {
                      path: ':orderId',
                      component: Order,
                      name: 'order',
                      props: (route) => ({
                        shopId: route.params.shopId.toString(),
                        orderId: route.params.orderId.toString(),
                        orderType: 'order',
                      }),
                    },
                    {
                      path: ':orderId/transfer',
                      component: Order,
                      name: 'transfer',
                      props: (route) => ({
                        shopId: route.params.shopId.toString(),
                        orderId: route.params.orderId.toString(),
                        orderType: 'transfer',
                      }),
                    },
                    {
                      path: ':orderId/rma-request',
                      name: 'order-rma-request',
                      component: OrderRmaRequest,
                      props: (route) => ({
                        shopId: route.params.shopId.toString(),
                        orderId: route.params.orderId.toString(),
                        orderType: 'order',
                      }),
                    },
                  ],
                },
                {
                  path: 'returns',
                  components: {
                    default: Rmas,
                    tabs: Tabs,
                  },
                  props: {
                    default: (route) => ({
                      shopId: route.params.shopId.toString(),
                    }),
                    tabs: (route) => ({
                      shopId: route.params.shopId.toString(),
                      context: route.query.context || 'awaiting',
                      search: route.query.search,
                    }),
                  },
                  name: 'returns',

                  children: [
                    {
                      path: ':returnId',
                      name: 'rma',
                      components: {
                        default: Rma,
                      },
                      props: {
                        default: (route) => ({
                          returnId: route.params.returnId.toString(),
                          shopId: route.params.shopId.toString(),
                        }),
                      },
                    },
                  ],
                },
                {
                  path: 'rma-requests',
                  components: {
                    default: RmaRequests,
                    tabs: Tabs,
                  },
                  props: {
                    default: (route) => ({
                      shopId: route.params.shopId.toString(),
                    }),
                    tabs: (route) => ({
                      shopId: route.params.shopId.toString(),
                      context: route.query.context || 'awaiting',
                      search: route.query.search,
                    }),
                  },
                  name: 'rma-requests',

                  children: [
                    {
                      path: ':rmaRequestId',
                      name: 'rmaRequest',
                      components: {
                        default: RmaRequest,
                      },
                      props: {
                        default: (route) => ({
                          rmaRequestId: route.params.rmaRequestId.toString(),
                          shopId: route.params.shopId.toString(),
                        }),
                      },
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ],

  beforeEach: () => { },
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (auth.isAuthenticated()) {
      next();
    } else {
      auth.destroyChannel();
      const fullPath = to.fullPath === '/' ? `${to.fullPath}shops` : to.fullPath;
      next({ name: 'login', query: { nextUrl: fullPath } });
    }
  } else {
    next();
  }
});

export default router;
