<template>
  <order-incomplete
    v-if="orderTabStatus === 'incomplete'"
    :key="order.id"
    :order="order"
    :shop-id="shopId"
    :is-shippment-was-not-created="isShippmentWasNotCreated"
    @onBookFinished="$emit('onBookShipmentFinished')"
  />
  <component
    :is="orderDetailsComponent"
    v-else
    :order="order"
    :shop-id="shopId"
  />
</template>

<script>
import OrderAccepted from './OrderDetails/OrderAccepted.vue';
import OrderAwaiting from './OrderDetails/OrderAwaiting.vue';
import OrderDeclined from './OrderDetails/OrderDeclined.vue';
import OrderIncomplete from './OrderDetails/OrderIncomplete.vue';

export default {
  components: { OrderIncomplete },
  props: {
    order: {
      type: Object,
      required: true,
    },
    isShippmentWasNotCreated: {
      type: Boolean,
    },
  },

  data() {
    return {
      orderDetailsComponent: null,
    };
  },

  computed: {
    shopId() {
      return this.$route.params.shopId;
    },

    trackingNumber() {
      return this.order.shippingInformation.deliveryInfo.trackingNumber;
    },

    orderTabStatus() {
      let status;

      if (this.order.status === 'pending') {
        status = 'awaiting';
      } else if (this.order.status === 'accepted' && !this.trackingNumber) {
        status = 'incomplete';
      } else if (this.order.status === 'accepted' && !!this.trackingNumber) {
        status = 'accepted';
      } else if (this.order.status === 'declined') {
        status = 'declined';
      }

      return status;
    },
  },

  created() {
    switch (this.orderTabStatus) {
      case 'awaiting':
        this.orderDetailsComponent = OrderAwaiting;
        break;
      case 'accepted':
        this.orderDetailsComponent = OrderAccepted;
        break;
      case 'declined':
        this.orderDetailsComponent = OrderDeclined;
        break;
      default:
        this.orderDetailsComponent = OrderAccepted;
        break;
    }
  },
};
</script>
