<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div class="shipping-provider-label">
    <span
      v-if="providerName"
      class="shipping-provider-label__text"
      :title="providerName"
    >
      {{ providerName }}
    </span>
    <div class="shipping-provider-label__image-box">
      <img
        class="shipping-provider-label__image"
        :src="iconPath"
      >
    </div>
  </div>
</template>

<script>
import { shippingProvidersMap } from '@/const';

export default {
  name: 'ProvidersListLabel',
  props: {
    providerId: {
      type: String,
      required: true,
    },
    providerName: {
      type: String,
      default: null,
    },
  },

  created() {
    this.iconPath = shippingProvidersMap[this.providerId] ? `/img/shipping-providers/${shippingProvidersMap[this.providerId]}.png` : '/img/shipping-providers/default_provider.png';
  },
};
</script>

<style lang="scss">
.shipping-provider-label {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    justify-content: center;
    height: 100%;

    &__image-box {
      margin-top: 10px;
    }

    &__image {
      max-width: 80px;
      max-height: 40px;
    }

    &__text {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: normal;
      align-self: flex-start;
    }
  }
</style>
