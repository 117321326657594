<template>
  <div class="orders-layout layout__grow" :class="containerClasses">
    <div class="orders-layout__wrapper" :class="wrapperClasses">
      <div class="orders-layout__list" :class="wrapperClasses">
        <slot />
      </div>

      <div class="orders-layout__details">
        <div id="layout-content" class="orders-layout__details-content">
          <slot name="details" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    withDetails: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    containerClasses() {
      return {
        'orders-layout--with-details': this.withDetails,
        'orders-layout--is-loading': !this.withDetails && this.isLoading,
      };
    },
    wrapperClasses() {
      return {
        layout__grow: !this.withDetails && this.isLoading,
      };
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.orders-layout {
  $this: &;
  $orders-layout-list-width: 392px;
  $orders-layout-list-width-laptop: 340px;
  $orders-layout-duration: .3s;
  display: block;
  position: relative;

  &__wrapper {
    display: flex;
    align-items: stretch;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__list {
    width: 100%;
    flex-shrink: 0;
    overflow: scroll;
  }

  &__details {
    flex-grow: 1;
    flex-basis: 0;
    background: #fff;
    transition: all linear $orders-layout-duration;
    overflow: scroll;

  }

  &__details-content {
    opacity: 0;
    transform: scale(0);
    transition: opacity linear $orders-layout-duration calc($orders-layout-duration / 2), transform linear calc($orders-layout-duration / 2);
    overflow: scroll;
  }

  &--with-details {
    #{$this} {
      &__list {
        .is-loading {
          width: $orders-layout-list-width;
          height: 100%;
        }
      }

      &__details {
        opacity: 1;
        margin-left: calc(-100% + #{$orders-layout-list-width});
        transform: translateX(0px);
        border-left: 1px solid #DADBDB;
      }

      &__details-content {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  &--is-loading {
    #{$this} {
      &__details {
        display: none;
      }
    }
  }

  @include for-tablet-landscape-down {
    &--with-details {
      #{$this} {
        &__details {
          margin-left: calc(-100% + #{$orders-layout-list-width-laptop});
        }
      }
    }
  }
  @include for-tablet-portrait-down {
    &__wrapper {
      position: static;
    }

    &--with-details {
      #{$this}__details {
        display: block;
        margin: 0;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      #{$this}__details-content {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
      }

      #{$this}__details-content {
        transition: none;
      }
    }
  }
}
</style>
