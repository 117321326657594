import apiV2 from '@/apiV2/api';

import pagination from '@/store/pagination';
import rmaRequest from '@/store/rmaRequests/rmaRequest';

export const types = {
  RESET: 'RESET',
  FETCH_REQUEST: 'RMA_REQUESTS_LIST/FETCH_REQUEST',
  FETCH_SUCCESS: 'RMA_REQUESTS_LIST/FETCH_SUCCESS',
  FETCH_FAILED: 'RMA_REQUESTS_LIST/FETCH_FAILED',
};

const initialState = () => ({
  isLoading: false,
  isFailed: false,
  errorMsg: null,
  list: [],
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  modules: {
    pagination,
    rmaRequest,
  },

  mutations: {
    [types.RESET](state) {
      Object.assign(state, initialState());
    },

    [types.FETCH_REQUEST](state) {
      state.isLoading = true;
      state.errorMsg = null;
    },

    [types.FETCH_SUCCESS](state, data) {
      state.isLoading = false;
      state.isFailed = false;
      state.list = data;
    },

    [types.FETCH_FAILED](state, msg) {
      state.isLoading = false;
      state.isFailed = true;
      state.list = [];
      state.errorMsg = msg;
    },
  },

  getters: {
    returns: (state) => state.list.map((item) => ({
      ...item,
      totalAmount: item?.price?.value,
    })),
  },

  actions: {
    fetchForShopV2(context, { contextId, params }) {
      context.commit(types.FETCH_SUCCESS, []);
      context.commit(types.FETCH_REQUEST);

      if (params.status === '') return [];

      return apiV2(context).returns.getRmas(contextId, { ...params, status: params.status || 'pending' }, 'partner')
        .then((response) => {
          const { data, meta } = response;
          const positionIds = data.map((item) => item.positionId);
          const groupedPositionByGroupId = data.reduce((acc, curr) => {
            if (Object.keys(acc).includes(curr.groupId)) return { ...acc, [curr.groupId]: [...acc[curr.groupId], curr.positionId] };
            return { ...acc, [curr.groupId]: [curr.positionId] };
          }, {});

          return apiV2(context).orders.getOrderRMA(contextId, positionIds, 'accepted', true)
            .then(({ data: orderData }) => {
              const orderDataValues = Object.values(orderData).map((item) => {
                const relatedGroupId = data.find((i) => i.positionId === item.id)?.groupId;
                if (relatedGroupId) {
                  return { ...item, groupId: relatedGroupId };
                }

                return item;
              });

              const sameOrderMap = orderDataValues.map((o) => {
                const t = orderDataValues.filter((f) => o.groupId === f.groupId);
                if (t.length === 1) return t[0];
                return t;
              });

              const collections = sameOrderMap.map((e) => {
                if (Array.isArray(e)) {
                  let totalAmount = 0;
                  e.forEach((el) => {
                    totalAmount += el.price.value;
                  });
                  return ({
                    ...e[0],
                    amount: totalAmount,
                    currency: e[0].price.currency,
                    quantity: e.length,
                    duplicatePositon: e[0].groupId,
                  });
                }
                return ({
                  ...e, amount: e.price.value, currency: e.price.currency, quantity: 1,
                });
              });

              collections.forEach((rm, i) => {
                if (rm.duplicatePositon) {
                  collections.forEach((s, j) => {
                    if (i !== j && rm.duplicatePositon === s.duplicatePositon) {
                      collections.splice(j, 1);
                    }
                  });
                }
              });

              const remapData = data.map((item) => {
                const order = collections.find((el) => el.id === item.positionId);
                if (order) {
                  return ({
                    ...item,
                    amount: order.amount,
                    currency: order.currency,
                    quantity: order.quantity,
                    status: item.status,
                    positionId: item.groupId,
                    parentOrderId: order.orderId,
                    returnPositionIds: groupedPositionByGroupId,
                  });
                }
                return null;
              }).filter((n) => n);

              context.commit(types.FETCH_SUCCESS, remapData);
              context.dispatch('pagination/set', meta);
            })
            .catch((orderError) => {
              context.commit(types.FETCH_FAILED, orderError);
              throw orderError;
            });
        })
        .catch(() => {
          context.commit(types.FETCH_FAILED);
        });
    },
  },
};
