import urlParse from 'url-parse';

export const generateHeaders = (channel, method, absoluteUrl, payload) => {
  let requestSignature = null;
  const url = urlParse(absoluteUrl);
  let headers = null;

  const query = url.query.replace('?', '').replace('[', '%5B').replace(']', '%5D');

  if (channel) {
    requestSignature = channel.signRequest(
      method.toUpperCase(),
      url.hostname,
      url.pathname,
      query,
      payload,
    );

    headers = {
      'Miinto-Api-Auth-ID': channel.id,
      'Miinto-Api-Auth-Signature': requestSignature.signature,
      'Miinto-Api-Auth-Timestamp': requestSignature.timestamp.toString(),
      'Miinto-Api-Auth-Seed': requestSignature.seed.toString(),
      'Miinto-Api-Auth-Type': channel.authType,
      'Miinto-Api-Control-Flavour': 'Miinto-Generic',
      'Miinto-Api-Control-Version': '4.0',
    };
  }

  return headers;
};

// eslint-disable-next-line func-names
export default function (authService) {
  return {
    onRequest(config) {
      const newConfig = config;
      const channel = authService.getChannel();
      const url = config.baseURL + config.url;
      const payload = config.data || null;

      const authHeaders = generateHeaders(channel, config.method, url, payload);

      if (channel) {
        newConfig.headers = {
          ...config.headers,
          ...authHeaders,
          'Content-Type': 'application/json',
          Accept: 'application/json',
        };
      }

      return newConfig;
    },
    onError(error) {
      return Promise.reject(error.response);
    },
  };
}
