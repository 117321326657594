<template>
  <returns-layout-container
    :is-loading="isLoading"
    :with-details="withDetails"
  >
    <slot slot="tabs" name="tabs" />

    <router-link
      slot="close"
      class="returns-container__close-link"
      :to="{
        name: 'rma-requests',
        params: {
          shopId: $route.params.shopId,
        },
        query: $route.query,
      }"
    >
      <ui-icon
        type="x"
        class="order__close-icon"
      />
    </router-link>

    <returns-nav-bar
      slot="navBar"
      :status="filter.status"
      :status-filter-options="statusFilterOptions"
      :total="total"
      :offset="offset"
      :limit="limit"
      @refresh="refresh"
      @pagination-change="onPaginationChange"
      @filter-status-change="onFilterStatusChange"
      @search-uuid="onSearchUuid"
    />

    <template v-if="returns.length > 0">
      <returns-list :items="returns">
        <template slot="item" slot-scope="{ item, itemKey, classNames }">
          <router-link :to="{ name: 'rmaRequest', params: { shopId, rmaRequestId: item.id } }">
            <template slot-scope="{ isActive, href }">
              <returns-list-item
                :key="itemKey"
                :item="{ ...item, url: href }"
                :class-names="classNames"
                :id-active="isActive"
                @navigate="onClickItem"
              />
            </template>
          </router-link>
        </template>
      </returns-list>
    </template>
    <template v-else>
      <no-rma-requests-found-message :message="notFoundMessage" />
    </template>
  </returns-layout-container>
</template>

<script>
import { UiIcon } from '@/ui';
import returnsListBehaviorMixin from './returnsListBehavior.mixin';
import ReturnsLayoutContainer from '../components/Layout/Container.vue';
import ReturnsNavBar from '../components/Layout/NavBar.vue';
import ReturnsList from '../components/ReturnsList.vue';
import ReturnsListItem from '../components/RetunsListItem.vue';
import NoRmaRequestsFoundMessage from '../components/Messages/NoRmaRequestsFound.vue';

export default {
  name: 'ReturnsRmaRequestsView',
  components: {
    ReturnsLayoutContainer,
    ReturnsNavBar,
    ReturnsList,
    ReturnsListItem,
    NoRmaRequestsFoundMessage,
    UiIcon,
  },

  mixins: [
    returnsListBehaviorMixin,
  ],

  props: {
    shopId: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      filter: {
        status: 'pending',
      },
      statusFilterOptions: [
        { value: '', text: 'Pending' },
        { value: 'approved', text: 'Approved' },
        { value: 'rejected', text: 'Rejected' },
        { value: 'confirmed', text: 'Approved manually' },
      ],
      notFoundMessages: {
        pending: 'Sorry, no pending RMA requests found',
        approved: 'Sorry, no approved RMA requests found',
        rejected: 'Sorry, no rejected RMA requests found',
        'confirmed': 'Sorry, no approved manually RMA requests found',
      },
    };
  },

  computed: {
    withDetails() {
      return this.$route.matched.some((route) => route.name === 'rmaRequest');
    },

    isLoading() {
      return this.$store.state.rmaRequests.isLoading;
    },

    total() {
      return this.$store.state.rmaRequests.pagination.total;
    },

    offset() {
      return this.$store.state.rmaRequests.pagination.offset;
    },

    limit() {
      return this.$store.state.rmaRequests.pagination.limit;
    },

    returns() {
      return this.$store.getters['rmaRequests/returns'];
    },

    notFoundMessage() {
      return this.notFoundMessages[this.filter.status] || null;
    },
  },

  methods: {
    fetch(params) {
      this.$store.dispatch('rmaRequests/fetchForShopV2', {
        contextId: this.shopId,
        params: {
          query: this.search,
          status: this.filter.status || undefined,
          limit: this.limit,
          offset: 0,
          ...params,
        },
      });
    },

    onClickItem(returnId) {
      this.$router.push({ path: `${this.$route.path}/${returnId}`, query: this.$route.query });
    },
  },
};
</script>
