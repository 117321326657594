<template>
  <order-base :order="order">
    <template #positions>
      <order-awaiting-process
        :key="order.id"
        :has-msa="hasMsa"
        :currency="order.currency"
        :order-id="order.parentOrder.id"
        :shop-id="shopId"
        :order-type="order.type"
      />
      <order-positions-list
        :accepted="order.acceptedPositions"
        :declined="order.declinedPositions"
        :currency="order.currency"
        :show-declined="true"
      />
    </template>
  </order-base>
</template>

<script>
import OrderBase from './OrderBase.vue';
import OrderPositionsList from '../Position/OrderPositionsList.vue';
import OrderAwaitingProcess from './OrderAwaiting/OrderAwaitingProcess.vue';

export default {
  components: {
    OrderBase,
    OrderPositionsList,
    OrderAwaitingProcess,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    shopId: {
      type: String,
      required: true,
    },
  },

  computed: {
    hasMsa() {
      return this.order.shippingInformation.deliveryInfo.canUseShippingService;
    },
  },

  mounted() {
    // Make sure to run after loading order and shops from API
    this.$store.dispatch('orders/order/positions/init', {
      positions: this.order.pendingPositions,
    });
  },
};
</script>
