import { Machine } from 'xstate';

export default Machine(
  {
    id: 'incomplete',
    initial: 'init',

    states: {
      init: {
        on: {
          '': [
            { cond: 'hasMSA', target: 'selectProviders.withMSA' },
            { target: 'selectProviders.withoutMSA' },
          ],
        },
      },

      selectProviders: {
        initial: 'withMSA',
        states: {
          withMSA: {
            on: {
              SELECT_PICKUP_ADDRESS: {
                actions: 'SELECT_PICKUP_ADDRESS',
              },

              SELECT_PROVIDER: {
                actions: 'SELECT_PROVIDER',
              },

              TRY_AGAIN: {
                target: '#incomplete.selectProviders.withMSA.fetching',
              },

              SELECT_PACKAGE: {
                actions: 'SELECT_PACKAGE',
              },

              CONTINUE: [
                { cond: 'noSelectedPickupPoint', target: '#incomplete.selectProviders.withMSA.error.noSelectedPickupPoint' },
                { target: '#incomplete.bookingShipping' },
              ],
            },

            initial: 'fetching',
            states: {
              fetching: {
                invoke: {
                  src: 'FETCH_PROVIDERS',
                  onDone: {
                    target: '#incomplete.selectProviders.withMSA.selecting',
                  },
                  onError: {
                    target: '#incomplete.selectProviders.withMSA.error',
                  },
                },
              },
              selecting: {},
              error: {
                initial: 'generic',
                states: {
                  generic: {},
                  noSelectedPickupPoint: {},
                },
              },
            },
          },

          withoutMSA: {
            initial: 'fetching',
            on: {
              SET_TRACKING_NUMBER: {
                actions: 'SET_TRACKING_NUMBER',
              },

              SELECT_PROVIDER: {
                actions: 'SELECT_PROVIDER',
              },

              CONTINUE: [
                { target: '#incomplete.updatingShipping' },
              ],
            },
            states: {
              fetching: {
                invoke: {
                  src: 'FETCH_PROVIDERS',
                  onDone: {
                    target: '#incomplete.selectProviders.withoutMSA.selecting',
                  },
                  onError: {
                    target: '#incomplete.selectProviders.withoutMSA.error',
                  },
                },
              },
              selecting: {},
              error: {
                initial: 'generic',
                states: {
                  generic: {},
                },
              },
            },
          },
        },
      },

      bookingShipping: {
        initial: 'booking',
        on: {
          BACK: {
            target: 'init',
          },
        },
        states: {
          booking: {
            invoke: {
              src: 'BOOK_SHIPPING',
              onDone: {
                target: '#incomplete.final',
              },
              onError: {
                target: '#incomplete.bookingShipping.error',
              },
            },
          },
          error: {
            initial: 'generic',
            states: {
              generic: {},
            },
          },
        },
      },

      updatingShipping: {
        initial: 'updating',
        on: {
          BACK: {
            target: 'init',
          },
        },
        states: {
          updating: {
            invoke: {
              src: 'UPDATE_SHIPPING',
              onDone: {
                target: '#incomplete.final',
              },
              onError: {
                target: '#incomplete.updatingShipping.error',
              },
            },
          },
          shipping: {},
          error: {},
        },
      },

      final: {
        type: 'final',
        entry: ['FETCH_ORDER'],
      },
    },
  },
);
