<template>
  <message-template
    icon="/illustration-sleepy-basket.svg"
    text="Your job here is done."
    additional-text="There are no awaiting orders."
  />
</template>

<script>
import MessageTemplate from '@/modules/Orders/components/Messages/_MessageTemplate.vue';

export default {
  components: {
    MessageTemplate,
  },
};
</script>
