<template>
  <div>
    <ui-spinner-container :is-loading="isLoading" style="height: 100%;">
      <rma-details-container v-if="rma.id">
        <rma-details-header
          :id="rma.id"
          :shop-id="shopId"
          :created-date="new Date(rma.createdDatetime)"
          :order-id="rma.relatedOrderId"
          :status="rma.status"
        />
        <rma-customer-info
          :customer="rma.customer"
        />

        <rma-details-items-list
          :items="items"
          :total="rma.amount"
          :currency="rma.currency"
        />
      </rma-details-container>
    </ui-spinner-container>
    <slot name="close" />
  </div>
</template>

<script>
import {
  UiSpinnerContainer,
} from '@ui';
import RmaDetailsContainer from '../components/Rma/Details/DetailsContainer.vue';
import RmaDetailsHeader from '../components/Rma/Details/Header.vue';
import RmaCustomerInfo from '../components/Rma/Details/CustomerInfo.vue';
import RmaDetailsItemsList from '../components/Rma/Details/Items/List.vue';

export default {
  name: 'ReturnsRmaRequestView',
  components: {
    UiSpinnerContainer,
    RmaDetailsContainer,
    RmaDetailsHeader,
    RmaCustomerInfo,
    RmaDetailsItemsList,
  },

  props: {
    shopId: {
      type: String,
      default: null,
    },
    rmaRequestId: {
      type: String,
      default: null,
    },
  },

  computed: {
    isLoading() {
      return this.$store.state.rmaRequests.rmaRequest.isLoading || this.$store.state.rmaRequests.isLoading;
    },
    readyForFetch() {
      return !this.$store.state.rmaRequests.isLoading;
    },

    rma() {
      return this.$store.getters['rmaRequests/rmaRequest/rmaRequest'];
    },

    items() {
      const rmaRequest = this.$store.state.rmaRequests.rmaRequest.data;

      return rmaRequest.returnedProducts.map((item) => ({
        ...item,
        reason: rmaRequest.returnReason,
      }));
    },
    fetchTriggerByShopId() {
      return this.shopId && this.readyForFetch ? this.shopId : null;
    },
    fetchTriggerByRmaRequestId() {
      return this.rmaRequestId && this.readyForFetch ? this.rmaRequestId : null;
    },
  },

  watch: {
    fetchTriggerByShopId: 'fetch',
    fetchTriggerByRmaRequestId: 'fetch',
    readyForFetch: 'fetch',
  },

  created() {
    this.fetch();
  },

  methods: {
    fetch() {
      if (this.readyForFetch) {
        this.$store.dispatch('rmaRequests/rmaRequest/fetchRmaRequestV2', {
          contextId: this.shopId,
          returnId: this.rmaRequestId,
        });
      }
    },
  },
};
</script>
