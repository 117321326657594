<template>
  <component
    :is="component"
    :href="href"
    class="ui-nav-item"
    :class="{ 'is-active': active }"
    @click="handleClick"
  >
    <div class="ui-nav-item__content">
      <slot />
    </div>
  </component>
</template>

<script>
export default {
  name: 'UiNavItem',
  props: {
    active: {
      type: Boolean,
      default: false,
    },

    href: {
      type: String,
      default: null,
    },
  },

  computed: {
    component() {
      return this.href ? 'a' : 'div';
    },
  },

  methods: {
    handleClick(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.ui-nav-item {
  position: relative;
  line-height: inherit;
  display: inline-block;
  margin: 0 16px;
  color: #333333;
  font-weight: 500;
  cursor: pointer;

  &__content {
    display: inline-block;
  }

  &:hover {
    color: #006EF5;
  }

  &.is-active {
    color: #006EF5;

    &:after {
      position: absolute;
      content: '';
      display: block;
      height: 2px;
      background: #1F64EE;
      bottom: 0;
      left: 0;
      right: 0;
    }
  }

  @include for-tablet-portrait-down {
    margin: 0 16px 0 0;
  }
}
</style>
