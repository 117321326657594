import apiV2 from '@/apiV2/api';
import iso3311Country from 'iso-3166-1-alpha-2';

const initialState = () => ({
  isLoading: false,
  isFailed: false,
  data: null,
  errorMsg: null,
  isHandling: false,
  isHandlingFailed: false,
  handlingError: null,
  processingRmaId: null,
});

export const types = {
  RESET: 'RESET',
  FETCH_REQUEST: 'FETCH_REQUEST',
  FETCH_SUCCESS: 'FETCH_SUCCESS',
  FETCH_FAILED: 'FETCH_FAILED',
  PROCESSING_RMA_ID: 'PROCESSING_RMA_ID',
  HANDLING_REQUEST: 'HANDLING_REQUEST',
  HANDLING_SUCCESS: 'HANDLING_SUCCESS',
  HANDLING_FAILED: 'HANDLING_FAILED',
};

// eslint-disable-next-line no-unused-vars
const getAsyncRequestResult = async (context, requestId) => {
  context.commit(types.HANDLING_REQUEST);

  return new Promise((resolve, reject) => {
    let attempts = 1;
    // eslint-disable-next-line consistent-return
    const fetchRetry = (n) => apiV2(context).asyncOperations.getAsyncOperationResult(requestId).then((response) => {
      if (n === 1) {
        context.commit(types.HANDLING_FAILED, 'Your request is queued for processing');
        throw Error('Something went wrong');
      } else if (response.data.processStatus === 'failure') {
        context.commit(types.HANDLING_FAILED, 'Please contact with our support');
        throw Error('Something went wrong');
      } else if (response.data.processStatus !== 'success') {
        setTimeout(() => {
          attempts++;
          fetchRetry(n - 1);
        }, attempts * 1000);
      } else {
        context.commit(types.HANDLING_SUCCESS);
        return resolve(response);
      }
    }).catch((error) => {
      if (n === 1 || error.message === 'Something went wrong') {
        reject(error);
        context.commit(types.HANDLING_FAILED, error.message);
      } else {
        setTimeout(() => {
          attempts++;
          fetchRetry(n - 1);
        }, attempts * 1000);
      }
    });
    // eslint-disable-next-line no-promise-executor-return
    return fetchRetry(5);
  });
};

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  mutations: {
    [types.FETCH_REQUEST](state) {
      state.data = null;
      state.isLoading = true;
      state.isFailed = false;
      state.errorMsg = null;
    },

    [types.FETCH_SUCCESS](state, { data }) {
      state.isLoading = false;
      state.isFailed = false;
      state.data = data;
    },

    [types.PROCESSING_RMA_ID](state, { processingRmaId }) {
      state.processingRmaId = processingRmaId;
    },

    [types.FETCH_FAILED](state, msg) {
      state.isLoading = false;
      state.isFailed = true;
      state.errorMsg = msg;
    },

    [types.HANDLING_REQUEST](state) {
      state.isHandling = true;
    },

    [types.HANDLING_SUCCESS](state) {
      state.isHandling = false;
      state.isHandlingFailed = false;
      state.handlingError = null;
    },

    [types.HANDLING_FAILED](state, msg) {
      state.isHandling = false;
      state.isHandlingFailed = true;
      state.handlingError = msg;
    },
  },

  getters: {
    rma: ({ data: rmaData }) => ({
      ...rmaData,
      relatedOrderId: rmaData?.groupId,
    }),
    isProcessingCurrentRma: ({ processingRmaId }) => (rmaId) => rmaId === processingRmaId,
  },

  actions: {
    fetchRmaV2(context, { contextId, returnId }) {
      context.commit(types.FETCH_REQUEST);

      return apiV2(context).returns.getRma(contextId, returnId)
        .then(async ({ data: rmaData, meta }) => {
          const orderId = rmaData?.positionId;
          const { data: mainOrder } = await apiV2(context).orders.getOrderRMA(contextId, orderId, 'accepted', true);
          const returnPositionIds = context.rootGetters['rmas/returns']?.find((item) => item.groupId === rmaData.groupId)?.returnPositionIds || [];
          let restOrder = [];
          const relatedPositions = mainOrder.relatedPositionIds?.filter((id) => id !== rmaData?.positionId && returnPositionIds?.includes(id));

          if (relatedPositions.length) {
            const { data } = await apiV2(context).orders.getOrderRMA(contextId, relatedPositions, 'accepted', true);
            restOrder = Object.values(data);
          }

          const source = relatedPositions.length ? [mainOrder, ...restOrder].filter((n) => n) : [mainOrder];
          const itemIds = [...new Set(source.map((src) => src.item.id))];
          const collectedOrders = itemIds.reduce((acc, curr) => {
            const items = source.filter((f) => curr === f.item.id);
            const order = {
              ...items[0],
              name: items[0].product.title,
              amount: items[0].price.value * items.length,
              currency: items[0].price.currency,
              color: items[0].product.color,
              brand: items[0].product.brand,
              reasonForReturning: items[0].reasonForReturning || rmaData.returnReason,
              size: items[0].item.size.name,
              price: items[0].price.value,
              totalAmount: items[0].price.value * items.length,
              quantity: items.length,
              duplicatePositon: items[0].orderId,
            };

            return [...acc, order];
          }, []);

          const finalRmaData = {
            ...rmaData,
            id: rmaData.groupId,
            createdDatetime: rmaData.createdAt,
            parentOrderId: mainOrder.orderId,
            orderStatus: mainOrder.status,
            status: rmaData.status,
            billingInformation: {
              ...mainOrder.billingInformation,
              address: {
                ...mainOrder.billingInformation.address,
                country: iso3311Country.getCountry(mainOrder.billingInformation.address.countryCode),
              },
            },
            returnTo: {
              ...rmaData.recipient,
              address: {
                ...rmaData.recipient.address,
                country: iso3311Country.getCountry(rmaData.recipient.address.countryCode),
              },
            },
            returnedPositions: [
              ...collectedOrders,
            ],
            currency: mainOrder.price.currency,
            amount: collectedOrders.map((el) => el.totalAmount).reduce((a, b) => a + b, 0),
          };

          context.commit(types.FETCH_SUCCESS, { data: finalRmaData, meta });
          return finalRmaData;
        })
        .catch((rmaError) => {
          context.commit(types.FETCH_FAILED, rmaError.msg);
          throw rmaError;
        });
    },

    async acceptV2(context, { shopId, returnId }) {
      context.commit(types.HANDLING_REQUEST);
      const acceptRequestData = await apiV2(context).returns.acceptRma(shopId, returnId);
      context.commit(types.PROCESSING_RMA_ID, { processingRmaId: returnId });
      let result;
      try {
        result = await getAsyncRequestResult(context, acceptRequestData?.data?.requestId);
      } catch (error) {
        result = error;
      }

      return result;
    },

    async declineV2(context, { shopId, returnId, reason }) {
      context.commit(types.HANDLING_REQUEST);

      const acceptRequestData = await apiV2(context).returns.declineRma(shopId, returnId, reason);
      context.commit(types.PROCESSING_RMA_ID, { processingRmaId: returnId });
      let result;
      try {
        result = await getAsyncRequestResult(context, acceptRequestData?.data?.requestId);
      } catch (error) {
        result = error;
      }

      return result;
    },
  },
};
