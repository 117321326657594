const initialState = () => ({
  packageTypeId: null,
  pickupPoints: [],
  selectedPickupPoint: null,
});

export default {
  namespaced: true,

  state() {
    return initialState();
  },

  getters: {
    noSelectedPickupPoint: (state) => state.selectedPickupPoint === null,
  },

  mutations: {
    selectAddress(state, payload) {
      state.selectedPickupPoint = payload;
    },

    selectPackage(state, payload) {
      state.packageTypeId = payload;
    },

    setPickupPoints(state, payload) {
      state.pickupPoints = payload;
    },

    selectDefaultPickupPoint(state, pickupPoints) {
      state.selectedPickupPoint = pickupPoints.length > 1 ? null : pickupPoints[0].id;
    },

    reset(state) {
      Object.assign(state, initialState());
    },
  },

  actions: {
    init({ commit }, payload) {
      commit('setPickupPoints', payload.pickupPoints);
      commit('selectDefaultPickupPoint', payload.pickupPoints);
    },

    selectAddress({ commit }, payload) {
      commit('selectAddress', payload);
    },

    selectPackage({ commit }, payload) {
      commit('selectPackage', payload);
    },

    setPickupPoints({ commit }, payload) {
      commit('setPickupPoints', payload);
    },

    reset({ commit }) {
      commit('reset');
    },
  },
};
