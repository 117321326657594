<template>
  <order-base :order="order">
    <template #middleSection>
      <delivery-info
        :shop-id="shopId"
        :order-id="order.id.toString()"
        :provider-id="order.shippingInformation.deliveryOptions.initial.providerId"
        :tracking-number="order.shippingInformation.deliveryInfo.trackingNumber"
        :print-documents="order.availabilityOptions.shipping.documentsTypes"
      />
    </template>
    <template #positions>
      <order-positions-list
        :accepted="order.acceptedPositions"
        :declined="order.declinedPositions"
        :show-declined="showDeclined"
        :currency="order.currency"
      />
    </template>

    <template #actions="{ classes }">
      <ui-button
        v-if="canShowDeclined && !showDeclined"
        @click="showDeclined = !showDeclined"
      >
        Show declined positions
      </ui-button>

      <router-link
        :to="{
          name: 'order-rma-request',
          params: { shopId, orderId: order.id.toString() },
          query: { context },
        }"
        :class="classes.right"
      >
        <tooltip
          v-if="isRmaAvailable"
          :disabled="!isRmaDisabled"
          content="In this sales channel the RMA option is not available"
          placement="left-start"
          max-width="180"
        >
          <ui-button :disabled="isRmaDisabled">
            Create RMA Request
          </ui-button>
        </tooltip>
      </router-link>
    </template>
  </order-base>
</template>

<script>
import {
  Tooltip,
} from 'iview';

import {
  UiButton,
} from '@ui';

import OrderBase from './OrderBase.vue';
import DeliveryInfo from '../ShippingDetails/DeliveryInfo.vue';
import OrderPositionsList from '../Position/OrderPositionsList.vue';

export default {
  components: {
    UiButton,
    Tooltip,
    OrderBase,
    OrderPositionsList,
    DeliveryInfo,
  },

  props: {
    order: {
      type: Object,
      required: true,
    },

    shopId: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      showDeclined: false,
    };
  },

  computed: {
    isRmaDisabled() {
      return !this.order.availabilityOptions.rma.enabled;
    },

    availableRmaPositions() {
      return this.order.acceptedPositions.filter((item) => item.quantity > 0);
    },

    isRmaAvailable() {
      return this.availableRmaPositions.length > 0;
    },

    canShowDeclined() {
      return this.order.declinedPositions.length > 0;
    },

    context() {
      return this.$route.query.context;
    },
  },
};
</script>
