<template>
  <div v-if="isLoggedIn" class="user-picture">
    <img v-if="src" :src="src" :alt="accessorEmail">
    <span v-else>{{ getLetter }}</span>
  </div>
</template>

<script>
export default {
  name: 'UiHeaderUserPicture',
  props: {
    src: {
      type: String,
      default: '',
    },
    letter: {
      type: String,
      default: '',
    },
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.isLoggedIn;
    },
    getLetter() {
      return this.letter || this.$store.state.auth.accessorEmail.substring(0, 1);
    },
    accessorEmail() {
      return this.$store.state.auth.accessorEmail;
    },
  },
};
</script>

<style lang="scss">
  .user-picture {
    display: inline-block;
    width: 35px;
    height: 35px;
    line-height: 35px;
    border-radius: 50%;
    background-color: lightseagreen;
    color: #fff;
    font-weight: bold;
    overflow: hidden;
    text-transform: capitalize;
    padding-left: 1px;
    padding-top: 1px;
  }
</style>
