<!-- eslint-disable vuejs-accessibility/click-events-have-key-events -->
<template>
  <i
    :class="classes"
    :style="styles"
    @click="handleClick"
  />
</template>

<script>
const prefixCls = 'ui-icon';

export default {
  name: 'UiIcon',
  props: {
    type: {
      type: String,
      default: '',
    },
    size: {
      type: [Number, String],
      default: '',
    },
    color: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return [
        `${prefixCls}`,
        {
          [`${prefixCls}-${this.type}`]: this.type !== '',
        },
      ];
    },
    styles() {
      const style = {};
      if (this.size) {
        style['font-size'] = `${this.size}px`;
      }
      if (this.disabled) {
        style.color = '#999';
      } else if (this.color) {
        style.color = this.color;
      }
      return style;
    },
  },
  methods: {
    handleClick(event) {
      if (!this.disabled) {
        this.$emit('click', event);
      }
    },
  },
};
</script>

<style lang="scss">
  @import 'icons';

  .mii-icon {
    display: inline-block;
  }
</style>
