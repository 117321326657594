<template>
  <div class="ui-pagination__wrapper">
    <div class="ui-pagination__pager">
      <span v-if="totalItemCount > 0">{{ offset + 1 }} - </span>{{ getOffsetEnd() }} of
      {{ totalItemCount }}
    </div>

    <div class="ui-pagination__arrows">
      <ui-icon
        :disabled="offset === 0 || totalItemCount === 0"
        type="arrow-left"
        size="12"
        class="ui-pagination__arrow"
        @click="onPreviousClick"
      />

      <ui-icon
        :disabled="getOffsetEnd() === totalItemCount || totalItemCount === 0"
        type="arrow-right"
        size="12"
        class="ui-pagination__arrow"
        @click="onNextClick"
      />
    </div>
  </div>
</template>

<script>
import { UiIcon } from '@ui';

export default {
  name: 'UiPagination',
  components: {
    UiIcon,
  },

  props: {
    limit: {
      type: Number,
      required: true,
    },
    offset: {
      type: Number,
      required: true,
    },
    totalItemCount: {
      type: Number,
      required: true,
    },
  },

  methods: {
    onPreviousClick() {
      const currentOffset = this.offset - this.limit;

      this.$emit('page-change', {
        limit: this.limit,
        offset: currentOffset,
        totalItemCount: this.totalItemCount,
      });
    },

    onNextClick() {
      const currentOffset = this.offset + this.limit;

      this.$emit('page-change', {
        limit: this.limit,
        offset: currentOffset,
        totalItemCount: this.totalItemCount,
      });
    },

    getOffsetEnd() {
      return this.offset + this.limit > this.totalItemCount
        ? this.totalItemCount
        : this.offset + this.limit;
    },
  },
};
</script>

<style lang="scss">
.ui-pagination {
  @import "@/styles/mixins/breakpoints.scss";

  &__wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__pager {
    line-height: 20px;
    white-space: nowrap;
  }

  &__arrows {
    display: flex;
    height: 15px;
    width: 45px;
    margin: 0 0 0 15px;
    align-self: center;
    justify-content: space-between;
    align-items: center;
  }

  @include for-tablet-portrait-down {
    &__arrows {
      width: 40px;
      margin: 0 0 0 10px;
    }

    &__arrow {
      padding: 5px;
    }
  }
}
</style>
