<!-- eslint-disable vuejs-accessibility/alt-text -->
<template>
  <div class="order-position">
    <div class="order-position__col-images">
      <viewer
        :images="[position.item.imageUrls[0].replace(/\.[^.]+$/, '.webp')]"
        :options="imageViewerOptions"
        class="order-position__image-box"
        @inited="cancelMovement"
      >
        <picture>
          <source :srcset="position.item.imageUrls[1].replace(/\.[^.]+$/, '.avif')" type="image/avif">
          <source :srcset="position.item.imageUrls[1].replace(/\.[^.]+$/, '.webp')" type="image/webp">
          <img
            :src="position.item.imageUrls[1].replace(/\.[^.]+$/, '.jpg')"
            class="order-position__image"
          >
        </picture>
      </viewer>
    </div>

    <div class="order-position__col-name">
      <span v-if="position && position.item" class="order-position__name">
        <span v-if="position.item.brand">
          {{ position.item.brand }} - </span>{{ position.item.name }}</span>
      <span class="order-position__parameters">
        {{ position.item.color }}, {{ position.item.size }}
      </span>
      <div class="order-position__ids">
        <span
          v-if="position.quantity"
          class="order-position__quantity"
        >
          Qty: {{ position.quantity }}
        </span>
        <span
          v-if="position.id"
          class="order-position__id"
        >
          Id: {{ position.id }}
        </span>
        <span
          v-if="position.item.styleId"
          class="order-position__style-id"
        >
          Style Id: {{ position.item.styleId }}
        </span>
        <span
          v-if="position.item.gtin"
          class="order-position__gtin"
        >
          Gtin: {{ position.item.gtin }}
        </span>
        <div v-if="pcsMappedData">
          <br>
          <hr>
          <p>Partner product details</p>
          <span class="order-position__gtin">
            PartnerItemId: {{ pcsMappedData[1] }}
          </span>
        </div>
      </div>
    </div>

    <div class="order-position__col-value">
      <span
        v-if="position.stock"
        class="order-position__stock"
      >
        <span style="color: #999999">Stock: </span>{{ position.stock }}
      </span>
      <span
        class="order-position__price"
      >
        {{ positionTotalPrice | price(currency) }}
      </span>
    </div>

    <div v-if="showStatus" class="order-position__col-status">
      <slot name="status" :classes="classes.statusLabel" />
    </div>

    <slot />
  </div>
</template>

<script>
import priceFilter from '@/logic/filters/price';
import { component as Viewer } from 'v-viewer';
import 'viewerjs/dist/viewer.css';

export default {
  components: {
    Viewer,
  },

  filters: {
    price: priceFilter,
  },

  props: {
    position: {
      type: Object,
      default: () => {},
    },

    currency: {
      type: String,
      default: null,
    },

    status: {
      type: String,
      default: null,
    },
  },

  data() {
    return {
      classes: {
        statusLabel: 'order-position__status-label',
      },
      imageViewerOptions: {
        title: false,
        toolbar: false,
        navbar: false,
        movable: true,
        className: 'order-position__viewer-body',
      },
    };
  },

  computed: {
    positionTotalPrice() {
      return this.position.price * this.position.quantity;
    },

    showStatus() {
      return this.status === 'pending';
    },
    pcsMappedData() {
      return this.$store.state.orders.pcsDataMap.find(
        (mapEntry) => mapEntry[3] === this.position.item.miintoItemId,
      );
    },
  },

  methods: {
    // due to bug on iOS <13 we had to enable viewer movable option and cancel it behavior
    cancelMovement(viewer) {
      // eslint-disable-next-line no-param-reassign
      viewer.move = () => {};
    },
  },
};
</script>

<style lang="scss">
@import '@/styles/mixins/breakpoints.scss';

.order-position {
  position: relative;
  display: flex;
  vertical-align: middle;
  align-items: stretch;
  justify-content: space-between;

  &__col-status,
  &__col-images,
  &__col-name {
    display: flex;
    align-items: center;
  }

  &__col-status {
    flex-grow: 0;
    width: 120px;
    margin-left: 20px;
    text-align: right;
    justify-content: flex-end;
  }

  &__col-images {
    width: 110px;
    padding-right: 20px;
  }

  &__col-quantity {
    width: 100px;
    text-align: right;
    align-self: center;
  }

  &__col-value {
    text-align: right;
    align-self: center;
    font-size: 22px;
  }

  &__col-name {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-around;
    flex-grow: 1;
  }

  &__stock {
    margin-right: 15px;
  }

  &__ids {
    max-width: 300px;
  }

  &__id,
  &__style-id,
  &__quantity,
  &__gtin {
    font-size: 14px ;
    display: inline-block;
    margin-right: 10px;
    color: #666;
  }

  &__image-box {
    cursor: pointer;
  }

  &__image {
    width: 90px;
  }

  &__name {
    display: block;
    font-size: 20px;
    color: #006EF5;
  }

  &__parameters {
    font-size: 16px;
    color: #333;
  }

  @include for-tablet-portrait-down {
    flex-direction: column;

    &__col-images {
      align-self: center;
      padding: 0;
    }

    &__col-value {
      margin: 16px 0;
    }

    &__col-status {
      margin: 0;
      width: 100%;
    }

    &__viewer-body {
      &.viewer-backdrop {
        background: black;

      }

     .viewer-close {
      top: 0;
      right: 0;
      width: 40px;
      height: 40px;

       &:before {
         content: '';
         background-image: url('~@/assets/x.svg');
         background-size: 50% 50%;
         width: 100%;
         height: 100%;
         top: 0;
         left: 0;
         position: absolute;
         background-position: center;
         line-height: initial;
       }
     }
    }
  }
}
</style>
